import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog, ConsoleLog } from "../../../../../components/basic-elements"
import { ProjectInfoContainer } from "../../am-archived-projects/dialogs/ProjectInfoContainer"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import FaPreProcessPDF from "./FaPreProcessPDF"

export default observer(function FaPreProcessDialog({
	partialStore,
}: {
	partialStore: any
}) {
	const store = useRootStore()
	// const partialStore = orgStore.archiveMgmt

	const dnt = store.global.getDntFormat
	const projectId = partialStore.selectedProj
	const projectInfo = partialStore.viewFASelectedProjInfo(
		partialStore.selectedProj,
		dnt
	)
	const actionStatus = partialStore.getActionStatus
	// const preProcessStatus = partialStore.faPreConditionData.isPreProcessed
	console.log(projectInfo, "projectInfo")

	useEffect(() => {
		partialStore.setFaPreConditionData({
			projectSize: "",
			isPreProcessed: false,
			hasForceArchivedZip: false,
			forceArchivedZipUrl: "",
			preProcessFiles: [],
		})
		// to hide the pdf file list
		partialStore.responses.setResponse(
			"getForceArchivedPreConditionStatus",
			{
				actionName: "getForceArchivedPreConditionStatus",
				status: ActionStatus.standby,
			}
		)

		partialStore.responses.setResponse(
			"generateForceArchivedPreProcessPdfs",
			{
				actionName: "generateForceArchivedPreProcessPdfs",
				status: ActionStatus.standby,
			}
		)

		partialStore.getForceArchivedPreConditionStatus(projectId)
	}, [])

	return (
		<DLDialog
			eleTestId="preprocess-dialog"
			isOpen={partialStore.preProcessDialog}
			setIsOpen={partialStore.setPreProcessDialog}
			showCloseBtn={true}
			dialogTitle="Force-Archived Pre-Process PDFs"
			dialogContents={
				<PreProcessDialogForm
					projInfo={projectInfo}
					partialStore={partialStore}
				/>
			}
			maxWidth="sm"
			fullWidth={true}
			cancelBtnText="Cancel"
			showSpinner={
				actionStatus("generateForceArchivedPreProcessPdfs") ===
					"LOADING" ||
				actionStatus("getForceArchivedPreConditionStatus") === "LOADING"
			}
			cannotUpdate={
				actionStatus("generateForceArchivedPreProcessPdfs") ===
					"LOADING" ||
				actionStatus("getForceArchivedPreConditionStatus") === "LOADING"
			}
			// NOTE: Use the action button on the PreProcessPDF component
		/>
	)
})

const PreProcessDialogForm = ({
	projInfo,
	partialStore,
}: {
	projInfo: any
	partialStore: any
}) => {
	return (
		<div>
			<ProjectInfoContainer projInfo={projInfo} isForceArchived={true} />
			<div style={{ height: "3rem" }} />
			<FaPreProcessPDF
				partialStore={partialStore}
				selectedProj={partialStore.selectedProj}
			/>
			<div style={{ height: "3rem" }} />
		</div>
	)
}
