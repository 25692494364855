import React from "react"
import { observer } from "mobx-react-lite"

import { PageContainer } from "../../../../components/app-frame-elements"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import styled from "styled-components"

import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import CheckEPMatchCxtMenu from "./sub-components/CheckEPMatchCxtMenu"
import CheckEPMatchTable from "./sub-components/CheckEPMatchTable"

export default observer(function CheckEPMatch({
	partialStore,
	actionStatus,
	permission,
}: {
	partialStore: any
	actionStatus: ActionStatus
	permission: PermissionAsObjectProps
}) {
	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			fixedHeight
			isLoading={actionStatus === ActionStatus.loading}
		>
			{/* ----- Contents ----- */}
			{actionStatus === "SUCCESS" && (
				<StyledCheckEPMatch>
					<CheckEPMatchTable permission={permission} />
				</StyledCheckEPMatch>
			)}
			<CheckEPMatchCxtMenu permission={permission} />
			{/* ---------- dialogs  ---------- */}

			{/* ---------- snackbar  ---------- */}
			<CommonSnackbarComponent i18n={{}} partialStore={partialStore} />
		</PageContainer>
	)
})

const StyledCheckEPMatch = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
	.partition {
		margin: 0 0.5rem;
	}
	.not-matched {
		background-color: rgba(226, 110, 110, 0.5);
		font-weight: 600;
	}
`
