import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { DLButton } from "../../../../../components/basic-elements"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { Icon } from "@mdi/react"
import {
	mdiTrashCan,
	mdiChevronUp,
	mdiChevronDown,
	mdiDotsHorizontal,
} from "@mdi/js"
import { useHistory } from "react-router-dom"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { TableCell, TableRow } from "@material-ui/core"

export default observer(function DatasetsPage({
	partialStore,
}: {
	partialStore: any
}) {
	const history = useHistory()
	const projStore = useProjStore()
	const handleContextMenu = (event: any) => {
		event.preventDefault()
		partialStore.setClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	const handleViewData = () => {
		partialStore.setShowHeader(true)
		const defaultUrl = projStore.checkin.defaultUrl
		history.push(defaultUrl + `/fs-data/import-fs-datasets/1`)
	}
	return (
		<StyledDatasetsPage>
			<div className="FR JSB AC">
				<h3>Datasets</h3>
				<div>
					<DLButton
						eleTestId="add-data-btn"
						clickHandler={() => {}}
						eleClassName="add-data"
						color="primary"
					>
						Add Data
					</DLButton>
				</div>
			</div>

			{partialStore.viewInProgressDatasets().length > 0 && (
				<div className="dataset-table">
					<h4>In Progress</h4>
					<ReactTableV8
						tableColumns={Columns()}
						data={partialStore.viewInProgressDatasets()}
						hasPagination={false}
						showPageSetting={false}
						height={300}
						menuId="InProgressDataSets"
					/>
				</div>
			)}

			{partialStore.viewTBDatasets().length > 0 && (
				<div className="dataset-table">
					<h3>Trail Balance</h3>
					<ReactTableV8
						tableColumns={TBColumns(handleViewData)}
						data={partialStore.viewTBDatasets()}
						menuId={"TrailBalanceDatasets"}
						showPageSetting={false}
						height={500}
						groupBy={["datasetType"]}
						renderSubcomponent={(row: any) => {
							return (
								<>
									{row.getIsExpanded() && (
										<TableRow>
											<TableCell
												colSpan={
													row.getVisibleCells().length
												}
											>
												<ReactTableV8
													tableColumns={SubColumns(
														handleContextMenu
													)}
													data={row.subRows.map(
														(i: any) => ({
															...i.original,
														})
													)}
													hasPagination={false}
													showPageSetting={false}
													height={
														Math.min(
															row.subRows.length,
															10
														) *
															40 +
														60
													}
													menuId="TrialBalanceSubTable"
												/>
											</TableCell>
										</TableRow>
									)}
								</>
							)
						}}
					/>
				</div>
			)}
		</StyledDatasetsPage>
	)
})

const StyledDatasetsPage = styled.div`
	.dataset-table {
		margin-top: 10px;
	}
	table > tbody > tr > td {
		padding: 8px;
	}
	table > thead > tr > th {
		.header {
			min-height: 1rem;
			padding-left: 8px;
			justify-content: center;
		}
	}
`

const TBColumns = (handleViewData: any) => {
	return [
		{
			header: "Type",
			accessorKey: "datasetType",
			width: 32,
			cell: ({ row }: any) => {
				const expanded = row.getIsExpanded()
				console.log(row, row.getIsGrouped(), "rowrow1")
				return (
					<>
						{row.getIsGrouped() && (
							<div
								className="FR AC"
								onClick={() => row.toggleExpanded()}
							>
								{expanded ? (
									<Icon
										path={mdiChevronUp}
										size={0.8}
										data-testid="folder-chevron-up"
									/>
								) : (
									<Icon
										path={mdiChevronDown}
										size={0.8}
										data-testid="folder-chevron-down"
									/>
								)}
								<span
									style={{ marginLeft: "0.6rem" }}
									data-testid="folder-name"
								>
									{row.subRows[0].original.datasetType}
								</span>
							</div>
						)}
					</>
				)
			},
		},
		// {
		// 	header: "Type",
		// 	accessorKey: "datasetType",
		// },
		{
			header: "Last modified date",
			accessorKey: "dateAdded",
			cell: ({ row }: any) => {
				const expanded = row.getIsExpanded()
				console.log(row, row.getIsGrouped(), "rowrow1")
				return (
					<>
						{row.getIsGrouped() && (
							<div
								className="FR AC"
								onClick={row.getToggleExpandedHandler()}
							>
								<span
									style={{ marginLeft: "0.6rem" }}
									data-testid="folder-name"
								>
									{row.subRows[0].original.dateAdded}
								</span>
							</div>
						)}
					</>
				)
			},
		},
		{
			header: "",
			accessorKey: "action",
			width: 32,
			cell: (props: any) => {
				return (
					<div
						className="FR AC"
						style={{ width: "100%", gap: 20, textAlign: "center" }}
					>
						<DLButton
							variant="contained"
							eleTestId="project-list-context-btn"
							color="primary"
							clickHandler={handleViewData}
						>
							View Data
						</DLButton>
						<DLButton
							variant="text"
							eleTestId="project-user-list-context-btn"
							// clickHandler={(event: any) =>
							// 	handleContextMenu(event, userId)
							// }
						>
							<Icon path={mdiDotsHorizontal} size={0.8} />
						</DLButton>
					</div>
				)
			},
		},
	]
}

const Columns = () => {
	const history = useHistory()
	const projStore = useProjStore()

	const handleRemove = (id: string) => {
		let proceed = window.confirm("Are you sure want to delete?")
		if (!proceed) return
		projStore.importFSDatasets.removeFromDataset(id)
	}

	return [
		{
			header: "Source",
			accessorKey: "source",
		},
		{
			header: "Dataset Type",
			accessorKey: "datasetType",
		},
		{
			header: "Date added",
			accessorKey: "dateAdded",
		},
		{
			header: "Added by",
			accessorKey: "addedBy",
		},
		{
			header: "Action",
			accessorKey: "action",
			cell: (props: any) => {
				const id = props.row.original.id
				const defaultUrl = projStore.checkin.defaultUrl
				const handleClick = () => {
					projStore.importFSDatasets.setShowHeader(false)
					history.push(defaultUrl + `/fs-data/import-fs-datasets/1`)
				}
				return (
					<div className="FR AC" style={{ gap: 20 }}>
						<DLButton
							variant="contained"
							eleTestId="project-list-context-btn"
							color="primary"
							clickHandler={handleClick}
						>
							Configure
						</DLButton>
						<DLButton
							variant="text"
							eleTestId="project-list-context-btn"
							clickHandler={() => handleRemove(id)}
						>
							<Icon path={mdiTrashCan} size={0.8} />
						</DLButton>
					</div>
				)
			},
		},
	]
}

const SubColumns = (handleContextMenu: any) => [
	{
		header: "Name",
		accessorKey: "source",
	},
	{
		header: "Status",
		accessorKey: "status",
	},
	{
		header: "Import date",
		accessorKey: "dateAdded",
	},
	{
		header: "Imported by",
		accessorKey: "addedBy",
	},
	{
		header: "Data layout",
		accessorKey: "action",
		cell: (props: any) => {
			return (
				<DLButton
					variant="contained"
					eleTestId="save-btn"
					color="primary"
					// clickHandler={handleClick}
				>
					Save
				</DLButton>
			)
		},
	},
	{
		header: "",
		accessorKey: "action",
		cell: (props: any) => {
			return (
				<div style={{ width: "100%", textAlign: "center" }}>
					<DLButton
						variant="text"
						eleTestId="sub-tb-context-btn"
						clickHandler={(event: any) => handleContextMenu(event)}
					>
						<Icon path={mdiDotsHorizontal} size={0.8} />
					</DLButton>
				</div>
			)
		},
	},
]
