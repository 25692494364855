import { types } from "mobx-state-tree"

export const MapNumbersModel = types.model({
	id: types.string,
	number: types.string,
	type: types.string,
	period: types.string,
	description: types.string,
	accounts: types.array(types.frozen()),
})
