import { types } from "mobx-state-tree"
import AdjustmentsViewModel from "./adjustments.view-model"
import AdjustmentsModel from "./adjustments.model"

export const AdjustmentsStore = () =>
	types
		.compose(AdjustmentsModel(), AdjustmentsViewModel)
		.named("AdjustmentsStore")

export const initialStore = {
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const FSAdjustmentsStoreInstance = AdjustmentsStore().create(initialStore)

export default FSAdjustmentsStoreInstance
