import { flow } from "mobx-state-tree"
import { CheckEPMatchApiProps } from "../check-ep-match.apis"

const ReadEPMatchData = (self: any, read: CheckEPMatchApiProps["apiRead"]) => ({
	readEPMatchData: flow(function* () {
		const actionName = "readEPMatchData"
		try {
			const response = yield read()
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadEPMatchData
