import React from "react"
// for store
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
// for input area
import InputWithLabel from "../../../../../components/basic-elements/input-with-label/InputWithLabel"
import sharedRegEx from "../../../../../library/sharedRegEx"
// validate form
import useForm from "../../../../../library/use-form"
// for UI
import styled from "styled-components"
import {
	DLButton,
	DLDialog,
	DLInputField,
	DLRadioGroup,
} from "../../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"

export const AddEngTypeBtn = ({ i18n }: { i18n: DLI18nProps }) => {
	const orgStore = useOrgStore()
	return (
		<DLButton
			variant="contained"
			size="regular"
			eleClassName="top-row-btns"
			eleTestId="add-engtype-btn"
			startIcon={<Icon path={mdiPlus} size={1} />}
			clickHandler={() => orgStore.setupEngTypes.setOpenAdd(true)}
			color="primary"
		>
			{i18n.twAddEngType || "Add"}
		</DLButton>
	)
}

const AddEngTypeDialog = observer(({ i18n }: { i18n: DLI18nProps }) => {
	// default setting
	const orgStore = useOrgStore()
	const actionName = "addEngType"
	//
	const inputsSchema = {
		name: { value: "", error: "", requestInput: false },
		sortOrder: { value: "", error: "", requestInput: false },
	}

	const validationSchema = {
		name: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialCharacters,
				error:
					i18n.warningNoSpecialChar ||
					"Cannot use special characters",
			},
		},
		sortOrder: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.numbersOnly,
				error: i18n.warningNumbersOnly || "Numbers only",
			},
		},
	}

	const addEngType = () => {
		const engTypeName = inputs.name.value
		const sortOrder = parseInt(inputs.sortOrder.value, 10)
		if (sortOrder === 0) {
			orgStore.setupEngTypes.handleResponse({
				actionName: "clickInfoButton",
				status: ActionStatus.fail,
				code: 200,
				color: MessageColorType.red,
				message:
					"Sorting Order should be greater than 0. Please enter a different Sorting Order.",
				open: true,
				autoHide: true,
			})
			return
		}
		orgStore.setupEngTypes.addEngType({
			EngagementTypeItemName: engTypeName,
			SortOrder: sortOrder,
			IsActive: true,
		})
	}

	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsSchema,
		validationSchema,
		addEngType,
		orgStore.setupEngTypes.openAdd // resetPoint of inputs and actionReady
	)

	return (
		<DLDialog
			eleTestId="add-engtype-dialog"
			isOpen={orgStore.setupEngTypes.openAdd}
			setIsOpen={orgStore.setupEngTypes.setOpenAdd}
			handleAction={handleOnSubmit}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={
				<div className="FR AC">
					<Icon
						path={mdiPlus}
						size={1}
						// color={MessageColorType.blue}
						style={{ marginRight: 8 }}
					/>
					{i18n.twAddEngType}
				</div>
			}
			dialogContents={
				<AddClientDialogForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					i18n={i18n}
					actionName={actionName}
				/>
			}
			cancelBtnText={i18n.twCancel}
			actionReady={
				isReady &&
				!orgStore.setupEngTypes.isDuplicatedName(inputs.name.value)
			}
			actionBtn={i18n.twSubmit}
			maxWidth="sm"
			fullWidth={true}
			dialogError={
				orgStore.setupEngTypes.getActionStatus(actionName) !== "SUCCESS"
					? orgStore.setupEngTypes.responses.getResponse(actionName)
							?.message
					: undefined
			}
			showSpinner={
				orgStore.setupEngTypes.getActionStatus(actionName) === "LOADING"
			}
			cannotUpdate={
				orgStore.setupEngTypes.getActionStatus(actionName) === "LOADING"
			}
			useEnterKeyForSubmit // This one should be deprecated if the engType get more field in it
		/>
	)
})

const AddClientDialogForm = observer(
	({
		inputs,
		handleOnChange,
		i18n,
		actionName,
	}: {
		inputs: any
		handleOnChange: any
		i18n: DLI18nProps
		actionName: string
	}) => {
		const orgStore = useOrgStore()
		return (
			<StyledAddDialog>
				<div className="input-section FR">
					<InputWithLabel
						required
						label={i18n.twSortOrder || "Sorting Order"}
					>
						<DLInputField
							eleType="number"
							autoFocus
							eleTestId="sorting-order-input"
							eleFullWidth
							eleName="sortOrder"
							eleValue={inputs.sortOrder.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={
								inputs.sortOrder.error ||
								(orgStore.setupEngTypes.isDuplicatedSortOrder(
									parseInt(inputs.sortOrder.value, 10)
								) &&
									(i18n.tsSameSortOrderExist ||
										"The same Sort Order already exist"))
							}
							warningType={
								orgStore.setupEngTypes.getActionStatus(
									actionName
								) !== "SUCCESS" &&
								orgStore.setupEngTypes.isDuplicatedSortOrder(
									parseInt(inputs.sortOrder.value, 10)
								)
									? "red"
									: undefined
							}
							requestInput={inputs.sortOrder.requestInput}
						/>
					</InputWithLabel>
				</div>
				<div className="input-section FR">
					<InputWithLabel required label={i18n.twName}>
						<DLInputField
							autoFocus
							eleTestId="engagement-type-name-input"
							eleFullWidth
							eleName="name"
							eleValue={inputs.name.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={
								inputs.name.error ||
								(orgStore.setupEngTypes.isDuplicatedName(
									inputs.name.value
								) &&
									(i18n.tsSameNameExist ||
										"The same name already exist"))
							}
							warningType={
								orgStore.setupEngTypes.getActionStatus(
									actionName
								) !== "SUCCESS" &&
								orgStore.setupEngTypes.isDuplicatedName(
									inputs.name.value
								)
									? "red"
									: undefined
							}
							requestInput={inputs.name.requestInput}
						/>
					</InputWithLabel>
				</div>
				<div className="input-section FR">
					<InputWithLabel required label={"Engagement Type Status"}>
						<DLRadioGroup
							groupName="itemStatus"
							disabled
							itemDirection="row"
							items={[
								{
									value: "Active",
									label: "Active",
								},
								{
									value: "Inactive",
									label: "Inactive",
								},
							]}
							selectedValue={"Active"}
							eleClassName="itemStatus"
						/>
					</InputWithLabel>
				</div>
			</StyledAddDialog>
		)
	}
)

const StyledAddDialog = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`

export default AddEngTypeDialog
