import { types } from "mobx-state-tree"
// model common action
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
// separated actions

import { CheckFinalReportDateMatchApiProps } from "./check-final-report-date-match.apis"
import ReadFinalReportDateMatchData from "./model-actions/read-final-report-date-match-data"
// WARNING: TODO: NOTE: Archive Policy List MVVM is not updated yet

const CheckFinalReportDateMatchModel = ({
	apiRead,
}: CheckFinalReportDateMatchApiProps) =>
	types
		.model({})
		// CRUD actions
		.actions((self, read = apiRead) =>
			ReadFinalReportDateMatchData(self, read)
		)
		// common model actions
		.actions(CommonModelActions)

export default CheckFinalReportDateMatchModel
