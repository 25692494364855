import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import { getRequest, postRequest } from "../../../../../library/api-requests"

export function read() {
	const response = getRequest({
		url: "/GetArchivePolicy",
		params: {},
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export type CheckFinalReportDateMatchApiProps = {
	apiRead: () => Promise<AxiosResponse<any>>
}
