import React, { useState } from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLSpinner,
	DLIconButton,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { Icon } from "@mdi/react"
import { mdiBookMultiple, mdiPlus, mdiDotsHorizontal } from "@mdi/js"
import styled from "styled-components"
import ProjTemplateFileCtxMenu from "./context-menus/ProjTemplateFileCtxMenu"
import NoSelectedItem from "../../../../../components/global-shared-components/NoSelectedItem"
import OrgProjTemplateFileTreeController from "./OrgProjTemplateFileTreeController"
import {
	DragOnOffIconButton,
	CheckboxOnOffIconButton,
	ExpandAllIconButton,
	CollapseAllIconButton,
	SortingIconButton,
} from "../../../../../components/basic-elements/buttons/toggle-icon-buttons"
import { FileScreenDialog } from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
import ProjTemplateFolderCtxMenu from "./context-menus/ProjTemplateFolderCtxMenu"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import {
	DLIcon,
	DLIconName,
} from "../../../../../components/basic-elements/icons/common-icons"

export default observer(function OrgProjTemplateDetail({
	permission,
	i18n,
}: {
	permission: PermissionAsObjectProps
	i18n: DLI18nProps
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const templateStore = orgStore.orgProjTemplates
	const templateId = templateStore.selectedTemplate.id
	const actionName = "getProjTemplateFirstFolders"
	//
	const handleExpandAll = () => {
		templateStore.getProjTemplateFirstFolders(templateId)
		// templateStore.expandAll(true)
		// NOTE: update with the sequential request
	}

	const downloadAllAsZip = templateStore.downloadAllAsZip

	const [isAsc, setIsAsc] = useState(true)
	const handleSort = () => {
		templateStore.sortChildren({ folderId: null, isAsc }) // parentId null means root folder
		setIsAsc(!isAsc)
	}

	const refreshProjectTemplate = () => {
		templateStore.getProjTemplateFirstFolders(templateId)
	}

	const loadingStatus = templateStore.getActionStatus(actionName)
	const areaWidth =
		store.ui.contentsAreaWidth - templateStore.viewNavWidth - 16 // 16 = resizing trigger width
	const contentsHeight = store.ui.contentsAreaHeight - 88

	return (
		<StyledOrgProjTemplateDetail style={{ width: areaWidth }}>
			{templateId !== "" ? (
				<>
					{loadingStatus === "LOADING" && (
						<div
							className="FR AC JC"
							style={{ width: "100%", height: 200 }}
						>
							<DLSpinner />
						</div>
					)}
					{loadingStatus === "SUCCESS" && (
						<div className="template-detail-container">
							{/* Template detail area header */}
							<div className="template-title FR AC JSB">
								<div className="FR AC">
									<Icon path={mdiBookMultiple} size={1} />
									<div className="title-text heading3">
										{templateStore.selectedTemplate.title}
									</div>
									<div style={{ marginRight: "1rem" }}>
										<DLIconButton
											eleTestId="refresh-project-template"
											clickHandler={() =>
												refreshProjectTemplate()
											}
										>
											<DLIcon
												name={DLIconName.refresh}
												noMargin
											/>
										</DLIconButton>
									</div>
								</div>
								{areaWidth > 272 ? (
									<div className="right-side-btn-area FR JSB AC">
										{permission.create && (
											<DLIconButton
												// update this icon as ctx menu as same as workpaper
												eleTestId="ctx-add-root-folder"
												clickHandler={() => {
													// WARNING: Project Template use template ID as parent ID of root folder
													templateStore.setSelectedItem(
														templateId,
														true
													)
													templateStore.setFileTreeDialogOpen(
														FileScreenDialog.createFolder,
														true
													)
												}}
												tooltipText={
													i18n.twAddFolder ||
													"Add a new folder"
												}
											>
												<Icon
													path={mdiPlus}
													size={0.8}
												/>
											</DLIconButton>
										)}

										{permission.update && (
											<DragOnOffIconButton
												handleOnOff={() => {
													templateStore.setShowDragIcon()
													ConsoleLog(
														"This one is just styled update. project template has no lock feature"
													)
												}}
												structureLock={
													// NOTE: Have to update with structure lock after sometimes
													templateStore.structureLock
												}
											/>
										)}
										{permission.update &&
											!templateStore.structureLock && (
												<SortingIconButton
													isAsc={isAsc}
													handleSort={handleSort}
													by="- Root Folders"
												/>
											)}
										{permission.update ||
											(permission.export && (
												<CheckboxOnOffIconButton
													handleOnOff={() =>
														templateStore.setShowCheckbox()
													}
													showCheckbox={
														templateStore.showCheckbox
													}
												/>
											))}

										<ExpandAllIconButton
											handleExpandAll={handleExpandAll}
											disabled={
												!templateStore.needExpandAll
											}
										/>
										<CollapseAllIconButton
											handleCollapseAll={() =>
												templateStore.expandAll(false)
											}
											disabled={
												!templateStore.needCollapseAll
											}
										/>
										{permission.export && (
											<div>
												<DLIconButton
													eleTestId="download-all-as-zip"
													clickHandler={() => {
														let proceed =
															window.confirm(
																"Do you want to download all as zip?"
															)
														if (!proceed) {
															return
														} else {
															downloadAllAsZip({
																templateId,
																fileNameZip:
																	templateStore
																		.selectedTemplate
																		.title,
															})
														}
													}}
												>
													<DLIcon
														name={
															DLIconName.download
														}
														noMargin
														tooltipText={
															"Download all as ZIP"
														}
													/>
												</DLIconButton>
											</div>
										)}
										{/* pending: because this export all does not exist for project template yet  */}
										{/* {permission.export && (
												<DLIconButton eleTestId="download-template-btn">
													<DLIcon
														name={
															DLIconName.download
														}
														noMargin
													/>
												</DLIconButton>
											)} */}
									</div>
								) : (
									<div className="right-side-btn-area FR AC">
										{/* TODO: @Noah */}
										<DLIconButton eleTestId="proj-template-file-tree-header-mobile-ctx-btn">
											<Icon
												path={mdiDotsHorizontal}
												size={0.8}
											/>
										</DLIconButton>
									</div>
								)}
							</div>
							{/* Template detail area file tree */}
							{templateStore.flatList.length === 0 ? (
								<div>No folder exist</div>
							) : (
								<div
									className="template-detail-tree"
									// style={{ height: contentsHeight - 48 }}
								>
									<OrgProjTemplateFileTreeController
										areaWidth={areaWidth}
									/>
								</div>
							)}
						</div>
					)}
				</>
			) : (
				//  NOTE: Because template group has one more depth for the items.. card list seems not good for this screen.
				<NoSelectedItem />
			)}
			{templateStore.folderClickPoint.mouseX && (
				<ProjTemplateFolderCtxMenu
					partialStore={templateStore}
					permission={permission}
				/>
			)}
			{templateStore.fileClickPoint.mouseX && (
				<ProjTemplateFileCtxMenu
					partialStore={templateStore}
					permission={permission}
				/>
			)}
		</StyledOrgProjTemplateDetail>
	)
})

const StyledOrgProjTemplateDetail = styled.div`
	/* min-width: 500px;
	min-height: 500px; */
	height: 100%;
	overflow: hidden;
	.template-title {
		height: ${(props) => props.theme.shared.baseUnit};
		padding-left: 1rem;
		padding-right: 1rem;
		.title-text {
			margin-left: 0.6rem;
			font-weight: 700;
		}
	}
	.template-detail-tree {
		overflow: hidden;
		overflow-y: auto;
	}
`
