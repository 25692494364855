import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"

const GenerateForceArchivedZip = (self: any) => ({
	generateForceArchivedZip(projectId: string) {
		// 0.
		const actionName = "generateForceArchivedZip"
		// 1.
		let proceed = window.confirm(
			"Do you really want to generate force-archived zip?"
		)
		if (!proceed) {
			return
		}
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 111,
			color: MessageColorType.green,
			customMessage: "Force-Archived zip Request is in progress...",
			open: true,
		})
		// 3.

		self.requestGenerateForceArchivedZip(projectId).then(
			(response: any) => {
				// console.log(actionName + "response" + response)
				if (
					response.status === 200 &&
					response.data.status.code === 1
				) {
					// if success
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 111,
						color: MessageColorType.blue,
						customMessage:
							"You will receive an email once the force-archived zip is generated.",
						open: true,
					})
				} else {
					// set fail response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						customMessage:
							"Force-Archived zip request has been failed.",
						open: true,
					})
				}
			}
		)
	},
})

export default GenerateForceArchivedZip
