import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import {
	DLIconButton,
	DLButton,
	DLSpinner,
} from "../../../../../components/basic-elements"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { SignoffhistoryTableColumns1 } from "./SignOffHistoryTableColumns"
import { Icon } from "@mdi/react"
import {
	mdiUnfoldLessHorizontal,
	mdiArrowExpandVertical,
	mdiFileExcel,
	mdiFilePdf,
} from "@mdi/js"
import SignOffHistoryDetailMore from "./SignOffHistoryDetailMore"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { handleExport } from "../../../../../components/basic-elements/export/ExportXLSX"
import ExportDetailHistoryDialog from "./ExportDetailHistoryDialog"
import { periodOptions } from "../../../../../common-models/enumerations/org-related-enums"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { Table } from "@tanstack/react-table"
import { TableCell, TableRow } from "@material-ui/core"

export default observer(function SignOffHistoryDetail({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	const store = useRootStore()
	const projStore = useProjStore()
	const partialStore = projStore.signOff

	// const handleExpand = (props: any) => {
	// 	setExpanded(props.newExpanded)
	// }

	const handleSignOffSummaryExport = () => {
		let proceed = window.confirm(
			"Do you really want to export sign off summary?"
		)
		if (!proceed) return
		const data = partialStore.exportLastSignOff(
			partialStore.selectedTab,
			store.global.getDntFormat
		)
		const { periodName, financialYear, clientName } =
			projStore.projInfo.projInfo
		const periodAbbrName = periodOptions.find(
			(item: any) =>
				item.label.replace(/ /g, "").toLowerCase() ===
				periodName.replace(/ /g, "").toLowerCase()
		)?.abbr
		const fileType =
			partialStore.selectedTab === DLProjSubMenus.wp ? "WP" : "PF"
		const fileName = `${periodAbbrName} ${financialYear} ${clientName} - ${fileType} Sign Off Summary`
		handleExport(data, fileName)
	}

	const areaWidth = store.ui.contentsAreaWidth - partialStore.viewListWidth
	const areaHeight = store.ui.contentsAreaHeight
	const columnsStatus = partialStore.viewColumns
	const dntFormat = store.global.getDntFormat

	console.log(
		partialStore.viewLastSignoffs(partialStore.selectedTab, dntFormat)
	)

	const pageHeader = (instance: Table<any>) => {
		console.log(instance, instance.getIsAllRowsExpanded(), "instance")
		return (
			<div className="buttons-area FR AC JSB">
				<div className="FR AC JR">
					<DLIconButton
						eleTestId="table-expand-all-btn"
						tooltipText="Expand all"
						disabled={instance.getIsAllRowsExpanded()}
						clickHandler={() => {
							instance.toggleAllRowsExpanded(true)
						}}
					>
						<Icon path={mdiArrowExpandVertical} size={0.8} />
					</DLIconButton>
					<DLIconButton
						eleTestId="table-collapse-all-btn"
						tooltipText="Collapse all"
						disabled={!instance.getIsAllRowsExpanded()}
						clickHandler={() => {
							instance.toggleAllRowsExpanded(false)
						}}
					>
						<Icon path={mdiUnfoldLessHorizontal} size={0.8} />
					</DLIconButton>
				</div>
				<div>
					{permission.export && (
						<div className="FR AC">
							<DLButton
								eleTestId="export-signoff-history-btn"
								startIcon={
									<Icon
										path={mdiFilePdf}
										size={0.8}
										color="red"
									/>
								}
								clickHandler={() =>
									partialStore.exportSignOffHistoryZip()
								}
							>
								Export Summary & Detail
							</DLButton>
							<div style={{ width: 8 }} />
							<DLButton
								eleTestId="export-summury-xlsx"
								startIcon={
									<Icon
										path={mdiFileExcel}
										size={0.8}
										color="green"
									/>
								}
								clickHandler={handleSignOffSummaryExport}
							>
								Export Summary
							</DLButton>
							<div style={{ width: 8 }} />
							<DLButton
								eleTestId="export-detail-xlsx"
								startIcon={
									<Icon
										path={mdiFileExcel}
										size={0.8}
										color="green"
									/>
								}
								clickHandler={() => {
									partialStore.setDetailHistoryDialogOpen(
										true
									)
								}}
							>
								Export Detail
							</DLButton>
						</div>
					)}
				</div>
			</div>
		)
	}

	return (
		<StyledSignOffHistoryDetail
			areaWidth={areaWidth}
			areaHeight={areaHeight}
		>
			<div className="contents-container">
				{partialStore.getActionStatus("getLastSignoffHistory") ===
				ActionStatus.success ? (
					<ReactTableV8
						tableColumns={SignoffhistoryTableColumns1(
							columnsStatus
						)}
						data={partialStore.viewLastSignoffs(
							partialStore.selectedTab,
							dntFormat
						)}
						menuId={DLProjSubMenus.signoff_history}
						showPageSetting={false}
						height={areaHeight - 40}
						pageHeader={pageHeader}
						groupBy={["parentId"]}
						renderSubcomponent={(row: any) => {
							return (
								<>
									{row.getIsExpanded() &&
										!row.groupingColumnId && (
											<TableRow>
												<TableCell
													colSpan={
														row.getVisibleCells()
															.length
													}
												>
													<SignOffHistoryDetailMore
														fileId={row.original.id}
														fromTable={true}
													/>
												</TableCell>
											</TableRow>
										)}
								</>
							)
						}}
					/>
				) : (
					<div
						style={{ width: "100%", marginTop: "6rem" }}
						className="FR AC JC"
					>
						<DLSpinner />
					</div>
				)}
			</div>
			{partialStore.detailHistoryDialogOpen && (
				<ExportDetailHistoryDialog partialStore={partialStore} />
			)}
		</StyledSignOffHistoryDetail>
	)
})

const StyledSignOffHistoryDetail = styled.div<{
	areaWidth: number
	areaHeight: number
}>`
	margin-left: 2px;
	.buttons-area {
		width: 100%;
	}
	.contents-container {
		width: ${(props) => props.areaWidth}px;
		height: ${(props) => props.areaHeight}px;
		font-size: 12px;
		// background-color: ${(props) => props.theme.secondaryDeep};
		padding: 1rem;
	}
	table > thead > tr > th {
		padding: 0;
		.header {
			min-height: 2rem;
			padding-left: 8px;
			justify-content: center;
		}
	}
`
