import { types } from "mobx-state-tree"

export const FinancialGroupModel = types.model({
	id: types.string,
	parentId: types.union(types.string, types.null),
	isParent: types.boolean,
	expanded: types.boolean,
	title: types.string,
	balance: types.string,
	code: types.union(types.string, types.null),
})

export const FSAccountsModel = types.model({
	id: types.string,
	code: types.string,
	name: types.string,
	balance: types.string,
	isAssigned: types.boolean,
})
