import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { PageContainer } from "../../../../components/app-frame-elements"
import { ActionStatus } from "@datalobby/types"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import styled from "styled-components"
import AccountsCard from "./sub-components/AccountsCard"
import SortableTree from "react-sortable-tree"
import Icon from "@mdi/react"
import { mdiFolder, mdiFolderOpen } from "@mdi/js"
import StyledTreeForFiles from "../../../../components/combined-elements/file-tree/StyledTreeForFileMenus"
import FSGroupAccountsCtxMenu from "./sub-components/FSGroupAccountsCtxMenu"

export default observer(function AssignFSAccounts({
	partialStore,
	actionStatus,
	permission,
}: {
	partialStore: any
	actionStatus: ActionStatus
	permission: PermissionAsObjectProps
}) {
	const [selectedAccount, setSelectedAccount] = useState<any>(null)

	console.log(partialStore.flatListAsTree(), "flatListAsTree")

	const handleClick = (e: any, expanded: boolean, id: string) => {
		partialStore.toggleExpanded(id)
	}

	const handleDragStart = (
		event: React.DragEvent<HTMLDivElement>,
		account: any
	) => {
		event.dataTransfer.setData("text/plain", JSON.stringify(account))
	}

	const handleTreeDrop = (data: any, node: any) => {
		console.log(
			data,
			data.dataTransfer.getData("text/plain"),
			node,
			"dataNode"
		)
		const account = JSON.parse(data.dataTransfer.getData("text/plain"))
		if (node.isParent) {
			const newNode = {
				id: account.id,
				parentId: node.id,
				isParent: false,
				title: account.name,
				expanded: false,
				balance: account.balance,
				code: account.code,
			}
			partialStore.pushItemToFinancialGroup(newNode)
			partialStore.changeAccountStatus(account.id, true)
		} else if (!node.isParent && node.parentId) {
			const newNode = {
				id: account.id,
				parentId: node.parentId,
				isParent: false,
				title: account.name,
				expanded: false,
				balance: account.balance,
				code: account.code,
			}
			partialStore.pushItemToFinancialGroup(newNode)
			partialStore.changeAccountStatus(account.id, true)
		}
	}

	const handleCardDrop = (event: React.DragEvent<HTMLDivElement>) => {
		const node = JSON.parse(event.dataTransfer.getData("text/plain"))
		console.log(event, node, "dropNode")

		if (node) {
			partialStore.removeItemFromFinancialGroup(node.id) // Update store to remove from tree
			partialStore.pushAccountOrChangeStatus(node, false) // Mark as unassigned
		}
	}

	const unassignedAccountsDropZone = {
		onDragOver: (e: React.DragEvent) => e.preventDefault(),
		onDrop: handleCardDrop,
	}

	const unassignedAccounts = partialStore.viewUnassignedFSAccounts()

	const handleTreeDragStart = (info: any, node: any) => {
		info.dataTransfer.setData("text/plain", JSON.stringify(node))
	}

	const handleClickAccount = (node: any) => {
		console.log(node, selectedAccount, "selectedAccount")
		setSelectedAccount({
			id: node.id,
			code: node.code,
			name: node.title,
			balance: node.balance,
			isAssigned: true,
		})
	}

	const handleContextMenu = (event: any, id: string) => {
		event.preventDefault()
		partialStore.setClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
		partialStore.setSelectedAccountId(id)
	}

	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			fixedHeight
			isLoading={actionStatus === ActionStatus.loading}
		>
			<StyledAssignFSAccounts>
				<Column>
					<SectionTitle>Unassigned</SectionTitle>
					<div {...unassignedAccountsDropZone}>
						{unassignedAccounts.map((account: any) => (
							<AccountsCard
								account={account}
								handleClick={(selected: any) =>
									setSelectedAccount(selected)
								}
								onDragStart={(event: any) =>
									handleDragStart(event, account)
								}
								key={account.id}
							/>
						))}
					</div>
				</Column>
				<Column>
					<SectionTitle>Selected Accounts</SectionTitle>
					{selectedAccount && (
						<AccountsCard
							handleClick={() => {}}
							account={selectedAccount}
							onDragStart={(event: any) =>
								handleDragStart(event, selectedAccount)
							}
						/>
					)}
				</Column>
				<Column>
					<SectionTitle>FS Data Groups</SectionTitle>
					<StyledTreeForFiles>
						<SortableTree
							treeData={partialStore.flatListAsTree()}
							className={"financial-groups-component"}
							//
							dndType="dlFileNode"
							shouldCopyOnOutsideDrop
							canDrag={true}
							getNodeKey={({ node }: { node: any }) => node.id}
							isVirtualized={false}
							onChange={(treeData) => {}} // sortable tree set this as mandatory
							style={{
								// width: contentsAreaWidth,
								height: 100,
								paddingBottom: "1rem",
								// overflowY: "auto", // Do not use scroll. It makes right side padding even when it has less item
							}}
							rowHeight={32}
							scaffoldBlockPxWidth={100}
							generateNodeProps={(data: any) => {
								console.log(data, "nodeData")
								let forIndent = 0
								for (let i = 0; i < data.path.length - 1; i++) {
									forIndent += 20
								}
								return {
									title: data.node.isParent ? (
										<div
											className="FR"
											onClick={(e: any) =>
												handleClick(
													e,
													data.node.expanded,
													data.node.id
												)
											}
											style={{ marginLeft: forIndent }}
										>
											<div
												data-testid={`file-folder-icon-${data.node.id}`}
											>
												{data.node.expanded ? (
													<div className="FR AC chevron-and-folder-icon opened">
														<Icon
															path={mdiFolderOpen}
															size={0.8}
															className="folder-icon expanded"
														/>
													</div>
												) : (
													<div className="FR AC chevron-and-folder-icon closed">
														<Icon
															path={mdiFolder}
															size={0.8}
															className="folder-icon"
														/>
													</div>
												)}
											</div>
											<div>{data.node.title}</div>
										</div>
									) : (
										<div
											draggable
											onDragStart={(info: any) =>
												handleTreeDragStart(
													info,
													data.node
												)
											}
											onClick={() =>
												handleClickAccount(data.node)
											}
											onContextMenu={(event: any) =>
												handleContextMenu(
													event,
													data.node.id
												)
											}
											style={{
												marginLeft: forIndent,
												borderLeft: `4px solid ${
													data.node.balance.startsWith(
														"("
													)
														? "red"
														: "green"
												}`,
												paddingLeft: 10,
											}}
										>
											{data.node.code} {data.node.title}
										</div>
									),
									onDrop: (info: any) =>
										handleTreeDrop(info, data.node),
								}
							}}
						/>
					</StyledTreeForFiles>
				</Column>
			</StyledAssignFSAccounts>
			{partialStore.clickPoint.mouseX && (
				<FSGroupAccountsCtxMenu permission={permission} />
			)}
		</PageContainer>
	)
})

const StyledAssignFSAccounts = styled.div`
	display: flex;
	height: 100vh;
	padding-left: 1rem;
	padding-right: 1rem;
`
const Column = styled.div`
	flex: 1;
	padding: 16px;
	overflow-y: auto;
	border-right: 1px solid #ddd;
`

const SectionTitle = styled.h2`
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 16px;
	color: #333;
`
