import React from "react"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { observer } from "mobx-react-lite"

export default observer(function FSAccountsController() {
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.fs_accounts

	const menuAccess = projStore.checkin.checkAccess(menuId)

	return (
		<>
			{menuAccess ? (
				<div>FS Accounts Controller</div>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
