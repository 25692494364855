import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import {
	DLButton,
	DLChip,
	DLContextMenuWithButton,
} from "../../../../components/basic-elements"
import { DLAvatar, DLCheckbox } from "@datalobby/components"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { mdiDotsHorizontal, mdiMenuDown } from "@mdi/js"
import Icon from "@mdi/react"
import { findProjRoleInfo } from "../../../../temporary-data/project-side/find-proj-role"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { DLSector } from "../../../../common-models/enumerations/common-enums"
import { InputFieldForTableV8 } from "../../../../components/basic-elements/tables/InputFieldForTable"
import { Column, Table } from "@tanstack/react-table"
import { singleSelectFilter } from "../../../../library/react-table-utils"
import ReactTableV8, {
	ReactTableColumnProps,
} from "../../../../components/basic-elements/tables/DLReactTable"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { AddProjUserButton } from "../dialogs/AddProjUserDialog"
import { StyledHeader } from "../../../organization-side/org-setup/org-setup-users/sub-components/OrgSetupUserListTable"
import { getProjIdFromUrl } from "../../../../library/api-requests/request-get-others"

export default observer(function ProjUsersTable({
	roleSet,
	i18n,
	projUsers,
	permission,
}: {
	roleSet: any
	i18n: DLI18nProps
	projUsers: any
	permission: PermissionAsObjectProps
}) {
	// default settings
	const store = useRootStore()
	const projStore = useProjStore()
	// const actionNames = {
	// 	activate: "activateProjUser",
	// 	deactivate: "deactivateProjUser",
	// 	assign: "assignProjUser",
	// 	unassign: "unAssignProjUser",
	// 	edit: "editProjUser",
	// }
	//
	const handleContextMenu = (event: any, userId: string) => {
		event.preventDefault()
		if (ableToUpdate) {
			projStore.projUsers.setClickPoint({
				mouseX: event.clientX - 2,
				mouseY: event.clientY - 4,
			})
			projStore.projUsers.setSelectedUser(userId)
		}
	}
	//
	useEffect(() => {
		if (projStore.roles.viewRoleSet2() === undefined) {
			projStore.roles.getRoleSet(DLSector.proj)
		}
	}, [])

	const [ableToUpdate, setAbleToUpdate] = useState(false)

	useEffect(() => {
		const projStatus = projStore.projInfo.projInfo.statusName
		const isArchived = projStore.projInfo.projInfo.isArchived
		const isPreProcessed =
			projStore.archivePreProcess.preConditionData.isPreProcessed

		if (isArchived || projStatus === ProjectStatus.forceArchived) {
			setAbleToUpdate(false)
		} else if (projStatus === ProjectStatus.unarchived) {
			if (isPreProcessed) {
				setAbleToUpdate(false)
			} else {
				setAbleToUpdate(true)
			}
		} else {
			if (isPreProcessed && !isArchived) {
				setAbleToUpdate(false)
			} else {
				setAbleToUpdate(true)
			}
		}
	}, [])

	//
	// let activating = projStore.projUsers.getActionStatus(actionNames.activate)
	// let deactivating = projStore.projUsers.getActionStatus(
	// 	actionNames.deactivate
	// )
	// let assigning = projStore.projUsers.getActionStatus(actionNames.assign)
	// let unassigning = projStore.projUsers.getActionStatus(actionNames.unassign)
	// let updating = projStore.projUsers.getActionStatus(actionNames.edit)

	const handleCheckbox = (id: string) => {
		projStore.projUsers.setSelectedUser(id)
	}

	const userId = projStore.projUsers.selectedUser
	const userInfo = projStore.projUsers.viewSelectedUserInfo()
	const actBy = localStorage.getItem("orgUserId")
	const projId = getProjIdFromUrl()

	const pageHeader = (instance: Table<any>) => {
		const totalUsers = instance.getPreFilteredRowModel().flatRows.length
		const totalInternalUsers = instance
			.getPreFilteredRowModel()
			.flatRows.filter(
				(item: any) => item.original.type === "internal"
			).length
		const totalExternalUsers = instance
			.getPreFilteredRowModel()
			.flatRows.filter(
				(item: any) => item.original.type === "external"
			).length

		const handleUserType = (value: string) => {
			instance.setColumnFilters((props: any) => {
				const filteredProps = props.filter(
					(item: any) => item.id !== "type"
				)
				return [...filteredProps, { id: "type", value }]
			})
		}

		const userType =
			instance
				.getState()
				.columnFilters.find((item: any) => item.id === "type")?.value ||
			"all"

		return (
			<StyledHeader style={{ width: "100%" }}>
				<div className="FR">
					{projStore.projInfo.projInfo.statusName !==
						ProjectStatus.replica &&
						permission.create &&
						ableToUpdate && <AddProjUserButton i18n={i18n} />}

					{projStore.projInfo.projInfo.statusName ===
						ProjectStatus.replica &&
						projStore.projUsers.canAssignUser && (
							<AddProjUserButton i18n={i18n} />
						)}
				</div>
				<div className="FR AC" style={{ marginTop: 10 }}>
					{(permission.update || permission.delete) && (
						<DLContextMenuWithButton
							eleTestId="folders-btn"
							menuOptions={[
								{
									label: "Edit",
									value: "edit",
									available: permission.update,
									disabled: !userId,
									clickEvent: () =>
										projStore.projUsers.setOpenEdit(true),
								},
								{
									label: "Activate",
									value: "activate",
									disabled:
										!userId ||
										(userInfo &&
											userInfo.status === "Active"),
									available: permission.update,
									clickEvent: () => {
										let proceed = window.confirm(
											"Do you really want to activate this user?"
										)
										if (proceed === false) {
											return
										}
										projStore.projUsers.activateProjUser({
											UserId: userId,
										})
									},
								},
								{
									label: "Deactivate",
									value: "deactivate",
									disabled:
										!userId ||
										(userInfo &&
											userInfo.status === "Inactive"),
									available: permission.update,
									clickEvent: () => {
										let proceed = window.confirm(
											"Do you really want to deactivate this user?"
										)
										if (proceed === false) {
											return
										}
										projStore.projUsers.deactivateProjUser(
											userId
										)
									},
								},
								{
									label: "Unassign",
									value: "unassign",
									available: permission.delete,
									disabled: !userId,
									clickEvent: () => {
										let proceed = window.confirm(
											"Do you really want to unassign this user from the project?"
										)
										if (proceed === false) {
											return
										}
										if (
											userId !== null &&
											actBy !== null &&
											projId !== null
										) {
											projStore.projUsers.unAssignProjUser(
												userId,
												actBy,
												projId
											)
										} else {
											alert(
												"(Project User Ctx Menu) Props are invalid"
											)
										}
									},
								},
							]}
							useScroll={false}
							showButton={true}
							showIcon={false}
							btnText="Item Actions"
							btnVariant="outlined"
							btnColor="primary"
							btnSize="regular"
							btnClassName="ctx-menu-styles"
							eleIcon={<Icon path={mdiMenuDown} size={1} />}
						/>
					)}
				</div>

				<div
					className="FR AC counter-container"
					style={{ marginTop: 10 }}
				>
					<div
						className={
							userType === "all" ? "counter focused" : "counter"
						}
						onClick={() => handleUserType("all")}
					>
						{i18n.twTotalAssignedUsers || "Total assigned users"}:{" "}
						{totalUsers}
					</div>
					<div style={{ margin: "0 1rem" }}>|</div>
					<span
						className={userType === "internal" ? "focused" : ""}
						onClick={() => handleUserType("internal")}
					>
						{i18n.twInternalUsers || "Internal Users"}:{" "}
						{totalInternalUsers}
					</span>
					<div style={{ margin: "0 1rem" }}>|</div>
					<span
						className={userType === "external" ? "focused" : ""}
						onClick={() => handleUserType("external")}
					>
						{i18n.twExternalUsers || "External Users"}:{" "}
						{totalExternalUsers}
					</span>
					<div style={{ margin: "0 1rem" }}>|</div>
					<DLChip label={roleSet.title} eleTestId="role-set-title" />
				</div>
			</StyledHeader>
		)
	}

	return (
		<ReactTableV8
			// TODO: height setting
			tableColumns={ProjUserColumns(
				handleContextMenu,
				ableToUpdate,
				i18n,
				handleCheckbox,
				userId
			)}
			data={projUsers}
			handleContextMenu={(e: any, row: any) =>
				handleContextMenu(e, row.id)
			}
			menuId={DLProjSubMenus.users}
			height={store.ui.currentPageContentsAreaHeight - 58}
			customFilters={filterComponent}
			showPageSetting
			pageHeader={pageHeader}
			getRowClass={(rowInfo: any) => {
				const status = rowInfo.original.status
				let className = ""

				if (status === "Inactive") {
					className = className + " disabled"
				}
				return className
			}}
			onRowClick={(event: any, row: any) =>
				projStore.projUsers.setSelectedUser(row.id)
			}
		/>
	)
})

const filterComponent = ({ column }: { column: Column<any> }) => {
	switch (column.id) {
		case "type":
			return (
				<select
					onChange={(event: any) => {
						column.setFilterValue(event.target.value)
					}}
					style={{ width: "100%" }}
					value={(column.getFilterValue() ?? "all") as string}
					data-testid="type-select"
				>
					<option value="all">All</option>
					<option value="internal">Internal</option>
					<option value="external">External</option>
				</select>
			)

		case "actions":
			return

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const renderCustomCell = (props: any) => {
	const cellId = props.cell.column.id + "-cell"
	return <div data-testid={cellId}>{props.getValue()}</div>
}

const ProjUserColumns = (
	handleContextMenu: any,
	actionPermission: boolean,
	i18n: DLI18nProps,
	handleCheckbox: (id: string) => void,
	selectedId: string
) => {
	const columns: ReactTableColumnProps = [
		{
			header: "",
			accessorKey: "id",
			enableColumnFilter: false,
			size: 50,
			cell: (props: any) => {
				const id = props.getValue()
				const handleClick = () => {
					handleCheckbox(id)
				}

				return (
					<DLCheckbox
						eleTestId={`checkbox-${id}`}
						clickHandler={() => handleClick()}
						color="primary"
						isChecked={selectedId === id}
					/>
				)
			},
		},
		{
			header: i18n.twType || "Type",
			accessorKey: "type",
			cell: renderCustomCell,
			filterFn: singleSelectFilter,
		},
		{
			header: "Status",
			accessorKey: "status",
			cell: (props: any) => {
				const cellId = props.cell.column.id + "-cell"
				return (
					<div data-testid={cellId}>
						{props.getValue()}{" "}
						{props.row.original.inactiveDueTo
							? `(${props.row.original.inactiveDueTo})`
							: ""}
					</div>
				)
			},
		},
		{
			header: i18n.twRole || "Role",
			accessorKey: "roleId",
			cell: (props: any) => {
				// NOTE: Temporary use
				const roleNumId = props.row.original.roleNumId
				const roleInfo = findProjRoleInfo(roleNumId, "ProjUsersTable")
				const cellId = props.cell.column.id + "-cell"
				return (
					<div className="FR AC" data-testid={cellId}>
						{/* TODO: Need to update. but do not update below */}
						<DLAvatar
							size={18}
							fontSize={12}
							text={roleInfo?.shortName}
							eleTestId={`${roleInfo?.shortName}-avatar`}
							color={roleInfo?.color}
						/>
						<span style={{ marginLeft: 4 }}>{roleInfo?.name}</span>
					</div>
				)
			},
		},
		{
			header: i18n.twUserName || "User Name",
			accessorKey: "name",
			cell: renderCustomCell,
		},
		{
			header: i18n.twUserAliasId || "User Alias ID",
			accessorKey: "aliasId",
			cell: renderCustomCell,
		},
		{
			header: i18n.twUserOrgTitle || "Org. Title",
			accessorKey: "orgTitle",
			cell: renderCustomCell,
		},
		{
			header: i18n.twAccessRight || "Access Right",
			accessorKey: "accessTypeName",
			cell: renderCustomCell,
		},
		{
			header: i18n.rfPermission || "Roll Forward Permission",
			accessorKey: "canRollForward",
			cell: (props: any) => {
				const cellId = props.cell.column.id + "-cell"

				return (
					<div
						style={{ width: "100%", textAlign: "center" }}
						data-testid={cellId}
					>
						{props.getValue ? "Yes" : "-"}
					</div>
				)
			},
		},
		{
			header: i18n.twExpDate || "Expiration Date",
			accessorKey: "expDate",
			cell: renderCustomCell,
		},
	]

	if (actionPermission) {
		columns.push({
			header: "Action",
			enableColumnFilter: false,
			accessorKey: "actions",
			cell: (props: any) => {
				const userId = props.row.original.id

				return (
					<div style={{ width: "100%", textAlign: "center" }}>
						<DLButton
							variant="text"
							eleTestId="project-user-list-context-btn"
							clickHandler={(event: any) =>
								handleContextMenu(event, userId)
							}
						>
							<Icon path={mdiDotsHorizontal} size={0.8} />
						</DLButton>
					</div>
				)
			},
		})
	}

	return columns
}
