import React from "react"
import { DLContextMenu } from "../../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { ConsoleLog } from "../../../../../components/basic-elements"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"

export default observer(function OrgSetupRiskAssessmentTableMenus({
	permission,
	partialStore,
}: {
	permission: PermissionAsObjectProps
	partialStore: any
}) {
	ConsoleLog(" -=-=-=-=-$ OrgSetupRiskAssessmentTableMenus $-=-=-=-=- ")
	const orgStore = useOrgStore()
	const store = useRootStore()
	const dateFormat = store.global.dateFormat.value
	const timeZone = store.global.timeZone.uiValue
	const selectedRow = partialStore.getRiskAssessmentById(
		partialStore.selectedRiskAssessment
	)
	const isActive = selectedRow?.isActive
	const active = partialStore.getActiveList()
	const inactive = partialStore.getInactiveList()

	const handleActivateDeactivate = () => {
		if (isActive && active.length === 1) {
			partialStore.handleResponse({
				actionName: "deactivateRiskAssessment",
				status: ActionStatus.fail,
				code: 200,
				color: MessageColorType.red,
				message: "At least one item must remain active.",
				open: true,
				autoHide: true,
			})
			return
		}
		const proceed = window.confirm(
			`Are you sure you want to ${
				isActive ? "deactivate" : "activate"
			} this item?`
		)
		if (!proceed) return
		// Retrieve the selected row details
		const sourceList = isActive ? active : inactive
		const selectedRowDetails = sourceList.find(
			(item: any) =>
				item.riskAssessmentId === selectedRow.riskAssessmentId
		)
		// console.log("selectedRowDetails", selectedRowDetails)
		if (!selectedRowDetails) {
			partialStore.handleResponse({
				actionName: "actOrDeactivateRiskAssessment",
				status: ActionStatus.fail,
				code: 200,
				color: MessageColorType.red,
				message: "Selected item details could not be found.",
				open: true,
				autoHide: true,
			})
			return
		}
		// Extract required properties & perform the action
		const userInfo = orgStore.checkin.orgInfo
		const modifiedByUserName = `[${userInfo.userAliasId}] ${userInfo.userName}`

		partialStore.editRiskAssessment({
			RiskAssessmentItemId: selectedRowDetails.riskAssessmentId,
			IsActive: !isActive,
			modifiedByUserName,
		})
	}

	const menuOptions = [
		{
			label: `Edit`,
			value: "ctx-edit-ra",
			available: permission.update,
			clickEvent: () =>
				partialStore.setEditRiskAssessmentDialogOpen(true),
		},
		{
			label: isActive ? "Deactivate" : "Activate",
			value: `ctx-${isActive ? "deactivate" : "activate"}`,
			available: permission.update,
			clickEvent: handleActivateDeactivate,
		},
	]

	const handleClose = () => {
		partialStore.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}

	return (
		<DLContextMenu
			eleId="org-setup-ra-ctx-menus"
			clickPoint={{
				mouseX: partialStore.clickPoint.mouseX,
				mouseY: partialStore.clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={menuOptions}
			hasDivider={[0]}
		/>
	)
})
