export const MySummaryTableTitles = {
	finalArchiveDateWithIn5Days:
		"My Assigned AUL Projects - Final Archive Deadline within 5 days",
	expectedArchiveDateWithIn5Days:
		"My Assigned AUL Projects - Expected Archive Deadline within 5 days",
	expectedReportDateWithIn5Days:
		"My Assigned AUL Projects - Expected Final Issuance Deadline within 5 days",
	finalArchiveDateWithIn6To20Days:
		"My Assigned AUL Projects - Final Archive Deadline within 6 ~ 20 days",
	expectedArchiveDateWithIn6To20Days:
		"My Assigned AUL Projects - Expected Archive Deadline within 6 ~ 20 days",
	expectedReportDateWithIn6To20Days:
		"My Assigned AUL Projects - Expected Final Issuance Deadline within 6 ~ 20 days",
}

export const MySummaryDueInDays = {
	finalArchiveDeadlineDate_5: "finalArchiveDeadlineDate_5",
	expectedArchiveDeadlineDate_5: "expectedArchiveDeadlineDate_5",
	expectedReportDate_5: "expectedReportDate_5",
	finalArchiveDeadlineDate_6_20: "finalArchiveDeadlineDate_6_20",
	expectedArchiveDeadlineDate_6_20: "expectedArchiveDeadlineDate_6_20",
	expectedReportDate_6_20: "expectedReportDate_6_20",
}

export const DueDaysPropForMySummary = {
	dueDaysForFinalArchiveDeadlineDate: "dueDaysForFinalArchiveDeadlineDate",
	dueDaysForExpectedArchiveDeadlineDate:
		"dueDaysForExpectedArchiveDeadlineDate",
	dueDaysForExpectedReportDate: "dueDaysForExpectedReportDate",
}
