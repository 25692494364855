import { flow } from "mobx"
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const GetForceArchivedPreConditionStatus = (self: any) => ({
	/**
	 *
	 * @param self
	 * @returns
	 *
	 * * i18n records
	 * - success, fail
	 *
	 */
	getForceArchivedPreConditionStatus(projId: string) {
		// 0.
		const actionName = "getForceArchivedPreConditionStatus"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		const clientId = idToNumber(projId, IdType.project)
		// 3. call API
		self.readForceArchivedPreConditionStatus(clientId)
			.then((response: any) => {
				// if success
				if (
					response.status === 200 &&
					response.data.status.code === 1
				) {
					const fetchedData = response.data.data
					const files: any = []

					fetchedData.ForceArchivedPreprocessPdfFiles.map(
						(item: any) => {
							files.push({
								fileName: item.Name,
								isPreProcessed: item.Status,
								downloadUrl: item.DownloadUrl,
								viewUrl: item.ViewUrl,
							})
						}
					)
					const reOrganizedData = {
						projectSize: fetchedData.ProjectSize
							? fetchedData.ProjectSize
							: null,
						isPreProcessed:
							fetchedData.HasForceArchivedPreprocessPdf,
						hasForceArchivedZip: fetchedData.HasForceArchivedZip,
						forceArchivedZipUrl: fetchedData.ForceArchivedZipUrl,
						preProcessFiles: files,
					}
					self.setFaPreConditionData(reOrganizedData)
					// if success
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetForceArchivedPreConditionStatus
