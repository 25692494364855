import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import CheckEPMatch from "./CheckEPMatch"

const CheckEPMatchController = observer(() => {
	const orgStore = useOrgStore()
	const actionName = "getEPMatchData"
	const menuId = DLOrgSubMenus.check_ep_match
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)
	const needRefresh = orgStore.checkEPMatch.needRefresh

	useEffect(() => {
		menuAccess && needRefresh && orgStore.checkEPMatch.getEPMatchData()
	}, [menuAccess, needRefresh])

	return (
		<>
			{menuAccess ? (
				<CheckEPMatch
					partialStore={orgStore.checkEPMatch}
					actionStatus={orgStore.checkEPMatch.getActionStatus(
						actionName
					)}
					permission={permission}
				/>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

export default CheckEPMatchController
