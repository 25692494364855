import React, { useEffect } from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import { DLDialog, DLSpinner } from "../../../../../components/basic-elements"
import { ProjectInfoContainer } from "../../am-archived-projects/dialogs/ProjectInfoContainer"
import { DLProjectModelType } from "../../../../../service-modules/project-module/data-model/project-module-data-models"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import FaPreProcessPDF from "./FaPreProcessPDF"

export default observer(function FaZipRegenerationDialog({
	partialStore,
	from,
}: {
	partialStore: any
	from: string
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const archMgmtStore = orgStore.archiveMgmt

	const dnt = store.global.getDntFormat

	const projectId = partialStore.selectedProj
	const projectInfo = partialStore.viewFASelectedProjInfo(
		partialStore.selectedProj,
		dnt
	)

	console.log(projectInfo, "projectInfo")

	const getPreConActionName = "getForceArchivedPreConditionStatus"

	const getPreConStatus = archMgmtStore.getActionStatus(getPreConActionName)
	const { isPreProcessed, hasForceArchivedZip, forceArchivedZipUrl } =
		archMgmtStore.faPreConditionData

	const isExportWip =
		archMgmtStore.getActionStatus("exportArchivedZipFile") ===
		ActionStatus.loading

	const handleExportForceArchivedZip = () => {
		if (
			!window.confirm(
				"Are you sure you want to export the force-archived zip file?"
			)
		)
			return
		if (forceArchivedZipUrl.length > 0) {
			const downloadWPFile = document.createElement("a")
			downloadWPFile.href = forceArchivedZipUrl

			downloadWPFile.setAttribute("download", "")
			document.body.appendChild(downloadWPFile)
			downloadWPFile.click()
			downloadWPFile.remove()
		}
	}

	useEffect(() => {
		// to hide the pdf file list
		archMgmtStore.responses.setResponse(getPreConActionName, {
			actionName: getPreConActionName,
			status: ActionStatus.standby,
		})

		archMgmtStore.responses.setResponse(
			"generateForceArchivedPreProcessPdfs",
			{
				actionName: "generateForceArchivedPreProcessPdfs",
				status: ActionStatus.standby,
			}
		)

		archMgmtStore.getForceArchivedPreConditionStatus(projectId)
	}, [])

	return (
		<DLDialog
			eleTestId="export-archive-zip-dialog"
			isOpen={archMgmtStore.archiveZipRegenerationDialog}
			setIsOpen={archMgmtStore.setArchiveZipRegenerationDialog}
			showCloseBtn={true}
			dialogTitle="Force-Archived ZIP"
			dialogContents={
				projectInfo ? (
					<ForceArchivedZipRegenerationDialogForm
						projInfo={projectInfo}
						partialStore={archMgmtStore}
						preProcessStatus={isPreProcessed}
						getPreConStatus={getPreConStatus}
						selectedProj={partialStore.selectedProj}
					/>
				) : (
					<DLSpinner />
				)
			}
			maxWidth="sm"
			fullWidth={true}
			cancelBtnText="Cancel"
			draggable
			actionBtn="Export"
			actionReady={
				getPreConStatus === ActionStatus.success &&
				isPreProcessed &&
				hasForceArchivedZip
			}
			cannotUpdate={isExportWip}
			handleAction={handleExportForceArchivedZip}
		/>
	)
})

const ForceArchivedZipRegenerationDialogForm = ({
	projInfo,
	partialStore,
	preProcessStatus,
	getPreConStatus,
	selectedProj,
}: {
	projInfo: DLProjectModelType
	partialStore: any
	preProcessStatus: boolean
	getPreConStatus: ActionStatus
	selectedProj: string
}) => {
	return (
		<div>
			<ProjectInfoContainer projInfo={projInfo} isForceArchived={true} />
			<div style={{ height: "3rem" }} />
			<FaPreProcessPDF
				partialStore={partialStore}
				selectedProj={selectedProj}
			/>
			<div style={{ height: "3rem" }} />
			{getPreConStatus === ActionStatus.loading &&
				"Checking Pre-process PDFs status... Please wait"}
			{getPreConStatus === ActionStatus.success &&
				!preProcessStatus &&
				"* Please generate Pre-process PDFs first to export"}
			{getPreConStatus === ActionStatus.fail &&
				"Failed to check the Pre-process PDFs status. Please try again"}
		</div>
	)
}
