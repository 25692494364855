import { types } from "mobx-state-tree"
import MapNumbersViewModel from "./map-numbers.view-model"
import MapNumbersModel from "./map-numbers.model"

export const MapNumbersStore = () =>
	types
		.compose(MapNumbersModel(), MapNumbersViewModel)
		.named("MapNumbersStore")

export const initialStore = {
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const MapNumbersStoreInstance = MapNumbersStore().create(initialStore)

export default MapNumbersStoreInstance
