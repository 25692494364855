import React, { useState, memo } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import { DLSpinner } from "../../../../components/basic-elements"

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		height: "70vh",
		backgroundColor: theme.palette.background.paper,
	},
	buttonGroup: {
		display: "flex",
		marginBottom: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		gap: theme.spacing(1), // Space between buttons
	},
	button: {
		//textTransform: "none", // Disable uppercase text
	},
	buttonActive: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},
	content: {
		flexGrow: 1,
		// padding: theme.spacing(2),
		overflowY: "auto", // Scroll only if the content overflows
	},
	spinner: {
		height: 540,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
}))

export default memo(function MySummaryButtons({
	summaryData,
}: {
	summaryData: Array<{ label: string; content: JSX.Element }>
}) {
	const classes = useStyles()
	const [selectedButton, setSelectedButton] = useState(0)
	const [loading, setLoading] = useState(false)

	const handleButtonClick = (index: number) => {
		setLoading(true)
		setTimeout(() => {
			setSelectedButton(index)
			setLoading(false)
		}, 500) // Simulates loading time
	}

	return (
		<div className={classes.root}>
			<Box className={classes.buttonGroup}>
				{summaryData.map((button, index) => (
					<Button
						key={index}
						variant="contained"
						className={`${classes.button} ${
							selectedButton === index ? classes.buttonActive : ""
						}`}
						onClick={() => handleButtonClick(index)}
					>
						{button.label}
					</Button>
				))}
			</Box>
			<Box className={classes.content}>
				{loading ? (
					<div className={classes.spinner}>
						<DLSpinner />
					</div>
				) : (
					summaryData[selectedButton].content
				)}
			</Box>
		</div>
	)
})
