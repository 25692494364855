import React, { useState } from "react"
import {
	DLButton,
	DLDatepicker,
} from "../../../../../components/basic-elements"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import { PageContainer } from "../../../../../components/app-frame-elements"
import styled from "styled-components"
import { ProjectStatus } from "../../../../../common-models/enumerations/project-related-enums"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import {
	addTzToDate,
	getTzDate,
} from "../../../../../library/converters/date-utc-converter"
import { addDays } from "date-fns"

export default observer(function RationaleFinalReportDate({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	const store = useRootStore()
	const projStore = useProjStore()

	const [finalReportDate, setFinalReportDate] = useState<any>("")
	const [finalArchDeadlineDate, setFinalArchDeadlineDate] = useState<any>("")

	const projInfo = projStore.projInfo.projInfo

	const tzUiValue = store.global.timeZone.uiValue
	const dateFormat = store.global.dateFormat.value

	const handleDateInput = (date: string) => {
		const days = parseInt(projInfo.archPolicyPeriodName.split(" ")[0]) || 0 // Extract and parse days safely
		const finalReportDate = addTzToDate(date, tzUiValue)
		setFinalReportDate(finalReportDate)

		if (days > 0) {
			const finalArchiveDate = addTzToDate(
				addDays(new Date(date), days),
				tzUiValue
			)
			setFinalArchDeadlineDate(finalArchiveDate)
		}
	}

	const handleUpdate = () => {
		const projInformation = {
			...projInfo,
			financialYearDate: addTzToDate(
				projInfo.financialYearDate,
				tzUiValue
			),
			periodEndDate: addTzToDate(projInfo.periodEndDate, tzUiValue),
			expectedReportDate: addTzToDate(
				projInfo.expectedReportDate,
				tzUiValue
			),
			expectedArchDeadlineDate: addTzToDate(
				projInfo.expectedArchDeadlineDate,
				tzUiValue
			),
			finalReportDate,
			finalArchDeadlineDate,
		}
		projStore.projInfo.editProjInfo(projInformation)
	}

	// If success call ratinale file
	if (projStore.projInfo.getActionStatus("editProjInfo") === "SUCCESS") {
		projStore.rationales.getRationales()
	}
	return (
		<PageContainer hasToolbar={false}>
			<StyledCannotAccess className="FC AC JC">
				<p>
					Please set <b>Final Report Date</b> to get Rationale
					required file list
				</p>
				{permission.update &&
					!projInfo.isLocked &&
					(projInfo.statusName === ProjectStatus.normal ||
						projInfo.statusName === ProjectStatus.unarchived) && (
						<>
							<div>
								<DLDatepicker
									eleTestId="final-report-date-field"
									eleName="finalReportDate"
									selected={
										finalReportDate
											? getTzDate({
													date: finalReportDate,
													timeZone: tzUiValue,
													dateFormat,
											  })
											: null
									}
									eleLabel="Final Report Date"
									onChange={(date: any) =>
										handleDateInput(date)
									}
									format={dateFormat}
									fullWidth
								/>
							</div>
							<div>
								<DLButton
									eleTestId="save-final-report-date-btn"
									clickHandler={handleUpdate}
									color="primary"
									disabled={
										finalReportDate === "" ||
										finalArchDeadlineDate === ""
									}
									eleClassName="save-btn"
								>
									Save
								</DLButton>
							</div>
						</>
					)}
			</StyledCannotAccess>
		</PageContainer>
	)
})

const StyledCannotAccess = styled.div`
	width: 100%;
	height: 100%;
	.save-btn {
		margin-top: 1rem;
	}
`
