import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import { initialStore } from "./assign-fs-accounts.provider"

import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"
import {
	FinancialGroupModel,
	FSAccountsModel,
} from "./data-models/assign-fs-accounts.data-model"
import { getTreeFromFlatData } from "../../../../../components/basic-elements/tree-list/getTreeFromFlatData"
import GetFinancialGroups from "./view-model-actions/get-financial-groups"
import GetFSAccounts from "./view-model-actions/get-fs-accounts"

const AssignFSAccountsViewModel = types
	.model({
		fsAccounts: types.array(FSAccountsModel),
		financialGroups: types.array(FinancialGroupModel),
		selectedAccountId: "",
		clickPoint: ClickPoint,
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar, // snackbar type 2
	})
	.actions((self) => ({
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setFinancialGroups(data: any) {
			self.financialGroups = data
		},
		setSelectedAccountId(id: string) {
			self.selectedAccountId = id
		},
		pushItemToFinancialGroup(item: any) {
			self.financialGroups.push(item)
		},
		setFSAccounts(data: any) {
			self.fsAccounts = data
		},
		toggleExpanded(itemId: string, expanded?: boolean) {
			const target = self.financialGroups.find(
				(item: any) => item.id === itemId
			)
			if (target) {
				target.expanded = expanded ?? !target.expanded
			}
		},
		changeAccountStatus(itemId: string, status: boolean) {
			const index = self.fsAccounts.findIndex(
				(item: any) => item.id === itemId
			)
			self.fsAccounts[index].isAssigned = status
		},
		removeItemFromFinancialGroup(id: string) {
			const targetIndex = self.financialGroups.findIndex(
				(item: any) => item.id === id
			)
			self.financialGroups.splice(targetIndex, 1)
		},
		pushAccountOrChangeStatus(node: any, status: boolean) {
			const index = self.fsAccounts.findIndex(
				(item: any) => item.id === node.id
			)
			if (index !== -1) {
				self.fsAccounts[index].isAssigned = status
			} else {
				self.fsAccounts.push({
					id: node.id,
					code: node.code,
					name: node.title || node.name,
					balance: node.balance,
					isAssigned: status,
				})
			}
		},
	}))
	.actions(GetFinancialGroups)
	.actions(GetFSAccounts)
	.views((self) => ({
		flatListAsTree() {
			const tree = getTreeFromFlatData(self.financialGroups, "title")

			return tree
		},
		viewUnassignedFSAccounts() {
			return self.fsAccounts.filter((i) => !i.isAssigned)
		},
		getSelectedFSGroupItemById(id: string) {
			return self.financialGroups.find((item: any) => item.id === id)
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default AssignFSAccountsViewModel
