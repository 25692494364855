import React, { useEffect } from "react"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import Adjustments from "./Adjustments"

export default observer(function AdjustmentsController() {
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.adjustments
	const isLocked = projStore.projInfo.projInfo.isLocked
	const isArchived = projStore.projInfo.projInfo.isArchived
	const menuAccess = projStore.checkin.checkAccess(menuId)
	const permission = projStore.checkin.getActionsAsObject(
		menuId,
		isLocked,
		isArchived
	)

	useEffect(() => {
		projStore.fsAdjustments.getAdjustments()
	}, [])

	return (
		<>
			{menuAccess ? (
				<Adjustments
					partialStore={projStore.fsAdjustments}
					permission={permission}
					actionStatus={projStore.fsAdjustments.getActionStatus(
						"getAdjustments"
					)}
				/>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
