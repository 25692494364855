import { types } from "mobx-state-tree"
// ViewModel, Modle, UiHelper
import CheckEPMatchModel from "./check-ep-match.model"
import CheckEPMatchViewModel from "./check-ep-match.view-model"
// common models
// apis
import * as api from "./check-ep-match.apis"

export const CheckEPMatchStore = ({ apiRead }: api.CheckEPMatchApiProps) =>
	types
		.compose(
			CheckEPMatchModel({
				apiRead,
			}),
			CheckEPMatchViewModel
		)
		.named("CheckEPMatchStore")

export const initialStore = {
	// viewModel related parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
}

const CheckEPMatchStoreInstance = CheckEPMatchStore({
	apiRead: api.read,
}).create(initialStore)

export default CheckEPMatchStoreInstance
