import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { hasEmptyProps } from "@datalobby/common"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

const AddArchiveDatePolicy = (self: any) => ({
	addArchiveDatePolicy(title: string, description: string) {
		// 0.
		const actionName = "addArchiveDatePolicy"
		// 1.
		const checkedProps = hasEmptyProps({ title })
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		/**
		 * PARAMETER or PAYLOAD READY
		 */
		self.createArchiveDatePolicy(checkedProps.props.title, description)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					// set success case response
					const policy = response.data.Data

					const policyId = idToString(
						policy.ArchiveDatePolicySetId,
						IdType.archivePolicy
					)

					const flatPolicy = {
						id: policyId,
						parentId: null,
						title: policy.ArchiveDatePolicySetName,
						description: policy.ArchiveDatePolicySetDescription,
						expanded: true,
						type: "policy",
						archiveDatePolicyItems: policy.ArchiveDatePolicyItems,
						isActive: true,
						isDefault: policy.IsDefault,
					}

					self.pushItemToFlatList(flatPolicy)

					self.setOpenCreateDialog(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Successfully added new date policy",
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default AddArchiveDatePolicy
