import React from "react"
import {
	DLTextField,
	DLDatepicker,
	DLComboBox,
} from "../../../../../../components/basic-elements"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { getTzDate } from "../../../../../../library/converters/date-utc-converter"

// This form does not use stored data.
export default observer(function AULProjectInformationForm({
	inputs,
	handleChange,
	handleDateInput,
	dntFormat,
}: any) {
	const { timeZone, dateFormat } = dntFormat

	return (
		<StyledAULProjectInformationForm>
			<div className="section-row">
				<div className="input-container">
					<DLComboBox
						eleTestId="client-select"
						eleName="clientName"
						placeholder="Search Projects by Client Master Name"
						onChange={(value: any) =>
							console.log("clientId", value)
						}
						getOptionLabel={(option: any) => option.title}
						options={[]}
						// defaultValue={{
						// 	id: clientId,
						// 	title: clientName,
						// 	clientAliasId: clientAliasId,
						// }}
					/>
				</div>
			</div>
			<div className="aul-proj-info-form section">
				<div className="section-row FR JSB">
					<div className="input-container">
						<DLTextField
							eleTestId="aulPeriod-field"
							eleId="aulPeriod"
							eleLabel="AUL Period"
							eleRequired
							eleFullWidth
							eleValue={inputs.aulPeriod}
							eleOnChange={handleChange}
						/>
					</div>
					<div className="input-container">
						<DLTextField
							eleTestId="aulProjectAliasId-field"
							eleId="aulProjectAliasId"
							eleLabel="AUL Project AliasId"
							// eleDefaultValue={inputs.clientAliasId}
							eleRequired
							eleFullWidth
							eleValue={inputs.aulProjectAliasId}
							eleOnChange={handleChange}
						/>
					</div>
				</div>
				<div className="section-row FR JSB">
					<div className="input-container">
						<DLTextField
							eleTestId="aulClientMasterName-field"
							eleId="aulClientMasterName"
							eleLabel="AUL Client Master Name"
							eleRequired
							eleValue={inputs.aulClientMasterName}
							eleOnChange={handleChange}
							eleFullWidth
						/>
					</div>
					<div className="input-container">
						<DLTextField
							eleTestId="aulVersion-field"
							eleId="aulVersion"
							eleLabel="AUL Project AliasId"
							eleValue={inputs.aulVersion}
							eleOnChange={handleChange}
							eleFullWidth
							eleRequired
						/>
					</div>
				</div>
				<div className="section-row FR JSB">
					<div className="input-container">
						<DLTextField
							eleTestId="aulGroupName-field"
							eleId="aulGroupName"
							eleLabel="AUL Group Name"
							eleRequired
							eleValue={inputs.aulGroupName}
							eleOnChange={handleChange}
							eleFullWidth
						/>
					</div>
					<div className="input-container">
						<DLTextField
							eleTestId="aulEPUsers-field"
							eleId="aulEPUsers"
							eleLabel="AUL EP Users"
							eleValue={inputs.aulEPUsers}
							eleOnChange={handleChange}
							eleFullWidth
							eleRequired
						/>
					</div>
				</div>
				<div className="section-row FR JSB">
					<div className="input-container">
						<DLTextField
							eleTestId="aulIsLocked-field"
							eleId="aulIsLocked"
							eleLabel="AUL Is Locked"
							eleRequired
							eleValue={inputs.aulIsLocked}
							eleOnChange={handleChange}
							eleFullWidth
						/>
					</div>
					<div className="input-container">
						<DLTextField
							eleTestId="aulQCUsers-field"
							eleId="aulQCUsers"
							eleLabel="AUL QC Users"
							eleValue={inputs.aulQCUsers}
							eleOnChange={handleChange}
							eleFullWidth
							eleRequired
						/>
					</div>
				</div>
				<div className="section-row FR JSB">
					<div className="input-container">
						<DLTextField
							eleTestId="aulProjectTypeName-field"
							eleId="aulProjectTypeName"
							eleLabel="AUL Project Type"
							eleRequired
							eleValue={inputs.aulProjectTypeName}
							eleOnChange={handleChange}
							eleFullWidth
						/>
					</div>
					<div className="input-container">
						<DLDatepicker
							eleTestId="aulFinalReportDate-field"
							eleName="aulFinalReportDate"
							selected={
								inputs.aulFinalReportDate
									? getTzDate({
											date: inputs.aulFinalReportDate,
											timeZone,
											dateFormat,
									  })
									: null
							}
							eleLabel="AUL Final Report Date"
							onChange={(date: any) =>
								handleDateInput("aulFinalReportDate", date)
							}
							format={dateFormat}
							fullWidth
						/>
					</div>
				</div>
				<div className="section-row FR JSB">
					<div className="input-container">
						<DLTextField
							eleTestId="aulProjectName-field"
							eleId="aulProjectName"
							eleLabel="AUL Project Name"
							eleRequired
							eleValue={inputs.aulProjectName}
							eleOnChange={handleChange}
							eleFullWidth
						/>
					</div>
				</div>
			</div>
		</StyledAULProjectInformationForm>
	)
})

const StyledAULProjectInformationForm = styled.div`
	// width: 700px;
	.section {
		margin-bottom: 2rem;
		.section-title {
			font-weight: 700;
			margin-bottom: 1rem;
		}
		.section-row {
			width: 100%;
			.input-container {
				width: calc(50% - 1rem);
				margin-bottom: 1rem;
				.financial-year-field {
					min-width: 64px;
					width: 30%;
					margin-right: 1rem;
				}
				.period-end-field {
					width: calc(70% - 1rem);
				}
			}
		}
	}
	.MuiAutocomplete-clearIndicator {
		display: none;
	}
`
