import React from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import { ActionStatus } from "@datalobby/types"
import styled from "styled-components"
import Icon from "@mdi/react"
import { mdiMenuDown } from "@mdi/js"
import { DLContextMenuWithButton } from "../../../../components/basic-elements"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import AddMapCategoryDialog from "./sub-components/AddMapCategoryDialog"
import { observer } from "mobx-react-lite"

export default observer(function MapNumbers({
	partialStore,
	actionStatus,
	permission,
}: {
	partialStore: any
	actionStatus: ActionStatus
	permission: PermissionAsObjectProps
}) {
	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			isLoading={actionStatus === ActionStatus.loading}
		>
			<StyledMapNumbers>
				<div className="FR" style={{ marginTop: 10 }}>
					<DLContextMenuWithButton
						eleTestId="folders-btn"
						menuOptions={[
							{
								label: `Create Map Category`,
								value: "ctx-create-map-category",
								available: permission.delete,
								clickEvent: () =>
									partialStore.setAddMapCategoryDialogOpen(
										true
									),
							},
						]}
						useScroll={false}
						showButton={true}
						showIcon={false}
						btnText="Map Categories"
						btnVariant="outlined"
						btnColor="primary"
						btnSize="regular"
						btnClassName="ctx-menu-styles"
						eleIcon={<Icon path={mdiMenuDown} size={1} />}
					/>
				</div>
				<div>
					<div className="FR AC JSB header">
						<div>Number</div>
						<div>Name</div>
						<div>Balance</div>
						<div>PY Balance</div>
					</div>
					<div></div>
				</div>
			</StyledMapNumbers>
			{partialStore.addMapCategoryDialogOpen && (
				<AddMapCategoryDialog partialStore={partialStore} />
			)}
		</PageContainer>
	)
})

const StyledMapNumbers = styled.div`
	padding: 0 1rem;
	.header {
		margin-top: 15px;
		border-top: 1px solid;
		border-bottom: 1px solid;
		font-weight: 900;
		font-size: ${(props) => props.theme.shared.textRg};
		height: 36px;
	}
`
