import React from "react"
import styled from "styled-components"

function UserInfoHeaderCard({ user }: any) {
	const name = user?.name || "-"
	const email = user?.email || "-"
	const aliasId = user?.aliasId || "-"

	return (
		<div style={{ padding: "0 1rem" }}>
			<StyledUserInfo>
				<div className="table-title">My AUL Information</div>
				<InfoRow label={"Username"} value={name} testId="username" />
				<InfoRow label={"Email"} value={email} testId="email" />
				<InfoRow
					label={"User Alias Id"}
					value={aliasId}
					testId="userAliasId"
				/>
			</StyledUserInfo>
		</div>
	)
}

export default UserInfoHeaderCard

const InfoRow = ({
	label,
	value,
	testId,
}: {
	label: string
	value: any
	testId: string
}) => {
	return (
		<div className="info-row">
			<div className="label-area">{label}</div>
			<div className="colon-spacing">:</div>
			<div className="value-area" data-testid={`projDetails-${testId}`}>
				{value}
			</div>
		</div>
	)
}

const StyledUserInfo = styled.div`
	width: 45%;
	background-color: #ffffff;
	// border: 2px solid lightgray;
	border-radius: 8px;
	box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
	margin: 1rem 0;
	padding: 1rem;
	overflow-y: scroll;
	position: relative;
	z-index: 999;

	.info-row {
		display: flex;
		padding: 0.3rem 0 0.3rem 1rem;
		.label-area {
			min-width: 10rem;
			font-weight: bold;
		}
		.colon-spacing {
			min-width: 2rem;
		}
		.value-area {
		}
	}

	.table-title {
		font-size: 1rem;
		font-weight: bold;
		margin-bottom: 4px;
	}
`
