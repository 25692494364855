import { types } from "mobx-state-tree"

export const UploadDataModel = types.model({
	column1: types.string,
	column2: types.string,
	column3: types.number,
	column4: types.number,
	column5: types.number,
})

export const DatasetModel = types.model({
	id: types.string,
	source: types.string,
	datasetType: types.string,
	dateAdded: types.string,
	addedBy: types.string,
	status: types.string,
})

export const ColumnMapping = types.model({
	id: types.string,
	columns: types.frozen(),
})
