import { getRequest } from "../../../library/api-requests"
import { TOKEN_TYPE } from "../../../library/api-requests/shared-for-api-requests"
import { postToDownload } from "../../../library/api-requests/request-post-others"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

//
//
// ----- download file
export type DownloadFileApiProps = {
	params:
		| {
				FileID: number
				Type: number
		  }
		| {
				FileID: number
		  }
	menuId: DLOrgSubMenus | DLProjSubMenus
}
export async function downloadFile(props: DownloadFileApiProps) {
	const { params, menuId } = props
	let tokenType = TOKEN_TYPE.projToken

	let endpoint = "/IndividualFile_Download_S3Files"

	if (menuId === DLOrgSubMenus.proj_templates) {
		endpoint = "/IndividualFile_DownloadS3_Template"
		tokenType = TOKEN_TYPE.orgToken
	}

	const response = await getRequest({
		url: endpoint,
		params,
		tokenType, // NOTE: WARNING: NEED TO CHECK AGAIN: this API is working with orgToken also (even when the endpoint is the first one)
	})

	return response
}

//
//
// ----- download folder
export async function downloadFolder(
	params: any,
	endpoint: string,
	menuId: DLOrgSubMenus | DLProjSubMenus
) {
	// wp:  "/PartialZipfiles"
	// pf: "/PermanentFilePartialZipfiles"

	let tokenType = TOKEN_TYPE.projToken

	if (menuId === DLOrgSubMenus.proj_templates) {
		tokenType = TOKEN_TYPE.orgToken

		const response = await getRequest({
			url: endpoint,
			params,
			tokenType,
			responseType: "arraybuffer",
		})

		return response
	} else {
		const response = await postToDownload({
			url: endpoint,
			params,
			tokenType, // TODO: test with org token or login token
		})

		return response
	}
}

//
//
// ----- download all
export type DownloadAllApiProps = {
	params:
		| {
				clientID: number // means project ID
				// Lang: en
				// UserID: number
		  }
		| {
				client: number // means project ID
				// Lang: en
				// UserID: number
		  }
		| {
				ParentFldID: number
		  }

	menuId: DLOrgSubMenus | DLProjSubMenus
}
export async function downloadAll(props: DownloadAllApiProps) {
	const { params, menuId } = props
	let tokenType = TOKEN_TYPE.projToken

	let endpoint = "/Zipfiles"

	if (menuId === DLProjSubMenus.pf) {
		endpoint = "/PermanentFileZipfiles"
	}

	if (menuId === DLOrgSubMenus.proj_templates) {
		tokenType = TOKEN_TYPE.orgToken
		endpoint = "/ExportZipTemplateFiles"
	}

	const response = await getRequest({
		url: endpoint,
		params,
		tokenType, // NOTE: WARNING: NEED TO CHECK AGAIN: this API is working with orgToken also (even when the endpoint is the first one)
		responseType: "arraybuffer",
	})

	return response
}
