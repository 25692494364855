import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import { initialStore } from "./map-numbers.provider"

import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"
import { MapNumbersModel } from "./data-models/map-numbers.data-model"
import GetMapNumbers from "./view-model-actions/get-map-numbers"

const MapNumbersViewModel = types
	.model({
		flatList: types.array(MapNumbersModel),
		addMapCategoryDialogOpen: false,
		selectedAdjustment: "",
		clickPoint: ClickPoint,
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar, // snackbar type 2
	})
	.actions((self) => ({
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setFlatList(list: any) {
			self.flatList = list
		},
		setSelectedAdjustment(id: string) {
			self.selectedAdjustment = id
		},
		setAddMapCategoryDialogOpen(request?: boolean) {
			self.addMapCategoryDialogOpen =
				request ?? !self.addMapCategoryDialogOpen
		},
	}))
	.actions(GetMapNumbers)
	.views((self) => ({
		viewMapNumbers() {
			return self.flatList
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default MapNumbersViewModel
