import React, { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { FormControl, MenuItem, Select, TextField } from "@material-ui/core"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { DLButton } from "../../../../../components/basic-elements"
import { DLCheckbox } from "@datalobby/components"
import { useHistory, useParams } from "react-router-dom"

const options = [
	{
		section: "Account details",
		label: "Account Number",
		value: "account-number",
	},
	{
		section: "Account details",
		label: "Account Name",
		value: "account-name",
	},
	{
		section: "Account details",
		label: "Financial Group Name",
		value: "fin-group-name",
	},
	{
		section: "Annual balance amounts",
		label: "Current year balance",
		value: "curr-year-balance",
	},
	{
		section: "Annual balance amounts",
		label: "Prior year balance",
		value: "prior-year-balance",
	},
]

const debitCreditOptions = [
	{ label: "Debit and Credit", value: "debit-credit" },
	{ label: "Debit only", value: "debit-only" },
	{ label: "Credit only", value: "credit-only" },
]

export default observer(function AssignFields() {
	const store = useRootStore()
	const projStore = useProjStore()
	const partialStore = projStore.importFSDatasets
	const history = useHistory()
	const [includeZeroBalance, setIncludeZeroBalance] = useState(false)
	let { parentId } = useParams<{ parentId: string }>()

	console.log(parentId, "idid")

	const [selectedOptions, setSelectedOptions] = useState<any>({
		column1: null,
		column2: null,
		column3: null,
		column4: null,
		column5: null,
	})

	useEffect(() => {
		const mappedColumns = partialStore.viewMappedColumnsById(parentId)
		if (mappedColumns) {
			setSelectedOptions(mappedColumns)
		}
	}, [parentId])

	const handleSelectionChange = (name: any, value: any) => {
		setSelectedOptions((prev: any) => ({
			...prev,
			[name]: value,
		}))
	}

	const contentHeight = store.ui.contentsAreaHeight
	const defaultUrl = projStore.checkin.defaultUrl

	const handleCancel = () => {
		history.push(defaultUrl + `/fs-data/import-fs-datasets`)
	}

	const handleImport = () => {
		const hasEmpty = Object.values(selectedOptions).some((i) => i === null)
		if (hasEmpty) {
			alert("Please select all columns")
			return
		}
		partialStore.setMappedColumns(parentId, selectedOptions)
		partialStore.updateDatasetById(parentId)
		history.push(defaultUrl + `/fs-data/import-fs-datasets`)
	}

	console.log(selectedOptions, "selectedOptions")

	const mappedColumns = Columns().map(({ header, accessorKey }) => {
		const value = selectedOptions[accessorKey] // Get the value from the object
		const option = options.find((option) => option.value === value) // Find the matching option

		return {
			header: option?.label || header, // Use label from options or fallback to original header
			accessorKey,
		}
	})

	return (
		<StyledAssignFields>
			{!partialStore.showHeader && (
				<>
					<div className="FR JSB AC">
						<h3>Assign Fields for Final Balance</h3>
						<div>
							<FormControl style={{ minWidth: 120 }} size="small">
								<Select
									labelId="data-layout-label"
									id="data-layout"
									value={[]}
									variant="outlined"
									displayEmpty
									placeholder="Select data layout"
									onChange={() => {}}
									fullWidth
								>
									<MenuItem disabled value="">
										Select data layout
									</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>

					<div className="column-assign-section FR JSB">
						{Object.keys(selectedOptions).map((name, index) => {
							const key = name as keyof typeof selectedOptions
							const value = selectedOptions[key] ?? ""
							return (
								<div key={index} style={{ width: 300 }}>
									<Autocomplete
										options={options}
										groupBy={(option) => option.section}
										getOptionLabel={(option) =>
											option.label
										}
										style={{ width: 242, marginBottom: 20 }}
										size="small"
										onChange={(event, value) =>
											handleSelectionChange(
												key,
												value?.value
											)
										}
										value={
											options.find(
												(option) =>
													option.value === value
											) || null
										}
										renderInput={(params) => (
											<TextField
												variant="outlined"
												{...params}
												label={`Column ${index + 1}`}
											/>
										)}
									/>
									{[
										"curr-year-balance",
										"prior-year-balance",
									].includes(value) && (
										<Autocomplete
											options={debitCreditOptions}
											getOptionLabel={(option) =>
												option.label
											}
											style={{ width: 242 }}
											size="small"
											renderInput={(params) => (
												<TextField
													variant="outlined"
													{...params}
													label="Select"
												/>
											)}
										/>
									)}
								</div>
							)
						})}
					</div>
				</>
			)}
			<div>
				<ReactTableV8
					tableColumns={
						partialStore.showHeader ? mappedColumns : Columns()
					}
					data={partialStore.viewUploadedData()}
					hasPagination={true}
					showPageSetting={false}
					height={contentHeight - 180}
					showHeader={partialStore.showHeader}
					menuId="DataSets"
				/>
			</div>
			<div className="FR JSB AC" style={{ marginTop: "1rem" }}>
				<div className="FR AC">
					<DLCheckbox
						eleClassName="include-zero-balance-checkbox"
						eleTestId="include-zero-balance-checkbox"
						eleName="includeZeroBalance"
						clickHandler={() => {}}
						isChecked={includeZeroBalance}
					/>
					<div>Include zero-balance accounts</div>
				</div>
				<div className="FR JSB AC" style={{ gap: 10 }}>
					<DLButton
						eleTestId="cancel-btn"
						clickHandler={handleCancel}
						eleClassName="cancel"
						color="default"
					>
						Cancel
					</DLButton>
					<DLButton
						eleTestId="import-btn"
						clickHandler={handleImport}
						eleClassName="import"
						color="primary"
					>
						Import
					</DLButton>
				</div>
			</div>
		</StyledAssignFields>
	)
})

const StyledAssignFields = styled.div`
	padding: 1rem;
	table > tbody > tr > td {
		padding: 8px;
	}
	table {
		border: 1px solid rgba(224, 224, 224, 1);
	}
	.column-assign-section {
		margin-bottom: 10px;
		margin-top: 10px;
	}
`

const GroupHeader = styled.div`
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    font-weigth: 600;
`

const GroupItems = styled.ul`
	padding: 0;
`

const Columns = () => {
	return [
		{
			header: "Column1",
			accessorKey: "column1",
		},
		{
			header: "Column2",
			accessorKey: "column2",
		},
		{
			header: "Column3",
			accessorKey: "column3",
		},
		{
			header: "Column4",
			accessorKey: "column4",
		},
		{
			header: "Column5",
			accessorKey: "column5",
		},
	]
}
