import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { DLCommentType } from "../../../common-models/enumerations/common-enums"
import { DLButton, DLIconButton } from "../../basic-elements"
import { DLTooltip } from "@datalobby/components"
import { mdiComment, mdiCommentOutline, mdiTrashCan } from "@mdi/js"
import Icon from "@mdi/react"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import {
	SimpleCommentProps,
	CommentStatus,
} from "../../../screens/project-side/communications/proj-comments/store/data-models/comments.data-model"
import { PermissionAsObjectProps } from "../../../common-models/permission"

export default observer(function CommentCardSimple({
	comment,
	handleDelete,
	handleOpenEdit,
	handleCloseComment,
	handleOpenReadonly,
	permission,
}: {
	comment: SimpleCommentProps
	handleDelete: (commentId: string) => void
	handleOpenEdit: (commentId: string) => void
	handleCloseComment: (commentId: string) => void
	handleOpenReadonly: (commentId: string) => void
	permission: PermissionAsObjectProps
}) {
	const projStore = useProjStore()
	const { id, type, assignees, contents, response, createdAt, createdBy } =
		comment

	const status = projStore.comments.getObjectInfo(id)?.status
	const handleClickDelete = () => {
		handleDelete(id)
	}

	const projRole = projStore.checkin.user.roleShortName
	const isNormalComment = type === DLCommentType.normal
	const isCompleted = status === CommentStatus.done
	const currentUserId = projStore.checkin.userId
	const currentUserName = projStore.projUsers.viewUserInfo(currentUserId).name

	// NOTE: MUST UPDATE TOGETHER WITH 'CommentsController > handleDeleteComment' or 'hasDeleteCommentAccess' in that file
	const hasDeleteCommentAccess = () => {
		if (isNormalComment) {
			// review comment
			if (createdBy === currentUserName) {
				return permission.deleteOwn
			} else {
				return permission.deleteOthers
			}
		} else {
			// QC comment
			if (projRole === "QC") {
				if (createdBy === currentUserName) {
					return permission.deleteOwn
				} else {
					return permission.deleteOthers
				}
			}
		}
	}

	// NOTE: Must update together: CommentsTable.tsx, CommentsCtxMenus > hasEditCommentAccess
	const handleOpenComment = () => {
		if (isCompleted) {
			handleOpenReadonly(id)
		} else {
			if (isNormalComment) {
				// review comment
				if (permission.update) {
					handleOpenEdit(id)
				} else {
					handleOpenReadonly(id)
				}
			} else {
				// QC comment
				if (projRole === "QC") {
					if (permission.update) {
						handleOpenEdit(id)
					} else {
						handleOpenReadonly(id)
					}
				} else {
					handleOpenReadonly(id)
				}
			}
		}
	}

	const cmtType = type === DLCommentType.qc ? "qc-comment" : "review-comment"
	const cmtStatus = isCompleted ? "completed" : "inprogress"

	return (
		<StyledCommentCardSimple>
			<div
				className={
					"comment-header FR JSB AC " + cmtType + " " + cmtStatus
					// type === DLCommentType.qc
					// 	? "comment-header FR JSB AC qc-comment"
					// 	: "comment-header FR JSB AC review-comment"
				}
			>
				<div className="FR AC">
					<Icon
						path={isCompleted ? mdiCommentOutline : mdiComment}
						size={0.7}
						style={{ marginRight: "0.4rem" }}
					/>
					{type === DLCommentType.qc && "QC Comment | "} Assignee:{" "}
					{assignees ? assignees : "No users assigned"}
				</div>

				<div className="FR AC">
					<DLButton
						eleTestId="close-comment"
						size="thin"
						clickHandler={() => handleCloseComment(id)}
						disabled={isCompleted}
					>
						{isCompleted ? "Closed" : "Mark Closed"}
					</DLButton>

					{/* {hasEditAccess() && (
						<DLIconButton
							eleTestId="edit-comment"
							size="small"
							clickHandler={() => handleOpenEdit(id)}
							disabled={commentStatus === "Completed"}
						>
							<Icon path={mdiPencil} size={0.6} />
						</DLIconButton>
					)} */}

					{hasDeleteCommentAccess() && (
						<DLIconButton
							eleTestId="delete-comment"
							size="small"
							clickHandler={handleClickDelete}
							tooltipText="Delete Comment"
						>
							<Icon path={mdiTrashCan} size={0.6} />
						</DLIconButton>
					)}
				</div>
			</div>
			<div className="comment-body" onClick={() => handleOpenComment()}>
				{contents}
			</div>

			{response && (
				<>
					<hr />
					<div
						className="comment-response"
						data-testid="comment-response"
					>
						Closing Response: {response}
					</div>
				</>
			)}
			<div className="comment-footer FR JSB">
				<DLTooltip title="Created at">
					<div data-testid="comment-createdAt">{createdAt}</div>
				</DLTooltip>
				<div data-testid="comment-createdBy">
					{/* {type === DLCommentType.qc ? "QC Comment" : "Comment"}{" "} */}
					by {createdBy}
				</div>
			</div>
		</StyledCommentCardSimple>
	)
})

const StyledCommentCardSimple = styled.div`
	transition: 0.4s;
	:hover {
		box-shadow: ${(props) => props.theme.shadowRegularLight};
		border: 1px solid rgba(0, 0, 0, 1);
	}
	padding: 1rem;
	border: 1px solid rgba(0, 0, 0, 0.5);
	background-color: white;
	border-radius: 0.5rem;
	margin-bottom: 0.4rem;
	.comment-header {
		/* font-weight: 500; */
		&.qc-comment {
			color: orange;
		}
		&.review-comment {
			/* color: blue; */
		}
		&.completed {
			/* color: black; */
			opacity: 0.5;
		}
	}
	.comment-body {
		padding: 1rem 0;
		cursor: pointer;
	}
	.comment-response {
		margin-bottom: 1rem;
	}
	.comment-footer {
		opacity: 0.5;
	}
`
