import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import CheckFinalReportDateMatch from "./CheckFinalReportDateMatch"

const CheckFinalReportDateMatchController = observer(() => {
	const orgStore = useOrgStore()
	const actionName = "getFinalReportDateMatchData"
	const menuId = DLOrgSubMenus.check_final_rep_date_match
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)
	const needRefresh = orgStore.checkFinalReportDateMatch.needRefresh

	useEffect(() => {
		menuAccess &&
			needRefresh &&
			orgStore.checkFinalReportDateMatch.getFinalReportDateMatchData()
	}, [menuAccess, needRefresh])

	return (
		<>
			{menuAccess ? (
				<CheckFinalReportDateMatch
					partialStore={orgStore.checkFinalReportDateMatch}
					actionStatus={orgStore.checkFinalReportDateMatch.getActionStatus(
						actionName
					)}
					permission={permission}
				/>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

export default CheckFinalReportDateMatchController
