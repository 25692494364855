import { types } from "mobx-state-tree"
// ViewModel, Modle, UiHelper
import CheckFinalReportDateMatchModel from "./check-final-report-date-match.model"
import CheckFinalReportDateMatchViewModel from "./check-final-report-date-match.view-model"
// common models
// apis
import * as api from "./check-final-report-date-match.apis"

export const CheckFinalReportDateMatchStore = ({
	apiRead,
}: api.CheckFinalReportDateMatchApiProps) =>
	types
		.compose(
			CheckFinalReportDateMatchModel({
				apiRead,
			}),
			CheckFinalReportDateMatchViewModel
		)
		.named("CheckFinalReportDateMatchStore")

export const initialStore = {
	// viewModel related parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
}

const CheckFinalReportDateMatchStoreInstance = CheckFinalReportDateMatchStore({
	apiRead: api.read,
}).create(initialStore)

export default CheckFinalReportDateMatchStoreInstance
