import { types } from "mobx-state-tree"

export const CheckFinalReportDateMatchModel = types.model({
	id: types.number,
	toBeCheckedErpReportDate: types.string,
	toBeCheckedAulFinalReportDate: types.string,
	erpClientMasterAliasId: types.string,
	erpClientMasterName: types.string,
	erpEngagementMasterAliasId: types.string,
	erpEngagementMasterName: types.string,
	erpEngagementYear: types.string,
	erpEngagementType: types.string,
	erpEngagementOwner: types.string,
	erpProjectAliasId: types.string,
	erpProjectName: types.string,
	erpProjectOwner: types.string,
	erpReportDate: types.string,
	erpReportReleaseDate: types.string,
	createdBy: types.string,
	rowIndex: types.number,
	aulSystemId: types.string,
	aulPeriod: types.string,
	aulGroupName: types.string,
	aulClientMasterName: types.string,
	aulIsLocked: types.string,
	aulProjectType: types.number,
	aulProjectTypeName: types.string,
	aulProjectName: types.string,
	aulProjectAliasId: types.string,
	aulVersion: types.string,
	aulEPUsers: types.string,
	aulQCUsers: types.string,
	aulFinalReportDate: types.string,
})
