import {
	zonedTimeToUtc,
	utcToZonedTime,
	toDate,
	format as format2,
} from "date-fns-tz"
import { formatISO, format } from "date-fns"
import { ConsoleLog } from "../../components/basic-elements"
import { DnTFormatProps } from "../../common-models/types/common-props"

export function convertDateAndTime() {}

export function convertDate(date: string, timeZone: string) {
	// NOTE: Temporary part to check timeZone issue
	// NOTE: Below process should be taken on the viewModel before calling API
	ConsoleLog(["selectedDate:", date, "/ timeZone:", timeZone])
	const newDate = new Date(date)
	const year = newDate.getFullYear()
	const month = newDate.getMonth()
	const day = newDate.getDate()

	ConsoleLog(["get numbers from the date:", year, month, day])

	let utcDate: any = ""
	let zonedDate: any = ""
	if (
		typeof year === "number" &&
		typeof month === "number" &&
		typeof day === "number"
		// NOTE: Do not use "year && month && day" for condition because 0 means false and January is 0 by getMonth()
	) {
		const isoFormatDate = formatISO(new Date(year, month, day), {
			representation: "date",
		})
		utcDate = zonedTimeToUtc(isoFormatDate, timeZone)
		zonedDate = utcToZonedTime(utcDate, timeZone)
		//
	}

	//
	return utcDate
}

export function addTzToDate(date: string | Date, timeZone: string) {
	const newDate = new Date(date)
	const year = newDate.getFullYear()
	const month = newDate.getMonth()
	const day = newDate.getDate()

	let dateWithTz: any = ""
	if (
		typeof year === "number" &&
		typeof month === "number" &&
		typeof day === "number"
		// NOTE: Do not use "year && month && day" for condition because 0 means false and January is 0 by getMonth()
	) {
		const isoFormatDate = formatISO(new Date(year, month, day), {
			representation: "date",
		})
		const newDate = isoFormatDate + " 00:00:00"
		const utcDate = zonedTimeToUtc(newDate, timeZone)
		const zonedDate = utcToZonedTime(utcDate, timeZone) // to get Date format data...

		dateWithTz = format2(zonedDate, "yyyy-MM-dd HH:mm:ssXXX", { timeZone })
	} else {
		ConsoleLog(
			`(addTzToDate) something wrong. year: ${year}| month: ${month}| day: ${day}`
		)
	}

	return dateWithTz
}

export function convertDateAsTzEOD(
	date: string | Date | number,
	timeZone: string
) {
	const newDate = new Date(date)
	const year = newDate.getFullYear()
	const month = newDate.getMonth()
	const day = newDate.getDate()

	ConsoleLog("convertDateAsTzEOD - TZ " + timeZone)

	let utcDate: any = ""
	if (
		typeof year === "number" &&
		typeof month === "number" &&
		typeof day === "number"
		// NOTE: Do not use "year && month && day" for condition because 0 means false and January is 0 by getMonth()
	) {
		const isoFormatDate = formatISO(new Date(year, month, day), {
			representation: "date",
		})
		const dateEnd = isoFormatDate + " 23:59:59" // NOTE: space is required. Do not remove the space

		utcDate = zonedTimeToUtc(dateEnd, timeZone)
	} else {
		ConsoleLog(
			`(convertDateAsTzEOD) something wrong. year: ${year}| month: ${month}| day: ${day}`
		)
	}

	//
	return utcDate
}

export function getFormattedEOD({
	date,
	timeZone,
	timeFormat,
	dateFormat,
	tzLabel,
}: {
	date: string | Date
	timeZone: string
	timeFormat?: string | null
	dateFormat?: string
	tzLabel?: string
}) {
	const newDate = new Date(date)
	const year = newDate.getFullYear()
	const month = newDate.getMonth()
	const day = newDate.getDate()

	let formattedDateWithTz: any = ""
	ConsoleLog("getFormattedEOD - TZ " + timeZone)
	if (
		typeof year === "number" &&
		typeof month === "number" &&
		typeof day === "number"
		// NOTE: Do not use "year && month && day" for condition because 0 means false and January is 0 by getMonth()
	) {
		const isoFormatDate = formatISO(new Date(year, month, day), {
			representation: "date",
		})
		const newDate = isoFormatDate + " 23:59:59"
		const utcDate = zonedTimeToUtc(newDate, timeZone)
		const zonedDate = utcToZonedTime(utcDate, timeZone) // to get Date format data...

		let defaultDateFormat = "yyyy-MM-dd"
		let defaultTimeFormat = "HH:mm:ss"

		if (dateFormat) {
			defaultDateFormat = dateFormat
		}
		if (timeFormat) {
			defaultTimeFormat = timeFormat
		}
		if (timeFormat === null) {
			defaultTimeFormat = ""
		}

		const formatShape = defaultDateFormat + " " + defaultTimeFormat

		const tzInfo = tzLabel ? tzLabel : timeZone

		formattedDateWithTz =
			format(zonedDate, formatShape) + " (" + tzInfo + ")"
	} else {
		ConsoleLog(
			`(addTzToDate) something wrong. year: ${year}| month: ${month}| day: ${day}`
		)
	}

	return formattedDateWithTz
}

// NOTE: The following is added by Bada on 2025-01-15.
// REASON: After selecting the date Expected Report Date & Final Report Dates, one day less is displayed in the UI when the getFormattedEOD method is used.
export function getFormattedEOD3({
	date,
	timeZone,
	timeFormat,
	dateFormat,
	tzLabel,
}: {
	date: string | Date
	timeZone: string
	timeFormat?: string | null
	dateFormat?: string
	tzLabel?: string
}) {
	const zonedDate = utcToZonedTime(date, timeZone) // Convert input date to time zone
	zonedDate.setHours(23, 59, 59, 999) // Explicitly set to end of day

	let defaultDateFormat = "yyyy-MM-dd"
	let defaultTimeFormat = "HH:mm:ss"

	if (dateFormat) {
		defaultDateFormat = dateFormat
	}
	if (timeFormat) {
		defaultTimeFormat = timeFormat
	}
	if (timeFormat === null) {
		defaultTimeFormat = ""
	}

	const formatShape = `${defaultDateFormat} ${defaultTimeFormat}`.trim()

	const tzInfo = tzLabel ? tzLabel : timeZone
	const formattedDateWithTz =
		format(zonedDate, formatShape) + (tzInfo ? ` (${tzInfo})` : "")

	return formattedDateWithTz
}

export function getFormattedEOD2({
	date,
	dntFormat,
}: {
	date: string | Date
	dntFormat: DnTFormatProps
}) {
	const { dateFormat, timeZone, timeFormat } = dntFormat

	const newDate = new Date(date)
	const year = newDate.getFullYear()
	const month = newDate.getMonth()
	const day = newDate.getDate()

	let formattedTzEOD: any = ""

	if (
		typeof year === "number" &&
		typeof month === "number" &&
		typeof day === "number"
		// NOTE: Do not use "year && month && day" for condition because 0 means false and January is 0 by getMonth()
	) {
		const isoFormatDate = formatISO(new Date(year, month, day), {
			representation: "date",
		})
		const newDate = isoFormatDate + " 23:59:59"
		const utcDate = zonedTimeToUtc(newDate, timeZone)
		const zonedDate = utcToZonedTime(utcDate, timeZone) // to get Date format data...

		let defaultDateFormat = "yyyy-MM-dd"
		let defaultTimeFormat = "HH:mm:ss"

		if (dateFormat) {
			defaultDateFormat = dateFormat
		}
		if (timeFormat) {
			defaultTimeFormat = timeFormat
		}
		if (timeFormat === null) {
			defaultTimeFormat = ""
		}

		const formatShape = defaultDateFormat + " " + defaultTimeFormat

		formattedTzEOD = format(zonedDate, formatShape)
	} else {
		ConsoleLog(
			`(formattedTzEOD) something wrong. year: ${year}| month: ${month}| day: ${day}`
		)
	}

	return formattedTzEOD
}

// NOTE: The following is added by Bada on 2025-01-15.
// REASON: After selecting the date Expected Report Date & Final Report Dates, one day less is displayed in the UI when the getFormattedEOD2 method is used.
export function getFormattedEOD4({
	date,
	dntFormat,
}: {
	date: string | Date
	dntFormat: DnTFormatProps
}) {
	const { dateFormat, timeZone, timeFormat } = dntFormat

	let defaultDateFormat = "yyyy-MM-dd"
	let defaultTimeFormat = "HH:mm:ss"

	if (dateFormat) {
		defaultDateFormat = dateFormat
	}
	if (timeFormat) {
		defaultTimeFormat = timeFormat
	}
	if (timeFormat === null) {
		defaultTimeFormat = ""
	}

	// Parse the input date and set the time explicitly to 23:59:59
	const zonedDate = utcToZonedTime(date, timeZone) // Convert input date to the given time zone
	zonedDate.setHours(23, 59, 59, 999) // Set time to end-of-day

	// Format the date in the specified format
	const formatShape = `${defaultDateFormat} ${defaultTimeFormat}`.trim()
	const formattedTzEOD = format(zonedDate, formatShape)

	return formattedTzEOD
}

export function formattedDateWithTz({
	date,
	timeZone,
	timeFormat,
	dateFormat,
	tzLabel,
}: {
	date: string | Date
	timeZone: string
	timeFormat?: string | null
	dateFormat?: string
	tzLabel?: string
}) {
	const zonedDate = utcToZonedTime(date, timeZone)

	let defaultDateFormat = "yyyy-MM-dd"
	let defaultTimeFormat = "HH:mm:ss"

	if (dateFormat) {
		defaultDateFormat = dateFormat
	}
	if (timeFormat) {
		defaultTimeFormat = timeFormat
	}
	if (timeFormat === null) {
		defaultTimeFormat = ""
	}

	const formatShape = defaultDateFormat + " " + defaultTimeFormat

	const tzInfo = tzLabel ? tzLabel : timeZone

	const formattedDate = format(zonedDate, formatShape) + " (" + tzInfo + ")"

	return formattedDate
}

export function formattedDateWithoutTzLabel({
	date,
	dntFormat,
}: {
	date: string | Date
	dntFormat: DnTFormatProps
}) {
	const { timeZone, timeFormat, dateFormat } = dntFormat
	const zonedDate = utcToZonedTime(date, timeZone)

	let defaultDateFormat = "yyyy-MM-dd"
	let defaultTimeFormat = "HH:mm:ss"

	if (dateFormat) {
		defaultDateFormat = dateFormat
	}
	if (timeFormat) {
		defaultTimeFormat = timeFormat
	}
	if (timeFormat === null) {
		defaultTimeFormat = ""
	}

	const formatShape = defaultDateFormat + " " + defaultTimeFormat
	const formattedDate = format(zonedDate, formatShape)

	return formattedDate
}

export function formattedDateWithTz2({
	date,
	timeZone,
	timeFormat,
	dateFormat,
}: {
	date: string | Date
	timeZone: string
	timeFormat?: string | null
	dateFormat?: string
}) {
	const zonedDate = utcToZonedTime(date, timeZone)

	let defaultDateFormat = "yyyy-MM-dd"
	let defaultTimeFormat = "HH:mm:ss"

	if (dateFormat) {
		defaultDateFormat = dateFormat
	}
	if (timeFormat) {
		defaultTimeFormat = timeFormat
	}
	if (timeFormat === null) {
		defaultTimeFormat = ""
	}

	const formattedDate = format(zonedDate, defaultDateFormat)
	const formattedTime = format(zonedDate, defaultTimeFormat)

	return {
		date: formattedDate,
		time: formattedTime,
	}
}

export function getTzDate({
	date,
	timeZone,
	dateFormat,
}: {
	date: string | Date
	timeZone: string
	dateFormat: string
}) {
	const zonedDate = utcToZonedTime(new Date(date), timeZone)
	const formattedDate = format(zonedDate, dateFormat)
	return formattedDate
}

export function getTzDate2({
	date,
	dntFormat,
}: {
	date: string | Date
	dntFormat: {
		timeZone: string
		dateFormat: string
		timeFormat: string
	}
}) {
	const { timeZone, dateFormat } = dntFormat
	const zonedDate = utcToZonedTime(new Date(date), timeZone)
	const formattedDate = format(zonedDate, dateFormat)

	return formattedDate
}

export function getTzDateAndTime({
	date,
	timeZone,
	dateFormat,
	timeFormat,
}: {
	date: string | Date
	timeZone: string
	dateFormat: string
	timeFormat: string
}) {
	const zonedDate = utcToZonedTime(new Date(date), timeZone)
	const formattedDate = format(zonedDate, dateFormat + " " + timeFormat)
	return formattedDate
}

export function getTzDateAndTime2({
	date,
	dntFormat,
}: {
	date: string | Date
	dntFormat: DnTFormatProps
}) {
	const { timeZone, dateFormat, timeFormat } = dntFormat
	const zonedDate = utcToZonedTime(new Date(date), timeZone)
	const formattedDate = format(zonedDate, dateFormat + " " + timeFormat)

	return formattedDate
}

export function getUtcDate(date: string, timeZone: string) {
	ConsoleLog("selected date:" + date)
	const newDate = new Date(date)
	const year = newDate.getFullYear()
	const month = newDate.getMonth()
	const day = newDate.getDate()

	ConsoleLog(["get numbers from the date:", year, month, day])
	ConsoleLog(["(timeZone)", timeZone])
	const isoFormatDate = formatISO(new Date(year, month, day), {
		representation: "date",
	})
	const utcDate = zonedTimeToUtc(isoFormatDate, timeZone)
	const zonedDate = utcToZonedTime(utcDate, timeZone)
	ConsoleLog("isoFormatDate ___" + isoFormatDate)
	ConsoleLog("utcDate >>> " + utcDate)
	ConsoleLog("zonedDate ___" + zonedDate)
	//
	return utcDate
}

export function addTzIfUnchanged(
	dateField: string,
	inputDate: string,
	tzUiValue: string
) {
	if (dateField === inputDate && inputDate !== "") {
		return addTzToDate(inputDate, tzUiValue)
	}
	return inputDate
}
