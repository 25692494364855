import { flow } from "mobx-state-tree"

const ReadForceArchivedPreConditionStatus = (
	self: any,
	forceArchivedPreConditionStatus: any
) => ({
	readForceArchivedPreConditionStatus: flow(function* (clientId) {
		const actionName = "readForceArchivedPreConditionStatus"
		try {
			const response = yield forceArchivedPreConditionStatus(clientId)

			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName, '__error', error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default ReadForceArchivedPreConditionStatus
