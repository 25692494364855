import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import sharedRegEx from "../../../../../library/sharedRegEx"
import useForm from "../../../../../library/use-form"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import {
	DLDialog,
	DLInputField,
	InputWithLabel,
} from "../../../../../components/basic-elements"
// import { NoSpecialChar2Msg } from "../../../basic-elements/system-msg/DLSystemMsg"
import { NoSpecialChar2Msg } from "../../../../../components/basic-elements/system-msg/DLSystemMsg"
import styled from "styled-components"

export default observer(function AddMapCategoryDialog({
	partialStore,
}: {
	partialStore: any
}) {
	const actionName = "addMapCategory"
	const inputsSchema = {
		name: { value: "", error: "", requestInput: false },
	}

	const validationSchema = {
		name: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialChar2,
				error: <NoSpecialChar2Msg />,
			},
			maxLength: 50,
		},
	}

	const open = partialStore.addMapCategoryDialogOpen

	const handleAdd = (name: string) => {}

	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsSchema,
		validationSchema,
		() => handleAdd(inputs.name.value),
		open
	)

	const actionStatus = partialStore.getActionStatus(actionName)
	let isDuplicated = false //partialStore.isDuplicatedFolderName(inputs.name.value)

	const isLoading = actionStatus === "LOADING"
	return (
		<DLDialog
			eleTestId={"add-map-category-dialog"}
			isOpen={open}
			setIsOpen={partialStore.setAddMapCategoryDialogOpen}
			handleAction={handleOnSubmit}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={"Add Map Category"}
			dialogContents={
				<FileFolderDialogForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					isDuplicated={isDuplicated}
				/>
			}
			cancelBtnText={"Cancel"}
			actionReady={isReady && !isDuplicated}
			actionBtn={"Add"}
			maxWidth="sm"
			fullWidth={true}
			showSpinner={isLoading}
			cannotUpdate={isLoading}
			useEnterKeyForSubmit
		/>
	)
})

const FileFolderDialogForm = observer(
	({
		inputs,
		handleOnChange,
		isDuplicated,
	}: {
		inputs: any
		handleOnChange: (props: any) => void
		isDuplicated: boolean
	}) => {
		return (
			<StyledFileFolderForm>
				<div className="input-section FR">
					<InputWithLabel required label={"Map Catergory Name"}>
						<DLInputField
							eleTestId="name-input"
							autoFocus
							eleName="name"
							eleValue={inputs.name.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={
								isDuplicated
									? "The same name already exist"
									: inputs.name.error
							}
							warningType="orange" // @Noah: this warning type also has impact on the testID. It seems not good. Have to update
							requestInput={inputs.name.requestInput}
							eleFullWidth
						/>
					</InputWithLabel>
				</div>
			</StyledFileFolderForm>
		)
	}
)

const StyledFileFolderForm = styled.div`
	.input-section {
		.label {
			min-width: 9rem;
			height: 4rem;
			/* height: include warning message */
		}
		.input-area {
			min-width: 20rem;
		}
	}
`
