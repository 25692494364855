import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
// separated actions
import GetEPMatchData from "./view-model-actions/get-ep-match-data"

import { initialStore } from "./check-ep-match.provider"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"
import { CheckEPMatchModel } from "./data-models/check-ep-match.data-model"

const CheckEPMatchViewModel = types
	.model({
		epMatchData: types.array(CheckEPMatchModel),
		selectedItem: 0,
		clickPoint: ClickPoint,

		// common parts
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setEPMatchDataList(clients: any) {
			self.epMatchData.length = 0
			self.epMatchData = clients
		},
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setSelectedItem(item: number) {
			self.selectedItem = item
		},
	}))
	// AGER (Add, Get, Edit, Remove)
	.actions(GetEPMatchData)
	//
	.views((self) => ({
		viewEPMatchData() {
			return self.epMatchData
		},
		viewSelectedItemInfo() {
			return self.epMatchData.find((i: any) => i.id === self.selectedItem)
		},
	}))
	// common actions
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default CheckEPMatchViewModel
