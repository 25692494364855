import React from "react"
import { DLContextMenu } from "../../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { useHistory } from "react-router-dom"

export default observer(function FSGroupAccountsCtxMenu({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	const projStore = useProjStore()
	const partialStore = projStore.assignFSAccounts

	const handleUnassign = () => {
		const item = partialStore.getSelectedFSGroupItemById(
			partialStore.selectedAccountId
		)
		if (item) {
			partialStore.removeItemFromFinancialGroup(item.id)
			partialStore.pushAccountOrChangeStatus(item, false)
		}
	}

	const menuOptions = () => [
		{
			label: "Unassign",
			value: "unassign",
			available: permission.update,
			clickEvent: handleUnassign,
		},
	]

	const handleClose = () => {
		partialStore.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}

	return (
		<DLContextMenu
			eleId="fs-group-account-ctx-menus"
			clickPoint={{
				mouseX: partialStore.clickPoint.mouseX,
				mouseY: partialStore.clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={menuOptions()}
			// hasDivider={[1]}
		/>
	)
})
