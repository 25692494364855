import React from "react"
import { Button, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import * as XLSX from "xlsx"
import AddNewDatasetDialog from "./AddNewDatasetDialog"

export default observer(function ImportFSDatasetPage({
	partialStore,
}: {
	partialStore: any
}) {
	function handleFile(e: any) {
		var file = e.target.files[0]
		var reader = new FileReader()

		reader.onload = function (e) {
			if (e.target) {
				if (
					e.target.result !== null &&
					typeof e.target.result !== "string"
				) {
					var data = new Uint8Array(e.target.result)
					var workbook = XLSX.read(data, { type: "array" })

					const jsonData = XLSX.utils.sheet_to_json(
						workbook.Sheets.Sheet1
					)
					console.log(jsonData, "jsonData")
					partialStore.setUploadData(jsonData)
					partialStore.setOpenAddNewDatasetModel(true)
				}
			} else {
				console.log("event target is null")
			}
		}
		reader.readAsArrayBuffer(file)
	}
	const handleFileUpload = (event: any) => {
		handleFile(event)
		const file = event.target.files[0]
		if (file) {
			partialStore.setFileName(file.name)
		}
	}

	const handleDrop = (e: any) => {
		e.preventDefault()
		handleFile(e)
		const file = e.dataTransfer.files[0]
		if (file) {
			partialStore.setFileName(file.name)
		}
		// partialStore.setOpenAddNewDatasetModel(true)
	}

	return (
		<StyledImportFSDatasetPage>
			<h3>Add Data</h3>
			<div>
				<InfoText>DATA FILE UPLOAD</InfoText>
				<UploadContainer
					onDragOver={(e) => e.preventDefault()}
					onDrop={handleDrop}
					onClick={() =>
						document.getElementById("fileInput")?.click()
					}
				>
					<FileInput
						type="file"
						id="fileInput"
						onChange={handleFileUpload}
						accept=".xlsx"
					/>
					{partialStore.fileName ? (
						<p>{partialStore.fileName}</p>
					) : (
						<>
							<p>Drag and drop here or click to browse</p>
						</>
					)}
				</UploadContainer>
			</div>

			<div style={{ marginTop: 10 }}>
				<InfoText>IMPORT FROM EXISTING ENGAGEMENT</InfoText>
				<div
					style={{
						display: "flex",
						gap: "10px",
						marginBottom: "20px",
					}}
				>
					<Button variant="outlined">
						Import from Working Papers
					</Button>
					<Button variant="outlined">
						Import from Caseware Cloud Engagement
					</Button>
				</div>
			</div>

			<div>
				<InfoText>IMPORT FROM EXISTING ENGAGEMENT</InfoText>
				<Autocomplete
					options={[]}
					getOptionLabel={(option: any) => option.label}
					renderInput={(params: any) => (
						<TextField
							{...params}
							variant="outlined"
							fullWidth
							placeholder="Search"
							InputLabelProps={{
								shrink: true,
							}}
						/>
					)}
					fullWidth
				/>
			</div>
			{partialStore.openAddNewDatasetModel && <AddNewDatasetDialog />}
		</StyledImportFSDatasetPage>
	)
})

const StyledImportFSDatasetPage = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`

const UploadContainer = styled.div`
	border: 2px dashed #ccc;
	border-radius: 8px;
	padding: 20px;
	text-align: center;
	cursor: pointer;
	transition: border-color 0.3s;

	&:hover {
		border-color: #007bff;
	}
`
const InfoText = styled.p`
	color: #666;
	font-size: 14px;
	margin-top: 10px;
`

const FileInput = styled.input`
	display: none;
`
