import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import { initialStore } from "./adjustments.provider"

import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"
import { AdjustmentsListModel } from "./data-models/adjustments.data-model"
import GetAdjustments from "./view-model-actions/get-adjustments"

const AdjustmentsViewModel = types
	.model({
		adjustmentsList: types.array(AdjustmentsListModel),
		selectedAdjustment: "",
		clickPoint: ClickPoint,
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar, // snackbar type 2
	})
	.actions((self) => ({
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setAdjustmentList(list: any) {
			self.adjustmentsList = list
		},
		setSelectedAdjustment(id: string) {
			self.selectedAdjustment = id
		},
	}))
	.actions(GetAdjustments)
	.views((self) => ({
		viewAdjustments() {
			return self.adjustmentsList
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default AdjustmentsViewModel
