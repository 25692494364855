import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import { initialStore } from "./import-fs-datasets.provider"
import {
	UploadDataModel,
	DatasetModel,
	ColumnMapping,
} from "./data-models/import-fs-datasets.data-model"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"

const ImportFSDatasetsViewModel = types
	.model({
		datasets: types.array(DatasetModel),
		mappedColumns: types.array(ColumnMapping),
		fileName: "",
		uploadData: types.array(UploadDataModel),
		openAddNewDatasetModel: false,
		clickPoint: ClickPoint,
		showHeader: false,
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar, // snackbar type 2
	})
	.actions((self) => ({
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setFileName(name: string) {
			self.fileName = name
		},
		setOpenAddNewDatasetModel(request?: boolean) {
			self.openAddNewDatasetModel =
				request ?? !self.openAddNewDatasetModel
		},
		setUploadData(data: any) {
			self.uploadData = data
		},
		pushToDataset(item: any) {
			self.datasets.push(item)
		},
		removeFromDataset(id: string) {
			const targetIndex = self.datasets.findIndex(
				(item) => item.id === id
			)
			self.datasets.splice(targetIndex, 1)
		},
		updateDatasetById(id: string) {
			const targetIndex = self.datasets.findIndex(
				(item) => item.id === id
			)
			self.datasets[targetIndex].status = "Final"
		},
		setMappedColumns(id: string, columns: any) {
			const itemIndex = self.mappedColumns.findIndex(
				(prevItem: any) => prevItem.id === id
			)
			if (itemIndex === -1) {
				self.mappedColumns.push({
					id,
					columns,
				})
			} else {
				self.mappedColumns[itemIndex] = {
					id,
					columns,
				}
			}
		},
		setShowHeader(show: boolean) {
			self.showHeader = show
		},
	}))
	.views((self) => ({
		viewUploadedData() {
			return self.uploadData
		},
		viewDatasets() {
			return self.datasets
		},
		viewInProgressDatasets() {
			return self.datasets.filter((i: any) => i.status === "InProgress")
		},
		viewTBDatasets() {
			return self.datasets.filter((i: any) => i.status === "Final")
		},
		viewMappedColumnsById(id: string) {
			return self.mappedColumns.find((i: any) => i.id === id)?.columns
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default ImportFSDatasetsViewModel
