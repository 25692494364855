export const dummyAdjustmentsData = [
	{
		id: "id1",
		number: "1",
		type: "Normal",
		period: "2024",
		description: "Payments to creditors",
		accounts: [
			{ account: "3.810.110 Trade Creditors", value: "5,000.00" },
			{ account: "1.100.100 Cash at bank", value: "(5,000.00)" },
		],
	},
	{
		id: "id2",
		number: "2",
		type: "Normal",
		period: "2024",
		description: "To cancel duplicate cheque",
		accounts: [
			{ account: "1.100.100 Cash at bank", value: "2,500.00" },
			{ account: "3.810.110 Trade Creditors", value: "(2,500.00)" },
		],
	},
	{
		id: "folderId5",
		number: "2",
		type: "Normal",
		period: "2023",
		description: "Receipt from sales",
		accounts: [
			{ account: "1.100.100 Cash at bank", value: "25,000.00" },
			{ account: "6.100.100 Sales", value: "(25,000.00)" },
		],
	},
]
