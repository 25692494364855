import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { PageContainer } from "../../../../components/app-frame-elements"
import { ActionStatus } from "@datalobby/types"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import styled from "styled-components"
import ImportFSDatasetPage from "./sub-components/ImportFSDatasetPage"
import DatasetsPage from "./sub-components/DatasetsPage"
import DatasetContextMenus from "./sub-components/DatasetContextMenus"

export default observer(function ImportFSDatasets({
	partialStore,
	actionStatus,
	permission,
}: {
	partialStore: any
	actionStatus: ActionStatus
	permission: PermissionAsObjectProps
}) {
	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			fixedHeight
			isLoading={actionStatus === ActionStatus.loading}
		>
			<StyledImportDatasets>
				{partialStore.viewDatasets().length > 0 ? (
					<DatasetsPage partialStore={partialStore} />
				) : (
					<ImportFSDatasetPage partialStore={partialStore} />
				)}

				{partialStore.clickPoint.mouseX && (
					<DatasetContextMenus permission={permission} />
				)}
			</StyledImportDatasets>
		</PageContainer>
	)
})

const StyledImportDatasets = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
`
