import { types } from "mobx-state-tree"
// model common action
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
// separated actions
import ReadEPMatchData from "./model-actions/read-ep-match-data"

import { CheckEPMatchApiProps } from "./check-ep-match.apis"
// WARNING: TODO: NOTE: Archive Policy List MVVM is not updated yet

const ERPDataModel = ({ apiRead }: CheckEPMatchApiProps) =>
	types
		.model({})
		// CRUD actions
		.actions((self, read = apiRead) => ReadEPMatchData(self, read))
		// common model actions
		.actions(CommonModelActions)

export default ERPDataModel
