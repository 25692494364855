import { observer } from "mobx-react-lite"
import React from "react"
import Icon from "@mdi/react"
import { mdiDownload, mdiEye } from "@mdi/js"
import styled from "styled-components"
import {
	DLButton,
	DLIconButton,
	DLSpinner,
} from "../../../../../components/basic-elements"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"

const FaPreProcessPDF = observer(
	({
		partialStore,
		selectedProj,
	}: {
		partialStore: any
		selectedProj: string
	}) => {
		// const orgStore = useOrgStore()
		const { isPreProcessed, hasForceArchivedZip } =
			partialStore.faPreConditionData
		const regenPdfRequestStatus = partialStore.getActionStatus(
			"generateForceArchivedPreProcessPdfs"
		)
		const preProcessStatusCheck = partialStore.getActionStatus(
			"getForceArchivedPreConditionStatus"
		)

		const previewPdf = (url: string) => {
			if (url.length > 0) {
				window.open(url)
			}
		}

		const downloadPdf = (url: string) => {
			if (url.length > 0) {
				const downloadWPFile = document.createElement("a")
				downloadWPFile.href = url

				downloadWPFile.setAttribute("download", "")
				document.body.appendChild(downloadWPFile)
				downloadWPFile.click()
				downloadWPFile.remove()
			}
		}

		const handleGenPreProcessPdfs = () => {
			const projId = selectedProj
			partialStore.generateForceArchivedPreProcessPdfs(projId)
		}

		const handleCancelPreProcessPdfs = () => {
			const projId = selectedProj
			if (hasForceArchivedZip) {
				partialStore.handleResponse({
					actionName: "cancelForceArchivedPreProcessPdfs",
					status: ActionStatus.success,
					code: 111,
					color: MessageColorType.red,
					customMessage:
						"Pre-process PDFs cannot be canceled because a force-archived zip exists.",
					open: true,
					autoHide: true,
				})
				return
			}
			partialStore.cancelForceArchivedPreProcessPdfs(projId)
		}

		const handleGenForceArchivedZip = () => {
			const projId = selectedProj
			partialStore.generateForceArchivedZip(projId)
		}

		console.log(preProcessStatusCheck, "preProcessStatusCheck")
		if (preProcessStatusCheck === ActionStatus.success) {
			return (
				<StyledFaPreProcessPDF
					className="file-details"
					data-testid="preprocess-info-container"
				>
					<h4 data-tetsid="area-title">PDF Files</h4>
					{partialStore.faPreConditionData.preProcessFiles.map(
						(file: any) => {
							return (
								<div
									className="FR AC JSB content-area"
									data-testid={`pdf-${file.fileName}`}
									key={file.fileName}
								>
									<div
										className="file-title"
										data-testid="fileName"
									>
										{file.fileName}
									</div>
									<div className="right-side FR AC">
										<div data-testid="file-status">
											{file.isPreProcessed ? (
												<div className="FR JSB file-status">
													<span
														style={{
															color: "blue",
														}}
													>
														Exist
													</span>
												</div>
											) : (
												<div className="FR JSB file-status">
													<span
														style={{ color: "red" }}
													>
														Not Exist
													</span>
												</div>
											)}
										</div>
										<div className="btn-area FR AC">
											<DLIconButton
												eleTestId="view-pdf"
												tooltipText="Preview PDF"
												clickHandler={() =>
													previewPdf(file.viewUrl)
												}
												disabled={!file.isPreProcessed}
												eleClassName="preview-pdf"
											>
												<Icon
													path={mdiEye}
													size={0.8}
												/>
											</DLIconButton>

											<DLIconButton
												eleTestId="download-pdf"
												tooltipText="Download PDF"
												clickHandler={() =>
													downloadPdf(
														file.downloadUrl
													)
												}
												disabled={!file.isPreProcessed}
												eleClassName="download-pdf"
											>
												<Icon
													path={mdiDownload}
													size={0.8}
												/>
											</DLIconButton>
										</div>
									</div>
								</div>
							)
						}
					)}

					<div className="pdf-zip-btn-container">
						{!isPreProcessed &&
							regenPdfRequestStatus !== ActionStatus.loading &&
							regenPdfRequestStatus !== ActionStatus.success && (
								<DLButton
									eleTestId="generate-force-archived-preprocess-pdf-btn"
									clickHandler={() =>
										handleGenPreProcessPdfs()
									}
									align="left"
									color="primary"
								>
									Generate Pre-process PDFs
								</DLButton>
							)}

						{isPreProcessed &&
							!hasForceArchivedZip &&
							regenPdfRequestStatus !== ActionStatus.loading &&
							regenPdfRequestStatus !== ActionStatus.success && (
								<DLButton
									eleTestId="cancel-force-archived-preprocess-pdf-btn"
									clickHandler={() =>
										handleCancelPreProcessPdfs()
									}
									align="left"
									color="primary"
								>
									Cancel Pre-process PDFs
								</DLButton>
							)}

						{isPreProcessed &&
							!hasForceArchivedZip &&
							regenPdfRequestStatus !== ActionStatus.loading &&
							regenPdfRequestStatus !== ActionStatus.success && (
								<DLButton
									eleTestId="generate-force-archived-zip"
									clickHandler={() =>
										handleGenForceArchivedZip()
									}
									align="right"
									color="primary"
								>
									Generate Force-Archived Zip
								</DLButton>
							)}
					</div>
				</StyledFaPreProcessPDF>
			)
		} else {
			return <DLSpinner />
		}
	}
)

export default FaPreProcessPDF

const StyledFaPreProcessPDF = styled.div`
	h4 {
		margin-bottom: 1rem;
	}
	.content-area {
		height: 1rem;
		margin-bottom: 0.5rem;
		overflow: hidden;
		.file-title {
			min-width: 19rem;
		}
		.right-side {
			.btn-area {
				width: 4rem;
				margin-left: 1.6rem;
			}
		}
	}
	.pdf-zip-btn-container {
		margin-top: 2rem;
		display: flex;
		justify-content: space-between;
	}
`
