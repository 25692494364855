import React from "react"
import Icon from "@mdi/react"
import {
	mdiCalendarClock,
	mdiShieldLock,
	mdiSettings,
	mdiClipboardAccount,
	mdiBullhorn,
	mdiViewDashboard,
	mdiFolderSearch,
	mdiFileTree,
	mdiBookOutline,
	mdiHelpRhombusOutline,
	mdiTrashCanOutline,
	mdiSitemap,
	mdiMagnify,
	mdiContentPaste,
	mdiClock,
} from "@mdi/js"
import { DLOrgSubMenus, DLOrgMenuGroups } from "./org-menus-enum"

// TODO: Sub menus have not icon yet (Current icons are dummy icon)
/**
 * 1. My page
 * --- Recent projects
 * --- Assigned projects
 * --- My informations
 * --- My history
 * --- My library
 */
const orgMenuIconList = [
	{
		menuId: DLOrgMenuGroups.my_page,
		icon: <Icon path={mdiClipboardAccount} size={1} />,
	},
	{
		menuId: DLOrgSubMenus.recent,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.assigned,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.my_info,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.my_history,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.my_lib,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	/*
	 *	X. Assigned Projects
	 */
	{
		menuId: DLOrgMenuGroups.assigned_projects,
		icon: <Icon path={mdiFolderSearch} size={1} />,
	},
	{
		menuId: DLOrgSubMenus.recent,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.assigned,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.my_info,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.my_history,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.my_lib,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.remind_archiving,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.remind_report,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	/**
	 * 2. Notifications
	 * --- Org Notifications
	 * --- System Notifications
	 */
	{
		menuId: DLOrgMenuGroups.noti,
		icon: <Icon path={mdiBullhorn} size={1} />,
	},
	{
		menuId: DLOrgSubMenus.org_noti,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	// {
	// 	menuId: DLOrgSubMenus.sys_noti,
	// 	icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	// },
	/**
	 * 3. Home Dashboard
	 * --- Home Dashboard
	 * --- Analysis
	 */
	{
		menuId: DLOrgMenuGroups.dashboard,
		icon: <Icon path={mdiViewDashboard} size={1} />,
	},
	{
		menuId: DLOrgSubMenus.dash_chart,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.dash_graph,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.dash_analysis,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	/**
	 * 4. Schedule and Budgets
	 * --- Schedule
	 * --- Budget
	 */
	{
		menuId: DLOrgMenuGroups.resource_mgmt,
		icon: <Icon path={mdiCalendarClock} size={1} />,
	},
	{
		menuId: DLOrgSubMenus.schedule,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.budget,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	/**
	 * 5. Archive Management
	 * --- Archived Projects Managmeent
	 * --- Unarchived Projects Management
	 */
	{
		menuId: DLOrgMenuGroups.arch_mgmt,
		icon: <Icon path={mdiShieldLock} size={1} />,
	},
	{
		menuId: DLOrgSubMenus.archived_mgmt,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.unarchived_mgmt,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	/**
	 * 6. Groups
	 * --- Groups
	 */
	{
		menuId: DLOrgMenuGroups.groups,
		icon: <Icon path={mdiFileTree} size={1} />,
	},
	{
		menuId: DLOrgSubMenus.groups,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	/**
	 * 7. Reports Management
	 * --- Reports management
	 */
	{
		menuId: DLOrgMenuGroups.reports_mgmt,
		icon: <Icon path={mdiContentPaste} size={1} />,
	},
	{
		menuId: DLOrgSubMenus.online_projects,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.checklists,
		icon: <Icon path={mdiContentPaste} size={0.8} />,
	},
	/**
	 * 7. Hard Copy Management
	 * --- Hard Copy List
	 * --- My Rental List
	 * --- Dashboard
	 * --- Management
	 */
	{
		menuId: DLOrgMenuGroups.hard_copy_management,
		icon: <Icon path={mdiContentPaste} size={1} />,
	},
	{
		menuId: DLOrgSubMenus.hard_copy,
		icon: <Icon path={mdiContentPaste} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.my_rental_list,
		icon: <Icon path={mdiContentPaste} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.hard_copy_dashboard,
		icon: <Icon path={mdiContentPaste} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.hard_copy_config,
		icon: <Icon path={mdiContentPaste} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.hard_copy_history,
		icon: <Icon path={mdiContentPaste} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.hard_copy_trash,
		icon: <Icon path={mdiContentPaste} size={0.8} />,
	},
	/**
	 * X1. Clients
	 * --- Clients
	 */
	{
		menuId: DLOrgMenuGroups.clients,
		icon: <Icon path={mdiHelpRhombusOutline} size={1} />,
	},
	{
		menuId: DLOrgSubMenus.clients,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	/**
	 * X2. Engagements
	 * --- Engagements
	 */
	{
		menuId: DLOrgMenuGroups.engagements,
		icon: <Icon path={mdiHelpRhombusOutline} size={1} />,
	},
	{
		menuId: DLOrgSubMenus.engagements,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	/**
	 * 7. Projects
	 * --- Normal
	 * --- Replica
	 * --- Archived
	 * --- Unarchived
	 */
	{
		menuId: DLOrgMenuGroups.projects,
		icon: <Icon path={mdiFolderSearch} size={1} />,
	},
	{
		menuId: DLOrgSubMenus.normal_projs,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.replicas,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.archived,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.unarchived,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	/**
	 * 8. Library
	 * --- Project Templates
	 * --- File Library
	 */
	{
		menuId: DLOrgMenuGroups.lib,
		icon: <Icon path={mdiBookOutline} size={1} />,
	},
	{
		menuId: DLOrgSubMenus.proj_templates,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.file_lib,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	/**
	 * 9. Trash
	 */
	{
		menuId: DLOrgMenuGroups.trash,
		icon: <Icon path={mdiTrashCanOutline} size={1} />,
	},
	{
		menuId: DLOrgSubMenus.file_trash,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	/**
	 * 10. Setup
	 * --- Setup Users
	 * --- Setup Groups
	 * --- Setup Clients
	 * --- Setup Engagement Types
	 * --- Setup Archive Policies
	 * --- Setup Role Setup
	 * --- Setup System Settings
	 * --- Setup Billing
	 */
	{
		menuId: DLOrgMenuGroups.setup,
		icon: <Icon path={mdiSettings} size={1} />,
	},
	{
		menuId: DLOrgSubMenus.setup_users,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.setup_groups,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.setup_clients,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.setup_eng_types,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.setup_arch_policy,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.setup_roles,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.setup_sys,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.setup_billing,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	/**
	 * X. Normal Setup
	 * --- Setup Users
	 * --- Setup Groups
	 * --- Setup Clients
	 */
	{
		menuId: DLOrgMenuGroups.org_normal_setup,
		icon: <Icon path={mdiSettings} size={1} />,
	},
	{
		menuId: DLOrgSubMenus.readonly_users,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.readonly_clients,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.readonly_groups,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	/**
	 * 11. Org Management
	 */
	{
		menuId: DLOrgMenuGroups.org_mgmt,
		icon: <Icon path={mdiSitemap} size={1} />,
	},
	{
		menuId: DLOrgSubMenus.org_mgmt,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	/**
	 * 12. Org Search
	 */
	{
		menuId: DLOrgMenuGroups.search,
		icon: <Icon path={mdiMagnify} size={1} />,
	},
	{
		menuId: DLOrgSubMenus.search,
		icon: <Icon path={mdiMagnify} size={0.8} />,
	},
	/**
	 * 13. Admin Remind
	 */
	{
		menuId: DLOrgMenuGroups.admin_remind,
		icon: <Icon path={mdiFolderSearch} size={1} />,
	},
	{
		menuId: DLOrgSubMenus.admin_remind_report,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLOrgSubMenus.admin_remind_archiving,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	//
	{
		menuId: DLOrgMenuGroups.archiving_reminder,
		icon: <Icon path={mdiClock} size={1} />,
	},
	//
	{
		menuId: DLOrgMenuGroups.erp_data_sync,
		icon: <Icon path={mdiFolderSearch} size={1} />,
	},
	//
	{
		menuId: DLOrgMenuGroups.user_my_summary,
		icon: <Icon path={mdiFolderSearch} size={1} />,
	},
	{
		menuId: DLOrgMenuGroups.admin_my_summary,
		icon: <Icon path={mdiFolderSearch} size={1} />,
	},
]

export default orgMenuIconList
