import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
	idToString,
} from "../../../../../library/converters/id-converter"
import { ProjInfoUpdateInputProps } from "../data-models/proj-info-update-input-props"

const EditProjInfo = (self: any) => ({
	editProjInfo(
		projInfo: ProjInfoUpdateInputProps,
		postSuccess?: (res: any) => void
	) {
		// TODO: any is temporary setting. Must update
		// 0.
		const actionName = "editProjInfo"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		const payload = {
			Address: projInfo.clientAddress,
			ArchiveDatePolicyId: idToNumber(
				projInfo.archPolicyPeriodId,
				IdType.archPolicyPeriod
			),
			ClientId: idToNumber(self.projInfo.projectId, IdType.project),
			ClientMasterID: idToNumber(projInfo.clientId, IdType.client),
			ClientShortName: projInfo.projAliasId, // NOTE: This is project alias ID
			EngagementID: idToNumber(projInfo.engTypeId, IdType.engType),
			ExpectedArchiveDeadLineDate: projInfo.expectedArchDeadlineDate,
			Fee: projInfo.fee || "",
			FiscalYearEnd: projInfo.periodEndDate,
			YearValue: projInfo.financialYear,
			LinkName: projInfo.projTitle,
			RiskAssessmentId: idToNumber(projInfo.raId, IdType.ra),
			// UserID: 16,
			archivedDeadlineDate: projInfo.finalArchDeadlineDate,
			expectedIssuanceDate: projInfo.expectedReportDate,
			finalIssuanceDate: projInfo.finalReportDate,
			periodID: idToNumber(projInfo.periodId, IdType.period),
			restoreRationale: false, // TBD
		}
		self.updateProjInfo(payload)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					//const projInfo = response.data
					// ----- default
					// Incorrect data is coming
					// const reOrganizedProjInfo = {
					// 	groupName: projInfo.GroupName,
					// 	groupId: idToString(projInfo.GroupID, IdType.group),
					// 	//
					// 	engPartner: projInfo.EngagementUser,
					// 	//
					// 	clientId: idToString(
					// 		projInfo.ClientMasterID,
					// 		IdType.client
					// 	),
					// 	clientName: projInfo.ClientName,
					// 	clientAliasId: projInfo.clientShortId,

					// 	clientAddress: projInfo.Address,
					// 	//
					// 	projectId: idToString(
					// 		projInfo.ClientId,
					// 		IdType.project
					// 	),
					// 	projTitle: projInfo.LinkName,
					// 	projAliasId: projInfo.ClientShortName,
					// 	projectType: projInfo.ProjectType,
					// 	isLocked: projInfo.IsLocked,
					// 	//
					// 	engTypeName: projInfo.EngagementType,
					// 	engTypeId: idToString(
					// 		projInfo.EngagementID,
					// 		IdType.engType
					// 	),
					// 	//
					// 	raName: projInfo.RiskAssessmentName,
					// 	raId: idToString(projInfo.RiskAssessmentId, IdType.ra),
					// 	//
					// 	createdBy: projInfo.CreatedUser,
					// 	//
					// 	periodName: projInfo.periodName,
					// 	periodId: idToString(projInfo.periodID, IdType.period),
					// 	//
					// 	financialYear: projInfo.FiscalYear,
					// 	periodEnd: projInfo.FiscalYearEnd,
					// 	//
					// 	fee:
					// 		projInfo.Fee === undefined || projInfo.Fee === null
					// 			? 0
					// 			: projInfo.Fee,
					// 	archPolicyPeriodName: projInfo.ArchiveDatePolicy,
					// 	archPolicyPeriodId: idToString(
					// 		projInfo.ArchiveDatePolicyId,
					// 		IdType.archPolicyPeriod
					// 	),
					// 	//
					// 	expectedReportDate:
					// 		projInfo.expectedDate === undefined ||
					// 		projInfo.expectedDate === null
					// 			? ""
					// 			: projInfo.expectedDate,

					// 	expectedArchDeadlineDate:
					// 		projInfo.ExpectedArchiveDeadLineDate ===
					// 			undefined ||
					// 		projInfo.ExpectedArchiveDeadLineDate === null
					// 			? ""
					// 			: projInfo.ExpectedArchiveDeadLineDate,

					// 	finalReportDate:
					// 		projInfo.finalDate === undefined ||
					// 		projInfo.finalDate === null
					// 			? ""
					// 			: projInfo.finalDate,
					// 	finalArchDeadlineDate:
					// 		projInfo.archivedDeadlineDate === undefined ||
					// 		projInfo.archivedDeadlineDate === null
					// 			? ""
					// 			: projInfo.archivedDeadlineDate,
					// }
					// ConsoleLog(reOrganizedProjInfo)
					// self.setProjInfo(reOrganizedProjInfo)
					self.getProjInfo(self.projInfo.projectId, () => {})
					postSuccess &&
						postSuccess(
							reOrganizeProjInfoForProjInfoUpdate(response.data)
						)
					self.setUpdateDialogOpenStatus(false)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to" + actionName,
						open: true,
						// autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message:
							response.data.Message ||
							response.data.message ||
							`(${actionName}) Something's wrong...`,
						// customMessage:
						// 	"Something Wrong... (UPDATE IS REQUIRED)",
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default EditProjInfo

const reOrganizeProjInfoForProjInfoUpdate = (projInfo: any) => {
	return {
		clientId: idToString(projInfo.ClientMasterID, IdType.client),
		clientName: projInfo.ClientName,
		clientAliasId: projInfo.clientShortId,
		//
		id: idToString(projInfo.ClientId, IdType.project),
		title: projInfo.LinkName,
		aliasId: projInfo.ClientShortName,
		//
		engTypeName: projInfo.EngagementType,
		engTypeId: idToString(projInfo.EngagementID, IdType.engType),
		//
		raName:
			projInfo.RiskAssessmentName === null
				? ""
				: projInfo.RiskAssessmentName,
		raId:
			projInfo.RiskAssessmentId === 0 ||
			projInfo.RiskAssessmentId === null
				? ""
				: idToString(projInfo.RiskAssessmentId, IdType.ra),
		//
		periodName: projInfo.periodName,
		periodId: idToString(projInfo.periodID, IdType.period),
		//
		periodEndDate: projInfo.FiscalYearEnd,
		//
		archPolicyPeriodName: projInfo.ArchiveDatePolicy,
		archPolicyPeriodId: idToString(
			projInfo.ArchiveDatePolicyId,
			IdType.archPolicyPeriod
		),
		//
		expectedReportDate:
			projInfo.expectedDate === undefined ||
			projInfo.expectedDate === null
				? ""
				: projInfo.expectedDate,

		expectedArchiveDate:
			projInfo.ExpectedArchiveDeadLineDate === undefined ||
			projInfo.ExpectedArchiveDeadLineDate === null
				? ""
				: projInfo.ExpectedArchiveDeadLineDate,

		finalReportDate:
			projInfo.finalDate === undefined || projInfo.finalDate === null
				? ""
				: projInfo.finalDate,
		finalArchiveDeadlineDate:
			projInfo.archivedDeadlineDate === undefined ||
			projInfo.archivedDeadlineDate === null
				? ""
				: projInfo.archivedDeadlineDate,
	}
}
