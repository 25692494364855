import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"
import styled from "styled-components"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"
import {
	getPageSettings,
	savePageSetting,
} from "../../../../library/get-page-settings"
import { DLComboBox } from "../../../../components/basic-elements"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import UserInfoHeaderCard from "../utils/UserInfoHeaderCard"
import MySummaryTable from "../utils/MySummaryTable"

export default observer(function AdminMySummaryController() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.remind_archiving
	const projStatus = ProjectStatus.normal // What will be the proj status
	const actionName = "getRemindProjectList_" + projStatus
	//
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)
	const i18nSheet = OrgI18n.projects
	const needRefresh = orgStore.projects.needRefreshForArchivingReminder
	const dntFormat = store.global.getDntFormat
	// const loggedInUser = orgStore.checkin.orgInfo

	const userList = orgStore.setupUsers.adminMySummaryOptions
	const [user, setUser] = useState({
		name: "",
		email: "",
		aliasId: "",
		id: "",
	})

	const loadingStatus = orgStore.projects.getActionStatus(actionName)
	const i18n = store.i18n.combineI18n(i18nSheet)
	const clickPointExist = orgStore.projects.clickPoint.mouseX !== null
	const clickPoint = {
		mouseX: orgStore.projects.clickPoint.mouseX,
		mouseY: orgStore.projects.clickPoint.mouseY,
	}

	const projId: string | null = orgStore.projects.selectedProj
	const projNumId: number | null = projId
		? (idToNumber(projId, IdType.project) as number)
		: null

	const menuOptions: any = [
		{
			label: "Open",
			value: "open",
			isLink: true,
			linkTo: projNumId ? `/project/checkin/${projNumId}` : "#",
		},
		{
			label: "Project Information",
			value: "ctx-proj_info",
			available: permission.read,
			clickEvent: () =>
				orgStore.projects.setProjectDetailsDialogOpen(true),
		},
	]

	const handleComboBoxInput = (value: any) => {
		setUser(value)
	}

	useEffect(() => {
		if (menuAccess) {
			orgStore.setupUsers.getUserList()
		}
	}, [])

	useEffect(() => {
		if (!menuAccess || !needRefresh || !user?.id) return

		const pageSetting = getPageSettings(menuId)
		if (pageSetting?.columnFilters) {
			const value = pageSetting?.columnFilters.filter(
				(item: any) => item.id !== "financialYear"
			)
			savePageSetting("columnFilters", value, `pageSetting_${menuId}`)
		}
		orgStore.projects.getRemindProjectList({
			projStatus,
			year: "All Years", //"2023",
			PTMAssignedOnly: true,
			menuId,
			dntFormat,
		})
	}, [menuAccess, needRefresh, user?.id])

	return (
		<>
			{menuAccess ? (
				<>
					<div
						className="input-container"
						style={{ width: "40%", padding: "1rem 0 0 1rem" }}
					>
						<DLComboBox
							eleTestId="user-select"
							eleName="userName"
							placeholder="Select User"
							onChange={(value: any) =>
								handleComboBoxInput(value)
							}
							getOptionLabel={(option: any) =>
								option.name
									? `[${option.name}] ${option.email}`
									: ""
							}
							options={userList}
							eleValue={user}
						/>
					</div>

					<StyledAdminMySummaryProjects>
						<UserInfoHeaderCard user={user} />
					</StyledAdminMySummaryProjects>

					<MySummaryTable
						orgStore={orgStore}
						i18n={i18n}
						menuId={menuId}
						permission={permission}
						clickPointExist={clickPointExist}
						clickPoint={clickPoint}
						menuOptions={menuOptions}
						loadingStatus={loadingStatus}
					/>
				</>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

const StyledAdminMySummaryProjects = styled.div`
	padding: 0 1rem;
`
