import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
// separated actions
import GetFinalReportDateMatchData from "./view-model-actions/get-final-report-date-match-data"

import { initialStore } from "./check-final-report-date-match.provider"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"
import { CheckFinalReportDateMatchModel } from "./data-models/check-final-report-date-match.data-model"

const CheckFinalReportDateMatchViewModel = types
	.model({
		finalReportDateMatchData: types.array(CheckFinalReportDateMatchModel),
		selectedItem: 0,
		clickPoint: ClickPoint,

		// common parts
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setFinalReportMatchData(clients: any) {
			self.finalReportDateMatchData.length = 0
			self.finalReportDateMatchData = clients
		},
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setSelectedItem(item: number) {
			self.selectedItem = item
		},
	}))
	// AGER (Add, Get, Edit, Remove)
	.actions(GetFinalReportDateMatchData)
	//
	.views((self) => ({
		viewFinalReportDateMatchData() {
			return self.finalReportDateMatchData
		},
		viewSelectedItemInfo() {
			return self.finalReportDateMatchData.find(
				(i: any) => i.id === self.selectedItem
			)
		},
	}))
	// common actions
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default CheckFinalReportDateMatchViewModel
