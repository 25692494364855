import React, { useEffect } from "react"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { observer } from "mobx-react-lite"
import AssignFSAccounts from "./AssignFSAccounts"
import { ActionStatus } from "@datalobby/types"

export default observer(function AssignFSAccountsController() {
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.assign_fs_accounts
	const isLocked = projStore.projInfo.projInfo.isLocked
	const isArchived = projStore.projInfo.projInfo.isArchived
	const menuAccess = projStore.checkin.checkAccess(menuId)
	const permission = projStore.checkin.getActionsAsObject(
		menuId,
		isLocked,
		isArchived
	)

	useEffect(() => {
		projStore.assignFSAccounts.getFinancialGroups()
		projStore.assignFSAccounts.getFSAccounts()
	}, [])

	return (
		<>
			{menuAccess ? (
				<AssignFSAccounts
					partialStore={projStore.assignFSAccounts}
					permission={permission}
					actionStatus={projStore.assignFSAccounts.getActionStatus(
						"getFinancialGroups"
					)}
				/>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
