import { types } from "mobx-state-tree"

export const PreProcessFiles = types.model({
	fileName: types.string, // [Name] ex:) "Project users information pdf"
	isPreProcessed: types.boolean, // [Status] ex:) true
	downloadUrl: types.string, // [DownloadUrl] ex:) ""
	viewUrl: types.string, // [ViewUrl] ex:) ""
})

export const PreConditionData = types.model({
	projectSize: types.union(types.string, types.null), // [ProjectSize] ex:) "9.27MB"
	isPreProcessed: types.boolean, // [StartPreprocessStatus] ex:) true
	preProcessFiles: types.array(PreProcessFiles), // [archivePreprocessStatus]
})

export const ForceArchivedPreConditionData = types.model({
	projectSize: types.union(types.string, types.null), // [ProjectSize] ex:) "9.27MB"
	isPreProcessed: types.boolean, // [HasForceArchivedPreprocessPdf] ex:) true
	hasForceArchivedZip: types.boolean, // [HasForceArchivedZip] ex:) true
	forceArchivedZipUrl: types.string, // [ForceArchivedZipUrl] ex:) ""
	preProcessFiles: types.array(PreProcessFiles), // [archivePreprocessStatus]
})

export const DefaultPreConditionData = {
	projectSize: "",
	isPreProcessed: false,
	preProcessFiles: [
		{
			fileName: "",
			isPreProcessed: false,
			downloadUrl: "",
			viewUrl: "",
		},
	],
}

export const DefaultFaPreConditionData = {
	projectSize: "",
	isPreProcessed: false,
	hasForceArchivedZip: false,
	forceArchivedZipUrl: "",
	preProcessFiles: [
		{
			fileName: "",
			isPreProcessed: false,
			downloadUrl: "",
			viewUrl: "",
		},
	],
}

export const ArchiveDetails = types.model({
	epName: types.string,
	archivedDate: types.string,
	archivedBy: types.string,
})
