import React, { useEffect, useState, useCallback } from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLSingleSelect,
	DLButton,
	DLRadioGroup,
	DLDatepicker,
	DLComboBox,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import Grid from "@material-ui/core/Grid"
import AccessMgmtDialogTableColumns from "./AccessMgmtDialogTableColumns"
import { ArchivedProjectIcon } from "../../../../../components/basic-elements/icons/common-project-icons"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import {
	addTzToDate,
	convertDateAsTzEOD,
	formattedDateWithTz,
	getUtcDate,
} from "../../../../../library/converters/date-utc-converter"
import { createFilterOptions } from "@material-ui/lab/Autocomplete"
import parse from "autosuggest-highlight/parse"
import match from "autosuggest-highlight/match"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import styled from "styled-components"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"

export default observer(function AccessMgmtDialogForm({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const partialStore = orgStore.archiveMgmt
	const actionStatus = partialStore.getActionStatus
	//
	const initialInputs = {
		type: "internal",
		user: "",
		expPermission: "false",
		expiredDate: "",
	}
	const [inputs, setInputs] = useState<any>(initialInputs)
	const [assignedUsers, setAssignedUsers] = useState(
		partialStore.viewReadOnlyUserList()
	)

	useEffect(() => {
		partialStore.getFilteredUsers(inputs.type)
	}, [inputs.type])

	useEffect(() => {
		setAssignedUsers(partialStore.viewReadOnlyUserList())
	}, [
		actionStatus("unAssignReadOnlyUser") === "SUCCESS",
		actionStatus("updateReadOnlyUser") === "SUCCESS",
	])

	const handleOnChange = useCallback((event: any) => {
		let name = event.target.name
		let value = event.target.value
		setInputs((inputs: any) => ({
			...inputs,
			[name]: value,
		}))
	}, [])

	const dnt = store.global.getDntFormat
	const { dateFormat, timeZone, timeFormat } = dnt

	const handleDateInput = useCallback((date: any) => {
		const endOfTheDate = convertDateAsTzEOD(date, timeZone)
		ConsoleLog("endOfTheDate " + endOfTheDate)
		inputs.expiredDate = endOfTheDate
		setInputs((inputs: any) => ({
			...inputs,
			expiredDate: endOfTheDate,
		}))
	}, [])

	const handleUserChange = useCallback((value: any) => {
		setInputs((inputs: any) => ({
			...inputs,
			user: value ? value.userId : "",
		}))
	}, [])

	const projectInfo = partialStore.getClientInfoById(
		partialStore.selectedProj,
		dateFormat,
		timeZone,
		timeFormat
	)

	const handleAssign = () => {
		const payload = {
			...inputs,
			expiredDate: addTzToDate(inputs.expiredDate, timeZone),
		}
		partialStore.assignReadonlyUser(payload, partialStore.selectedProj)
	}

	const unassignUser = (id: string) => {
		partialStore.unAssignReadOnlyUser(id, partialStore.selectedProj)
	}

	const updateUser = (row: any) => {
		// TODO: Need to make individual update
		let data: any[] = []
		const projectId = partialStore.selectedProj
		const clientId = idToNumber(projectId, IdType.project)
		const userId = idToNumber(row.original.id, IdType.user)
		data.push({
			clientId: clientId,
			Id: userId,
			IsExportPerm: row.original.isExportPerm,
			ExpiredDate: addTzToDate(row.original.expiredDate, timeZone),
		})
		partialStore.editReadOnlyUser(data, row.original.expiredDate)
	}

	return (
		<StyledAccessMgmtDialogForm>
			<div
				className="project-title FR AC"
				data-testid="access-mgmt-target-project"
			>
				<ArchivedProjectIcon />
				<p>
					<b>{i18n.twProjectName}</b>:{" "}
					<span data-testid="financial-year">
						{projectInfo?.financialYear}
					</span>{" "}
					<span data-testid="client-name">
						{projectInfo?.clientName}
					</span>
					:
					<span data-testid="project-title">
						{projectInfo?.title}
					</span>
					<span data-testid="project-aliasId">
						({projectInfo?.aliasId})
					</span>{" "}
					<span data-testid="version">
						(Archived v{projectInfo?.version})
					</span>
				</p>
			</div>
			<div className="user-assign-area" data-testid="assign-user-section">
				<p>
					<b>{i18n.tsAssignReadonlyUser}</b>
				</p>
				<hr />
				<StyledAddUserForm>
					<div className="assign-readonly-area FR JSB">
						<div className="assign-input-item select-user-type">
							<div className="input-label">{i18n.twType}</div>
							<DLSingleSelect
								placeholder="Select a user"
								eleValue={inputs.type}
								eleName="type"
								eleFullWidth
								eleOnChange={handleOnChange}
								optionList={[
									{ name: "Internal", value: "internal" },
									{ name: "External", value: "external" },
								]}
								eleTestId="user-type"
							/>
						</div>
						<div className="assign-input-item select-user">
							<div className="input-label">{i18n.twUser}</div>
							<DLComboBox
								eleName="user"
								eleTestId="select-user"
								options={partialStore.viewSearchAccessMgmtUserList()}
								renderOption={(
									option: any,
									{ inputValue }: { inputValue: any }
								) => (
									<GroupUserRow
										userInfo={option}
										inputValue={inputValue}
									/>
								)}
								filterOptions={createFilterOptions({
									// matchFrom: 'start',
									stringify: (option: any) =>
										option.name +
										option.userAliasId +
										option.email,
								})}
								eleOnChange={handleOnChange}
								onChange={handleUserChange}
								eleClassName="user-select-combo-box"
							/>
						</div>
						<div className="assign-input-item set-export-permission">
							<div className="input-label">
								{i18n.twExportPermission}
							</div>
							<DLRadioGroup
								itemDirection="row"
								groupName="expPermission"
								eleClassName="exp-permission-radio"
								selectedValue={inputs.expPermission}
								items={[
									{
										value: "true",
										label: "Yes",
									},
									{
										value: "false",
										label: "No",
									},
								]}
								eleOnChange={handleOnChange}
							/>
						</div>
						<div className="assign-input-item exp-date-select">
							<div className="input-label">
								{i18n.twExpiredDate}
							</div>
							<div className="datePicker">
								<DLDatepicker
									eleTestId="exp-date-field"
									eleName="expiredDate"
									// onChange={selectedDate}
									onChange={(date: any) =>
										handleDateInput(date)
									}
									disablePast
									format={store.global.dateFormat.value}
								/>
								{inputs.expiredDate !== "" &&
									formattedDateWithTz({
										date: inputs.expiredDate,
										timeZone,
										timeFormat,
										dateFormat,
									})}
							</div>
						</div>
						<div className="assign-input-item">
							<div className="input-label"> </div>
							<DLButton
								eleTestId={`dialog-action-btn-assign`}
								clickHandler={handleAssign}
								color="primary"
								disabled={
									inputs.expiredDate === "" ||
									inputs.user === ""
								}
							>
								Assign
							</DLButton>
						</div>
					</div>
				</StyledAddUserForm>
			</div>

			<div
				className="assigned-user-list"
				data-testid="assigned-users-section"
			>
				<p>
					<b>Assigned User List - </b> Current{" "}
					{partialStore.viewReadOnlyUserList().length} Users Assigned
				</p>
				<hr />

				<Grid container>
					<Grid item xs={12}>
						<ReactTableV8
							tableColumns={AccessMgmtDialogTableColumns(
								setAssignedUsers,
								unassignUser,
								updateUser,
								store.global.dateFormat.value,
								timeZone,
								i18n
							)}
							showPageSetting={false}
							data={assignedUsers}
							hasPagination={assignedUsers.length > 10}
							height={800}
							i18n={i18n}
							menuId="AccessMgmtDialog"
						/>
					</Grid>
				</Grid>
			</div>
			{/* )} */}
			{/* <DLButton
					eleTestId={`dialog-action-btn-update`}
					clickHandler={updateUsers}
					color="primary"
					disabled={
						partialStore.viewAccessMgmtUserList()
							.length === 0
					}
				>
					Update
				</DLButton> */}
		</StyledAccessMgmtDialogForm>
	)
})

// NOTE: match with AddProjUserForm
// NOTE: this component use 'userAliasId' and AddProjUserForm is using 'aliasId'
// If it is possible, update
const GroupUserRow = ({
	userInfo,
	inputValue,
}: {
	userInfo: any
	inputValue: any
}) => {
	const matches_name = match(userInfo.name, inputValue)
	const parts_name = parse(userInfo.name, matches_name)
	const matches_aliasId = match(userInfo.userAliasId, inputValue)
	const parts_aliasId = parse(userInfo.userAliasId, matches_aliasId)
	const matches_email = match(userInfo.email, inputValue)
	const parts_email = parse(userInfo.email, matches_email)

	const ModifiedOption = ({ option }: { option: any }) => {
		return (
			<>
				{option.map((part: any, i: number) => (
					<span
						key={i}
						style={{
							fontWeight: part.highlight ? 700 : 400,
						}}
					>
						{part.text}
					</span>
				))}
			</>
		)
	}
	return (
		<div className="FR AC">
			<div
				style={{
					marginRight: "0.4rem",
					whiteSpace: "nowrap",
				}}
			>
				<ModifiedOption option={parts_name} />
			</div>
			<div
				style={{
					marginRight: "0.6rem",
					opacity: 0.8,
					whiteSpace: "nowrap",
				}}
			>
				(<ModifiedOption option={parts_aliasId} />)
			</div>
			<div
				style={{
					opacity: 0.8,
					fontSize: "0.8rem",
					whiteSpace: "nowrap",
				}}
			>
				<ModifiedOption option={parts_email} />
			</div>
		</div>
	)
}

const StyledAccessMgmtDialogForm = styled.div`
	height: 70vh;
	padding: 0 1rem;
	.project-title {
	}
	.user-assign-area {
		margin-bottom: 2rem;
	}
	.assigned-user-list {
		.table {
			// margin-left: -2rem;
		}
	}
	table > thead > tr > th {
		.header {
			min-height: 2rem;
			padding-left: 8px;
			justify-content: center;
		}
	}
`

const StyledAddUserForm = styled.div`
	.exp-permission-radio {
		margin-left: 12px;
	}
	.MuiInput-formControl {
		margin-top: 0px !important;
	}
	.form-control {
		min-width: 11rem;
	}
	.react-datepicker-wrapper {
		margin-top: 6px;
	}
	.datePicker {
		margin-top: -15px;
	}
	.assign-readonly-area {
		width: 100%;
		.assign-input-item {
			.input-label {
				margin-bottom: 0.4rem;
				color: ${(props) => props.theme.shade40};
			}
		}
		.select-user {
			min-width: 25vw;
			.user-select-combo-box {
				width: 100%;
			}
		}
	}
`
