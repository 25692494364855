import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import dummyResponse from "../data-models/response-ep-match-data.json"

const GetEPMatchData = (self: any) => ({
	getEPMatchData() {
		// 0.
		const actionName = "getEPMatchData"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.readEPMatchData()
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					self.setEPMatchDataList(dummyResponse.EPMatch)
					self.setNeedRefresh(false)

					// set success case
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Successfully fetched EP Match data",
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetEPMatchData
