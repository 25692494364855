import { flow } from "mobx-state-tree"
import { CheckFinalReportDateMatchApiProps } from "../check-final-report-date-match.apis"

const ReadFinalReportDateMatchData = (
	self: any,
	read: CheckFinalReportDateMatchApiProps["apiRead"]
) => ({
	readFinalReportDateMatchData: flow(function* () {
		const actionName = "readFinalReportDateMatchData"
		try {
			const response = yield read()
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadFinalReportDateMatchData
