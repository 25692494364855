import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import {
	ProjectDashboard,
	ProjInfoController,
	EntityInformation,
	//
	Board,
	Notes,
	Comments,
	// coa
	CoAList,
	// CoADetail,
	// TempCoADetail,
	// pbc
	PreparedByClientController,
	TaskAndTime,
	// ? financial statements components are removed
	FSSetupController,
	FinancialStatementController,
	ConsolidatingViewController,
	AdjustmentsController,
	PassedAdjustmentsController,
	EliminatingEntryController,
	// Ledgers,
	// TrialBalance,
	TrialBalanceController,
	LeadSheetsController,
	// TempFSLeadsheet,
	// FSPapers,
	// TempTBDetail

	// ? sox related components are removed
	// SOXPlanning,
	// SOXRisks,
	// SOXProcess,
	// SOXControls,
	// SOXLeadsheet,
	// SOXReport,
	Reports,
	Checklist,
	// archive
	ArchiveDiagnosisController,
	ArchivePreProcessController,
	SignOffHistory,
	ArchiveHistoryController,
	RationalesController,
	Rationale,
	RationaleEdit,
	// setup
	ProjectUsersController,
	ProjectSetupRoles,
	ProjectTrash,
	NotificationSettingsController,
	ProjectDelete,
	ProjectRollForward,
	ImportFSDatasetsController,
	AssignFSAccountsController,
	FSAccountsController,
	FinancialGroupController,
	Adjustments,
	MNewAdjustmentController,
	MCreateNewFSGroupNumberController,
	MCreateNewMapNumberController,
	MapNumbersController,
} from "../screens/project-side"
import ProjSideAppFrame from "../components/app-frame-elements/ProjSideAppFrame"
// access control
import ProjCheckin from "../screens/project-side/proj-access-control/ProjCheckin"
import ProjCheckout from "../screens/project-side/proj-access-control/ProjCheckout"
// stores
import { ProjStoreProvider } from "../stores/proj-store/proj-store.provider"
//
import ProjRoute from "./ProjRoute"
import FileEditor from "../screens/lobby-side/file-editor/FileEditor"
import ProjectFiles from "../screens/project-side/project-files/ProjectFiles"
import AdjustmentView from "../screens/project-side/financial-statements/adjustments/sub-components/AdjustmentView"
import PassedAdjustmentView from "../screens/project-side/financial-statements/passed-adjustments/sub-components/PassedAdjustmentView"
import { ConsoleLog } from "../components/basic-elements"
import PBCTeamFilesController from "../screens/project-side/pbc-team-files/PBCTeamFilesController"
import PBCClientFilesController from "../screens/project-side/pbc-client-files/PBCClientFilesController"
import AssignFields from "../screens/project-side/fs-data/import-fs-datasets/sub-components/AssignFields"

export default function ProjRouter() {
	ConsoleLog("ProjRouter")
	const defaultUrl = "/project/:id"
	return (
		<ProjStoreProvider>
			<ProjSideAppFrame>
				<Switch>
					<Route
						exact
						path="/project/checkin/:id"
						component={ProjCheckin}
					/>

					<Route
						exact
						path="/project/checkout"
						component={ProjCheckout}
					/>
					{/* ----- Menus ----- */}
					<ProjRoute path={`${defaultUrl}/project-dashboard`}>
						<ProjectDashboard />
					</ProjRoute>

					<ProjRoute
						path={`${defaultUrl}/information/project-information`}
					>
						<ProjInfoController />
					</ProjRoute>

					<ProjRoute
						path={`${defaultUrl}/information/entity-information`}
					>
						<EntityInformation />
					</ProjRoute>

					<ProjRoute path={`${defaultUrl}/information/coa-list`}>
						<CoAList />
					</ProjRoute>

					<ProjRoute path={`${defaultUrl}/note-and-comments/board`}>
						<Board />
					</ProjRoute>

					<ProjRoute path={`${defaultUrl}/note-and-comments/note`}>
						<Notes />
					</ProjRoute>

					<ProjRoute
						path={`${defaultUrl}/note-and-comments/comments`}
					>
						<Comments />
					</ProjRoute>

					<ProjRoute path={`${defaultUrl}/prepared-by-client/pbc`}>
						<PreparedByClientController />
					</ProjRoute>
					<ProjRoute
						path={`${defaultUrl}/prepared-by-client/pbc-team-files`}
					>
						<PBCTeamFilesController />
					</ProjRoute>
					<ProjRoute
						path={`${defaultUrl}/prepared-by-client/pbc-client-files`}
					>
						<PBCClientFilesController />
					</ProjRoute>

					<ProjRoute path={`${defaultUrl}/task-and-time`}>
						<TaskAndTime />
					</ProjRoute>

					{/* Financial Statement */}
					<ProjRoute
						path={`${defaultUrl}/financial-statements/fs-setup`}
					>
						<FSSetupController />
					</ProjRoute>

					<ProjRoute
						path={`${defaultUrl}/financial-statements/financial-statements`}
					>
						<FinancialStatementController />
					</ProjRoute>

					<ProjRoute
						path={`${defaultUrl}/financial-statements/consolidating-view`}
					>
						<ConsolidatingViewController />
					</ProjRoute>

					<ProjRoute
						path={`${defaultUrl}/financial-statements/trial-balance`}
					>
						<TrialBalanceController />
					</ProjRoute>

					<ProjRoute
						path={`${defaultUrl}/financial-statements/fs-leadsheet`}
					>
						<LeadSheetsController />
					</ProjRoute>

					<ProjRoute
						exact
						path={`${defaultUrl}/financial-statements/adjustments`}
					>
						<AdjustmentsController />
					</ProjRoute>

					<ProjRoute
						exact
						path={`${defaultUrl}/financial-statements/adjustments/view/:parentId`}
					>
						<AdjustmentView />
					</ProjRoute>

					<ProjRoute
						exact
						path={`${defaultUrl}/financial-statements/passed-adjustments`}
					>
						<PassedAdjustmentsController />
					</ProjRoute>

					<ProjRoute
						exact
						path={`${defaultUrl}/financial-statements/passed-adjustments/view/:parentId`}
					>
						<PassedAdjustmentView />
					</ProjRoute>

					<ProjRoute
						path={`${defaultUrl}/financial-statements/eliminating-entry`}
					>
						<EliminatingEntryController />
					</ProjRoute>

					{/* Additional menus are required */}

					<ProjRoute path={`${defaultUrl}/files`}>
						<ProjectFiles />
					</ProjRoute>

					<ProjRoute exact path={`${defaultUrl}/archive`}>
						<ArchiveDiagnosisController />
					</ProjRoute>

					<ProjRoute
						exact
						path={`${defaultUrl}/archive/archive-diagnosis`}
					>
						<ArchiveDiagnosisController />
					</ProjRoute>

					<ProjRoute
						exact
						path={`${defaultUrl}/archive/archive-pre-process`}
					>
						<ArchivePreProcessController />
					</ProjRoute>

					<ProjRoute
						exact
						path={`${defaultUrl}/archive/sign-off-history`}
					>
						<SignOffHistory />
					</ProjRoute>

					<ProjRoute exact path={`${defaultUrl}/archive/rationales`}>
						<RationalesController />
					</ProjRoute>
					<ProjRoute
						exact
						path={`${defaultUrl}/archive/rationales/:parentId`}
					>
						<Rationale />
					</ProjRoute>
					<ProjRoute
						exact
						path={`${defaultUrl}/archive/rationales/edit/:parentId`}
					>
						<RationaleEdit />
					</ProjRoute>

					<ProjRoute
						exact
						path={`${defaultUrl}/archive/archive-history`}
					>
						<ArchiveHistoryController />
					</ProjRoute>

					<ProjRoute exact path={`${defaultUrl}/project-setup`}>
						<ProjectUsersController />
					</ProjRoute>

					<ProjRoute
						exact
						path={`${defaultUrl}/project-setup/project-users`}
					>
						<ProjectUsersController />
					</ProjRoute>

					<ProjRoute exact path={`${defaultUrl}/project-setup/roles`}>
						<ProjectSetupRoles />
					</ProjRoute>

					<ProjRoute
						exact
						path={`${defaultUrl}/project-setup/project-trash`}
					>
						<ProjectTrash />
					</ProjRoute>
					<ProjRoute
						exact
						path={`${defaultUrl}/project-setup/notification-settings`}
					>
						<NotificationSettingsController />
					</ProjRoute>
					<ProjRoute
						exact
						path={`${defaultUrl}/project-setup/danger`}
					>
						<ProjectDelete />
					</ProjRoute>
					<ProjRoute
						exact
						path={`${defaultUrl}/project-setup/roll-forward`}
					>
						<ProjectRollForward />
					</ProjRoute>
					<ProjRoute exact path={`${defaultUrl}/reports/report`}>
						<Reports />
					</ProjRoute>
					<ProjRoute exact path={`${defaultUrl}/reports/checklist`}>
						<Checklist />
					</ProjRoute>
					<ProjRoute
						exact
						path={`${defaultUrl}/fs-data/import-fs-datasets`}
					>
						<ImportFSDatasetsController />
					</ProjRoute>
					<ProjRoute
						exact
						path={`${defaultUrl}/fs-data/import-fs-datasets/:parentId`}
					>
						<AssignFields />
					</ProjRoute>
					<ProjRoute
						exact
						path={`${defaultUrl}/fs-data/assign-fs-accounts`}
					>
						<AssignFSAccountsController />
					</ProjRoute>
					<ProjRoute exact path={`${defaultUrl}/fs-data/fs-accounts`}>
						<FSAccountsController />
					</ProjRoute>
					<ProjRoute
						exact
						path={`${defaultUrl}/fs-data/financial-group`}
					>
						<FinancialGroupController />
					</ProjRoute>
					<ProjRoute
						exact
						path={`${defaultUrl}/fs-data/new-fs-group-number`}
					>
						<MCreateNewFSGroupNumberController />
					</ProjRoute>
					<ProjRoute exact path={`${defaultUrl}/fs-data/map-numbers`}>
						<MapNumbersController />
					</ProjRoute>
					<ProjRoute
						exact
						path={`${defaultUrl}/fs-data/new-map-number`}
					>
						<MCreateNewMapNumberController />
					</ProjRoute>
					<ProjRoute
						exact
						path={`${defaultUrl}/fs-adjustments/adjustments`}
					>
						<Adjustments />
					</ProjRoute>
					<ProjRoute
						exact
						path={`${defaultUrl}/fs-adjustments/new-adjustment`}
					>
						<MNewAdjustmentController />
					</ProjRoute>
				</Switch>
			</ProjSideAppFrame>
		</ProjStoreProvider>
	)
}
