export const dummyData = [
	{
		id: "folderId1",
		parentId: null,
		isParent: true,
		title: "A Non-current assets",
		expanded: false,
		balance: "0.00",
		code: null,
	},
	{
		id: "folderId4",
		parentId: "folderId1",
		isParent: true,
		title: "A.01 Intangible assets",
		expanded: false,
		balance: "0.00",
		code: null,
	},
	{
		id: "folderId5",
		parentId: "folderId1",
		isParent: true,
		title: "A.02 Tangible fixed assets",
		expanded: false,
		balance: "0.00",
		code: null,
	},
	{
		id: "folderId6",
		parentId: "folderId1",
		isParent: true,
		title: "A.03 Fixed assets investments",
		expanded: false,
		balance: "0.00",
		code: null,
	},
	{
		id: "fileId1",
		parentId: "folderId4",
		isParent: false,
		title: "Freehold Intangible assets",
		expanded: false,
		balance: "0.00",
		code: "A.01.01",
	},
	{
		id: "fileId2",
		parentId: "folderId5",
		isParent: false,
		title: "Freehold Tangible assets",
		expanded: false,
		balance: "0.00",
		code: "A.02.01",
	},
	{
		id: "fileId3",
		parentId: "folderId6",
		isParent: false,
		title: "Freehold Fixed assets",
		expanded: false,
		balance: "0.00",
		code: "A.03.01",
	},
	{
		id: "folderId2",
		parentId: null,
		isParent: true,
		title: "B Debiters due after more than 1 year",
		expanded: false,
		balance: "0.00",
		code: null,
	},
	{
		id: "folderId8",
		parentId: "folderId2",
		isParent: true,
		title: "B.01 Intangible assets",
		expanded: false,
		balance: "0.00",
		code: null,
	},
	{
		id: "fileId4",
		parentId: "folderId8",
		isParent: false,
		title: "Freehold Intangible assets",
		expanded: false,
		balance: "0.00",
		code: "B.01.01",
	},
	{
		id: "folderId9",
		parentId: "folderId2",
		isParent: true,
		title: "B.02 Tangible fixed assets",
		expanded: false,
		balance: "0.00",
		code: null,
	},
	{
		id: "fileId5",
		parentId: "folderId8",
		isParent: false,
		title: "Freehold Tangible assets",
		expanded: false,
		balance: "0.00",
		code: "B.02.01",
	},
	{
		id: "folderId20",
		parentId: "folderId2",
		isParent: true,
		title: "B.03 Fixed assets investments",
		expanded: false,
		balance: "0.00",
		code: null,
	},
	{
		id: "fileId6",
		parentId: "folderId20",
		isParent: false,
		title: "Freehold Fixed assets",
		expanded: false,
		balance: "0.00",
		code: "B.03.01",
	},
]

export const dummyAccounts = [
	{
		id: "fileIdB001",
		code: "B001",
		name: "Freehold Property",
		balance: "1.00",
		isAssigned: false,
	},
	{
		id: "fileIdB011",
		code: "B011",
		name: "Plant and Machinery cost b/f wd",
		balance: "1.00",
		isAssigned: false,
	},
	{
		id: "fileIdB012",
		code: "B012",
		name: "Plant & Mach. Additions",
		balance: "1.00",
		isAssigned: false,
	},
	{
		id: "fileIdB014",
		code: "B014",
		name: "Plant & Mach. Depn b/f wd",
		balance: "(1.00)",
		isAssigned: false,
	},
	{
		id: "fileIdB015",
		code: "B015",
		name: "Plant & Mach. Depn chg/yr",
		balance: "(1.00)",
		isAssigned: false,
	},
]
