import React from "react"
import { DLContextMenu } from "../../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { useHistory } from "react-router-dom"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import { requestToken } from "../../../../project-side/proj-access-control/store/proj-checkin.apis"
import { saveLatestProjToken } from "../../../../../library/token-utils"

export default observer(function CheckEPMatchCxtMenu({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	const orgStore = useOrgStore()
	const history = useHistory()

	const handleVisitUsers = () => {
		const userNumId = idToNumber(orgStore.checkin.orgUserId, IdType.user)
		const selectedItem = orgStore.checkEPMatch.viewSelectedItemInfo()
		const projNumId = selectedItem
			? parseInt(selectedItem?.aulSystemId)
			: undefined

		if (typeof userNumId === "number" && typeof projNumId === "number") {
			requestToken({
				UserId: userNumId,
				ClientId: projNumId,
			}).then((response: any) => {
				if (response.data.status.code === 200) {
					saveLatestProjToken(response.data.data.access_token)
					history.push(
						`/project/${selectedItem?.aulSystemId}/project-setup/project-users`
					)
				} else {
					alert(response.data.status.message)
				}
			})
		}
	}

	const menuOptions = [
		{
			label: "Edit AUL Project Info",
			value: "ctx-edit-aul-proj",
			available: permission.read,
			// clickEvent: () =>
			// 	orgStore.erpData.setAssignClientToGroupDialogOpen(true),
		},
		{
			label: `Visit Project Users`,
			value: "ctx-visit-project",
			available: permission.update,
			clickEvent: handleVisitUsers,
		},
	]

	const handleClose = () => {
		orgStore.checkEPMatch.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}

	return (
		<DLContextMenu
			eleId="aul-mapping-erp-data-ctx-menus"
			clickPoint={{
				mouseX: orgStore.checkEPMatch.clickPoint.mouseX,
				mouseY: orgStore.checkEPMatch.clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={menuOptions}
			hasDivider={[1]}
		/>
	)
})
