import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import {
	DLButton,
	DLDatepicker,
	DLDialog,
	DLInputField,
	DLRadioGroup,
} from "../../../../../components/basic-elements"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { FormControl, MenuItem, Select } from "@material-ui/core"
import styled from "styled-components"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { Icon } from "@mdi/react"
import { mdiArrowExpand } from "@mdi/js"
import { getTzDate } from "../../../../../library/converters/date-utc-converter"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"

const AddNewDatasetDialog = observer(() => {
	const projStore = useProjStore()
	const [isFullScreen, setIsFullScreen] = useState(false)
	const [datasetType, setDatasetType] = useState("")

	const addedBy = projStore.checkin.user.name
	console.log(addedBy)

	const handleImportData = () => {
		projStore.importFSDatasets.pushToDataset({
			id: "1",
			source: projStore.importFSDatasets.fileName,
			datasetType,
			dateAdded: new Date().toISOString(),
			addedBy: addedBy,
			status: "InProgress",
		})
		projStore.importFSDatasets.setOpenAddNewDatasetModel(false)
	}

	return (
		<DLDialog
			eleTestId="add-new-dataset-dialog"
			isOpen={projStore.importFSDatasets.openAddNewDatasetModel}
			setIsOpen={projStore.importFSDatasets.setOpenAddNewDatasetModel}
			handleAction={handleImportData}
			showCloseBtn={true}
			dialogTitle={isFullScreen ? "Adjust rows" : "Configure New Dataset"}
			dialogContents={
				<AddNewDatasetDialogContent
					partialStore={projStore.importFSDatasets}
					isFullScreen={isFullScreen}
					setIsFullScreen={setIsFullScreen}
					datasetType={datasetType}
					setDatasetType={setDatasetType}
				/>
			}
			showOpenBtn={false}
			cancelBtnText={"Cancel"}
			actionReady={!!datasetType}
			actionBtn={"Upload"}
			maxWidth="sm"
			fullWidth={true}
			fullScreen={isFullScreen}
		/>
	)
})

export default AddNewDatasetDialog

const AddNewDatasetDialogContent = ({
	partialStore,
	isFullScreen,
	setIsFullScreen,
	datasetType,
	setDatasetType,
}: {
	partialStore: any
	isFullScreen: boolean
	setIsFullScreen: any
	datasetType: string
	setDatasetType: any
}) => {
	const store = useRootStore()
	const [selectedTBType, setSelectedTBValue] = useState("final")
	const [plannigTBDate, setPlannigTBDate] = useState(null)
	const [columnRow, setColumnRow] = useState(0)

	const { dateFormat, timeZone } = store.global.getDntFormat

	const handleChange = (event: any) => setSelectedTBValue(event.target.value)

	const handleDatasetType = (e: any) => {
		setDatasetType(e.target.value)
	}

	return (
		<StyledContent>
			{isFullScreen ? (
				<div className="FC" style={{ gap: 10 }}>
					<div className="file-name">
						Adjust header row for {partialStore.fileName}
					</div>
					<div>
						Use the setting below to select the correct header row
					</div>
					<div>
						<div>Header column row (0 for no header)</div>
						<DLInputField
							eleTestId="header-input"
							eleHandleChange={() => {}}
							eleValue={columnRow}
							eleType="number"
							variant="outlined"
							eleClassName="header-input"
						/>
					</div>
				</div>
			) : (
				<>
					<div className="file-name">
						Configure {partialStore.fileName}
					</div>

					<div className="label">Dataset Type</div>
					<FormControl
						style={{ marginTop: 2 }}
						fullWidth
						size="small"
					>
						<Select
							labelId="data-set-type-label"
							id="data-set-type"
							value={datasetType}
							variant="outlined"
							displayEmpty
							placeholder="Select dataset type"
							onChange={handleDatasetType}
							fullWidth
						>
							<MenuItem disabled value="">
								Select dataset type
							</MenuItem>
							<MenuItem value={"trial-balance"}>
								Trial Balance
							</MenuItem>
							<MenuItem value={"general-ledger"}>
								General Ledger
							</MenuItem>
						</Select>
					</FormControl>

					{datasetType === "trial-balance" && (
						<div>
							<div className="row">
								<div className="label-name">
									Reporting Period:
								</div>
								<div>Annually</div>
							</div>
							<div className="row">
								<div className="label-name">Year end data:</div>
								<div>31/12/24</div>
							</div>
							<div className="row">
								Year end date can be changed in engagement
								properties
							</div>
							<DLRadioGroup
								groupName="trail-balance-type"
								itemDirection="column"
								items={[
									{
										value: "final",
										label: "Final trial balance",
									},
									{
										value: "planning",
										label: "Planning trial balance",
									},
								]}
								selectedValue={selectedTBType}
								eleOnChange={handleChange}
								eleClassName="trail-balance-type"
							/>

							{selectedTBType === "planning" && (
								<DLDatepicker
									eleTestId="planning-tb-date-field"
									eleName="plannigTBDate"
									selected={
										plannigTBDate
											? getTzDate({
													date: plannigTBDate,
													timeZone,
													dateFormat,
											  })
											: null
									}
									onChange={(date: any) =>
										setPlannigTBDate(date)
									}
									format={dateFormat}
									eleLabel="Select Date"
								/>
							)}
						</div>
					)}
				</>
			)}

			<div className="header-preview-section">
				<div className="FR JSB AC">
					<div className="header-text">Header row preview</div>
					<div>
						<DLButton
							eleTestId="adjudt-header-row-btn"
							clickHandler={() =>
								setIsFullScreen((prev: any) => !prev)
							}
							eleClassName="adjudt-header-row-btn"
							startIcon={
								<Icon path={mdiArrowExpand} size={0.8} />
							}
						>
							Adjust Header Row
						</DLButton>
					</div>
				</div>
			</div>
			<ReactTableV8
				tableColumns={Columns()}
				data={partialStore.viewUploadedData()}
				hasPagination={false}
				showPageSetting={false}
				showHeader={false}
				height={400}
				menuId="UploadFilesForm"
			/>
		</StyledContent>
	)
}

const Columns = () => {
	return [
		{
			header: "Column1",
			accessorKey: "column1",
		},
		{
			header: "Column2",
			accessorKey: "column2",
		},
		{
			header: "Column3",
			accessorKey: "column3",
		},
		{
			header: "Column4",
			accessorKey: "column4",
		},
		{
			header: "Column5",
			accessorKey: "column5",
		},
	]
}

const StyledContent = styled.div`
	.file-name {
		font-size: 1rem;
		font-weight: 500;
	}
	.label {
		margin-top: 10px;
	}
	.header-preview-section {
		margin: 10px 0;
		.header-text {
			font-size: 1rem;
			font-weight: 600;
		}
	}
	.trail-balance-type {
		margin-top: 10px;
		margin-left: 10px;
	}
	.row {
		margin-top: 10px;
		display: flex;
		flex-direction: row;
		gap: 20px;
		.label-name {
			min-width: 10rem;
		}
	}
	table {
		border: 1px solid rgba(224, 224, 224, 1);
		tbody > tr > td {
			padding: 8px;
		}
	}
	.header-input {
		width: 10rem;
	}
`
