import React from "react"
import { DLContextMenu } from "../../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { useHistory } from "react-router-dom"

export default observer(function DatasetContextMenus({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	const history = useHistory()
	const projStore = useProjStore()
	const defaultUrl = projStore.checkin.defaultUrl
	const handleReassign = () => {
		projStore.importFSDatasets.setShowHeader(false)
		history.push(defaultUrl + `/fs-data/import-fs-datasets/1`)
	}

	const menuOptions = () => [
		{
			label: "Reassign fields",
			value: "reassign",
			available: permission.update,
			clickEvent: handleReassign,
		},
		{
			label: "Delete data",
			value: "delete",
			available: permission.delete,
		},
	]

	const handleClose = () => {
		projStore.importFSDatasets.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}

	return (
		<DLContextMenu
			eleId="dataset-ctx-menus"
			clickPoint={{
				mouseX: projStore.importFSDatasets.clickPoint.mouseX,
				mouseY: projStore.importFSDatasets.clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={menuOptions()}
			// hasDivider={[1]}
		/>
	)
})
