import React from "react"
import {
	DLSingleSelect,
	DLTextField,
	DLDatepicker,
	DLComboBox,
} from "../../../../../components/basic-elements"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import {
	getTzDate,
	getFormattedEOD2,
} from "../../../../../library/converters/date-utc-converter"

// This form does not use stored data.
export default observer(function ERPDataForm({
	inputs,
	handleChange,
	handleDateInput,
	dntFormat,
}: any) {
	// 	const {
	// 		erpReportDate,
	// erpReportReleaseDate
	// 	} = inputs

	const { timeZone, dateFormat } = dntFormat

	return (
		<StyledERPDataForm className="erp-form">
			{/* SECTION 1: Default information  */}
			<div className="section">
				<div className="section-row FR JSB">
					<div className="input-container">
						<DLTextField
							eleTestId="erp-client-aliasId-field"
							eleId="erpClientMasterAliasId"
							eleLabel="ERP Client Master AliasId"
							eleRequired
							eleFullWidth
							eleValue={inputs.erpClientMasterAliasId}
							eleOnChange={handleChange}
						/>
					</div>
					<div className="input-container">
						<DLTextField
							eleTestId="erpEngagementOwner-field"
							eleId="erpEngagementOwner"
							eleLabel="ERP Engagement Owner"
							// eleDefaultValue={inputs.clientAliasId}
							eleRequired
							eleFullWidth
							eleValue={inputs.erpEngagementOwner}
							eleOnChange={handleChange}
						/>
					</div>
				</div>
				<div className="section-row FR JSB">
					<div className="input-container">
						<DLTextField
							eleTestId="erpClientMasterName-field"
							eleId="erpClientMasterName"
							eleLabel="ERP Client Master Name"
							eleRequired
							eleValue={inputs.erpClientMasterName}
							eleOnChange={handleChange}
							eleFullWidth
						/>
					</div>
					<div className="input-container">
						<DLTextField
							eleTestId="erpProjectAliasId-field"
							eleId="erpProjectAliasId"
							eleLabel="ERP Project AliasId"
							eleValue={inputs.erpProjectAliasId}
							eleOnChange={handleChange}
							eleFullWidth
							eleRequired
						/>
					</div>
				</div>
				<div className="section-row FR JSB">
					<div className="input-container">
						<DLTextField
							eleTestId="erpEngagementMasterAliasId-field"
							eleId="erpEngagementMasterAliasId"
							eleLabel="ERP Engagement Master AliasId"
							eleRequired
							eleValue={inputs.erpEngagementMasterAliasId}
							eleOnChange={handleChange}
							eleFullWidth
						/>
					</div>
					<div className="input-container">
						<DLTextField
							eleTestId="erpProjectName-field"
							eleId="erpProjectName"
							eleLabel="ERP Project Name"
							eleValue={inputs.erpProjectName}
							eleOnChange={handleChange}
							eleFullWidth
							eleRequired
						/>
					</div>
				</div>
				<div className="section-row FR JSB">
					<div className="input-container">
						<DLTextField
							eleTestId="erpEngagementMasterName-field"
							eleId="erpEngagementMasterName"
							eleLabel="ERP Engagement Master Name"
							eleRequired
							eleValue={inputs.erpEngagementMasterName}
							eleOnChange={handleChange}
							eleFullWidth
						/>
					</div>
					<div className="input-container">
						<DLTextField
							eleTestId="erpProjectOwner-field"
							eleId="erpProjectOwner"
							eleLabel="ERP Project Owner"
							eleValue={inputs.erpProjectOwner}
							eleOnChange={handleChange}
							eleFullWidth
							eleRequired
						/>
					</div>
				</div>
				{/* </div>
			<div className="section"> */}
				<div className="section-row FR JSB">
					<div className="input-container">
						<DLTextField
							eleTestId="erpEngagementYear-field"
							eleId="erpEngagementYear"
							eleLabel="ERP Engagement Year"
							eleRequired
							eleValue={inputs.erpEngagementYear}
							eleOnChange={handleChange}
							eleFullWidth
						/>
					</div>
					<div className="input-container">
						<DLDatepicker
							eleTestId="erpReportDatee-field"
							eleName="erpReportDate"
							selected={
								inputs.erpReportDate
									? getTzDate({
											date: inputs.erpReportDate,
											timeZone,
											dateFormat,
									  })
									: null
							}
							eleLabel="ERP Report Date"
							onChange={(date: any) =>
								handleDateInput("erpReportDate", date)
							}
							format={dateFormat}
							fullWidth
						/>
					</div>
				</div>
				<div className="section-row FR JSB">
					<div className="input-container">
						<DLTextField
							eleTestId="erpEngagementType-field"
							eleId="erpEngagementType"
							eleLabel="ERP Engagement Type"
							eleRequired
							eleValue={inputs.erpEngagementType}
							eleOnChange={handleChange}
							eleFullWidth
						/>
					</div>
					<div className="input-container">
						<DLDatepicker
							eleTestId="erpReportReleaseDate-field"
							eleName="erpReportReleaseDate"
							selected={
								inputs.erpReportReleaseDate
									? getTzDate({
											date: inputs.erpReportReleaseDate,
											timeZone,
											dateFormat,
									  })
									: null
							}
							eleLabel="ERP Report Release Date"
							onChange={(date: any) =>
								handleDateInput("erpReportReleaseDate", date)
							}
							format={dateFormat}
							fullWidth
						/>
					</div>
				</div>
				{/* </div>
			<div className="section"> */}
				<div className="section-row FR JSB">
					<div className="input-container"></div>
					<div className="input-container">
						<DLTextField
							eleTestId="createdBy-field"
							eleId="createdBy"
							eleLabel="Created By"
							eleRequired
							eleValue={inputs.createdBy}
							eleOnChange={handleChange}
							eleFullWidth
						/>
					</div>
				</div>
			</div>
		</StyledERPDataForm>
	)
})

const StyledERPDataForm = styled.div`
	// width: 700px;
	.section {
		margin-bottom: 2rem;
		.section-title {
			font-weight: 700;
			margin-bottom: 1rem;
		}
		.section-row {
			width: 100%;
			.input-container {
				width: calc(50% - 1rem);
				margin-bottom: 1rem;
				.financial-year-field {
					min-width: 64px;
					width: 30%;
					margin-right: 1rem;
				}
				.period-end-field {
					width: calc(70% - 1rem);
				}
			}
		}
	}
	.MuiAutocomplete-clearIndicator {
		display: none;
	}
`
