import React from "react"
import { Route } from "react-router-dom"
import ERPDataController from "./ERPData/ERPDataController"
import CheckFinalReportDateMatchController from "./CheckFinalReportDateMatch/CheckFinalReportDateMatchController"
import CheckEPMatchController from "./CheckEPMatch/CheckEPMatchController"
import MappingAULToERPController from "./MappingAULToERP/MappingAULToERPController"

export default function ERPDataSync() {
	return (
		<>
			<Route
				exact
				path="/organization/erp-data-sync"
				component={ERPDataController}
			/>
			<Route
				exact
				path="/organization/erp-data-sync/erp-data"
				component={ERPDataController}
			/>
			<Route
				exact
				path="/organization/erp-data-sync/mapping-aul-to-erp"
				component={MappingAULToERPController}
			/>
			<Route
				exact
				path="/organization/erp-data-sync/check-ep-match"
				component={CheckEPMatchController}
			/>
			<Route
				exact
				path="/organization/erp-data-sync/check-final-report-date-match"
				component={CheckFinalReportDateMatchController}
			/>
		</>
	)
}
