import { flow } from "mobx-state-tree"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"
import { TOKEN_TYPE } from "../../../../../../library/api-requests/shared-for-api-requests"
import { postRequest } from "../../../../../../library/api-requests"

const DeleteForceArchivedPreProcessPdfs = (self: any) => ({
	deleteForceArchivedPreProcessPdfs: flow(function* (projId: string) {
		const actionName = "deleteForceArchivedPreProcessPdfs"
		const projNumberId = idToNumber(projId, IdType.project)

		if (typeof projNumberId === "number") {
			try {
				const response = yield postRequest({
					url: `/DeleteForceArchivedPreconditionFiles`,
					params: {
						ClientId: projNumberId,
					}, // payload,
					tokenType: TOKEN_TYPE.orgToken,
				})

				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName, '__error', error)
				self.handleModelError({ actionName, error, open: true })

				return false
			}
		} else {
			// alert(
			// 	`(${actionName}) invalid prop exist. ${projNumberId} | ${userNumberId}`
			// )
		}
	}),
})

export default DeleteForceArchivedPreProcessPdfs
