import { types } from "mobx-state-tree"
import ImportFSDatasetsViewModel from "./import-fs-datasets.view-model"
import ImportFSDatasetsModel from "./import-fs-datasets.model"

export const ImportFSDatasetsStore = () =>
	types
		.compose(ImportFSDatasetsModel(), ImportFSDatasetsViewModel)
		.named("ImportFSDatasetsStore")

export const initialStore = {
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const ImportFSDatasetsStoreInstance =
	ImportFSDatasetsStore().create(initialStore)

export default ImportFSDatasetsStoreInstance
