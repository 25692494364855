import React, { ReactNode, memo, useCallback, useEffect, useState } from "react"
import {
	flexRender,
	useReactTable,
	getCoreRowModel,
	getPaginationRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getSortedRowModel,
	ColumnFiltersState,
	PaginationState,
	ColumnResizeMode,
	ColumnOrderState,
	getExpandedRowModel,
	getGroupedRowModel,
} from "@tanstack/react-table"
import styled from "styled-components"
import MaUTable from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableFooter from "@material-ui/core/TableFooter"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import { DLCheckbox } from "@datalobby/components/lib"
import DLDialog from "../dialogs/DLDialog"
import DefaultPageCtxMenus from "../../combined-elements/projects-table/DefaultPageCtxMenus"
import { PermissionAsObjectProps } from "../../../common-models/permission"
import { DLI18nProps } from "../../../common-models/types/common-props"
import {
	savePageSetting,
	getPageSettings,
} from "../../../library/get-page-settings"
import Icon from "@mdi/react"
import { mdiArrowDown, mdiArrowUp } from "@mdi/js"
import { SortableList } from "../sortable-list/SortableList"

export type ReactTableColumnProps = {
	header: string | ReactNode
	accessorKey: string
	cell?: (props: any) => ReactNode
	filterFn?: any
	enableColumnFilter?: boolean
	enableSorting?: boolean
	size?: number
	width?: number
	[x: string]: any
}[]

const FileName: any = {
	SetupUsers: "Organization Users",
	HardCopy: "Hard Copy List",
	HardCopyHistory: "Hard Copy History",
	RemindArchiving: "Remind Archiving",
	RemindReport: "Remind Report",
	AdminRemindReport: "Admin Remind Report",
	AdminRemindArchiving: "Admin Remind Archiving",
	AssignedNormalProjects: "Assigned Normal Projects",
	AssignedReplicaProjects: "Assigned Replica Projects",
	AssignedArchivedProjects: "Assigned Archived Projects",
	AssignedUnarchivedProjects: "Assigned Unarchived Projects",
	AssignedArchivedReadonlyProjects: "Assigned Archived Readonly Projects",
	NormalProj: "Normal Projects",
	ReplicaProj: "Replica Projects",
	ArchivedProj: "Archived Projects",
	UnarchivedProj: "UnArchived Projects",
	ArchProjMgmt: "AM Archived Projects",
	UnarchProjMgmt: "AM UnArchived Projects",
}

function ReactTableV8({
	tableColumns,
	data,
	customFilters,
	defaultFilters = [],
	defaultPageSize = 50,
	hasPagination = true,
	showPageSetting = true,
	height,
	permission,
	i18n,
	pageHeader,
	menuId,
	handleContextMenu,
	onSelectedRowChange,
	getRowClass,
	getCellClass,
	hasFooter = false,
	groupBy = [],
	renderSubcomponent,
	onRowClick,
	showHeader = true,
}: {
	tableColumns: any
	data: any
	defaultFilters?: any
	defaultPageSize?: any
	customFilters?: any
	hasPagination?: boolean
	showPageSetting?: boolean
	height?: number | string
	permission?: PermissionAsObjectProps
	i18n?: DLI18nProps
	pageHeader?: any
	menuId: string
	handleContextMenu?: any
	onSelectedRowChange?: (items: any) => void
	getRowClass?: any
	getCellClass?: any
	hasFooter?: boolean
	groupBy?: any
	renderSubcomponent?: any
	onRowClick?: any
	showHeader?: boolean
}) {
	const [{ pageIndex, pageSize }, setPagination] =
		React.useState<PaginationState>({
			pageIndex: 0,
			pageSize: defaultPageSize,
			// pageCount: data?.length ?? -1,
		})

	// useEffect(() => {
	// 	setPagination({
	// 		pageIndex: 0,
	// 		pageSize: defaultPageSize,
	// 		// pageCount: data?.length ?? -1,
	// 	})
	// }, [])

	const pagination = React.useMemo(
		() => ({
			pageIndex,
			pageSize,
			pageCount: data?.length ?? -1,
		}),
		[pageIndex, pageSize, data?.length]
	)

	const handlePagination = useCallback((props: any) => {
		// console.log(props({ pageIndex, pageSize }), "pagination")
		// partialStore.setPagination(props({ pageIndex: 0, pageSize: 50 }))
		setPagination(props)
	}, [])

	const [open, setOpen] = useState(false)
	const [columnVisibility, setColumnVisibility] = useState({})
	const [rowSelection, setRowSelection] = React.useState({})
	const [columnOrder, setColumnOrder] = React.useState<ColumnOrderState>([])
	const [columnFilters, setColumnFilters] =
		useState<ColumnFiltersState>(defaultFilters)
	const [grouping, setGrouping] = useState(groupBy)

	useEffect(() => {
		const pageSetting = getPageSettings(menuId)
		if (pageSetting?.columnFilters)
			setColumnFilters(pageSetting?.columnFilters)
		if (pageSetting?.columnVisibility)
			setColumnVisibility(pageSetting?.columnVisibility)
	}, [])

	const handleColumnVisibility = useCallback((props: any) => {
		const value = props(columnVisibility)
		setColumnVisibility(props)
		savePageSetting("columnVisibility", value, `pageSetting_${menuId}`)
	}, [])

	const handleColumnFilter = useCallback((props: any) => {
		const value = props(columnFilters)
		setColumnFilters(props)
		savePageSetting("columnFilters", value, `pageSetting_${menuId}`)
		setPagination({ pageIndex: 0, pageSize: defaultPageSize })
	}, [])

	const handleRowSelection = async (props: any) => {
		await setRowSelection(props)
		onSelectedRowChange &&
			(await onSelectedRowChange(table.getSelectedRowModel().flatRows))
	}

	const [columnResizeMode, setColumnResizeMode] =
		React.useState<ColumnResizeMode>("onChange")

	const table = useReactTable({
		data,
		columns: tableColumns,
		columnResizeMode,
		state: {
			columnFilters,
			columnOrder,
			columnVisibility,
			pagination,
			rowSelection,
			grouping,
		},
		onColumnFiltersChange: handleColumnFilter,
		onColumnOrderChange: setColumnOrder,
		onColumnVisibilityChange: handleColumnVisibility,
		onPaginationChange: handlePagination,
		onRowSelectionChange: handleRowSelection,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getGroupedRowModel: getGroupedRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		debugTable: true,
		autoResetPageIndex: false,
	})

	// console.log(
	// 	table,
	// 	table?.getHeaderGroups(),
	// 	table?.getCoreRowModel(),
	// 	"table1"
	// )

	try {
		console.log(table?.getFilteredRowModel())
		console.log(table?.getPaginationRowModel())
		console.log(table?.getRowModel())
	} catch (error) {
		console.log(error)
	}

	// console.log(partialStore.pagination, "pagination")

	const handleChangePage = (event: any, newPage: any) =>
		table.setPageIndex(newPage)

	const handleChangeRowsPerPage = (event: any) => {
		// setRowsPerPage(+event.target.value);
		// setPage(0);
		table.setPageSize(Number(event.target.value))
	}

	const hasId = (rowData: any) => {
		if (menuId === "SetupUsers" || menuId === "ReadonlyOrgUserList") {
			return rowData?.id?.includes("userId")
		} else if (
			menuId === "SetupClients" ||
			menuId === "ReadonlyOrgClientList"
		) {
			return rowData?.clientId?.includes("clientId")
		} else if (
			menuId === "SetupGroups" ||
			menuId === "ReadonlyOrgGroupList"
		) {
			return rowData?.groupId?.includes("groupId")
		} else {
			return rowData?.id?.includes("projId")
		}
	}

	const getSystemId = (rowData: any) => {
		if (menuId === "SetupClients" || menuId === "ReadonlyOrgClientList") {
			return rowData?.clientId.slice(8)
		} else if (
			menuId === "SetupGroups" ||
			menuId === "ReadonlyOrgGroupList"
		) {
			return rowData?.groupId.slice(7)
		} else {
			return rowData?.id.slice(6)
		}
	}

	const handleExcelData = (type: "Filtered" | "Total") => {
		const excelData =
			type === "Filtered"
				? table.getFilteredRowModel()
				: table.getPreFilteredRowModel()
		return excelData.flatRows.map((item: any) =>
			item.getAllCells().reduce((r: any, e: any) => {
				if (!r["System Id"] && hasId(e.row.original)) {
					r["System Id"] = getSystemId(e.row.original)
				}
				if (typeof e.column.columnDef.header === "string") {
					if (
						e.column.columnDef.header &&
						e.column.columnDef.header !== "Actions"
					) {
						r[e.column.columnDef.header] = Array.isArray(
							e.getValue()
						)
							? e
									.getValue()
									.map((item: any) => item.name)
									.join(", ")
							: e.getValue()
					}
				} else {
					const columnName = React.Children.map(
						e.column.columnDef.header.props.children,
						(child) => (typeof child === "string" ? child : " ")
					).join("")
					r[columnName] = e.getValue()
				}
				return r
			}, {})
		)
	}

	const handleMenu = useCallback((e: any, row: any) => {
		e.preventDefault()
		row.toggleSelected(true)
		handleContextMenu && handleContextMenu(e, row.original)
	}, [])

	const handleClick = useCallback((e: any, row: any) => {
		onRowClick && onRowClick(e, row.original)
	}, [])

	const handleSort = (props: any) => {
		const order = props.map((item: any) => item.id)
		setColumnOrder(order)
	}

	return (
		<StyledTable height={height}>
			{(pageHeader || showPageSetting) && (
				<div className="FR JSB pageHeader">
					{pageHeader && pageHeader(table)}
					{showPageSetting && (
						<DefaultPageCtxMenus
							permission={permission}
							getTotalData={() => handleExcelData("Total")}
							openPageSettingDialog={() => setOpen(true)}
							getFilteredData={() => handleExcelData("Filtered")}
							fileName={FileName[menuId] || "Excel"}
							i18n={i18n}
							toUpperCase={false}
						/>
					)}
				</div>
			)}
			<TableContainer className="container">
				<MaUTable
					stickyHeader
					aria-label="sticky table"
					style={{
						width: table?.getCenterTotalSize(),
						minWidth: "100%",
					}}
				>
					{showHeader && (
						<TableHead>
							{table?.getHeaderGroups()?.map((headerGroup) => (
								<TableRow key={headerGroup.id}>
									{headerGroup.headers.map((header) => {
										return (
											<TableCell
												key={header.id}
												colSpan={header.colSpan}
												style={{
													width: header.getSize(),
												}}
												className="FR"
											>
												{header.isPlaceholder ? null : (
													<div className="header">
														<div
															onClick={header.column.getToggleSortingHandler()}
															style={{
																whiteSpace:
																	"nowrap",
															}}
															className="FR JSB header-text"
														>
															{flexRender(
																header.column
																	.columnDef
																	.header,
																header.getContext()
															)}
															{header.column.getIsSorted() && (
																<Icon
																	path={
																		header.column.getIsSorted() ===
																		"desc"
																			? mdiArrowDown
																			: mdiArrowUp
																	}
																	size={0.6}
																	className="sorting-icon"
																/>
															)}
														</div>
														{header.column.getCanFilter() && (
															<div>
																{customFilters &&
																	customFilters(
																		{
																			column: header.column,
																			table,
																		}
																	)}
															</div>
														)}
													</div>
												)}
												<div
													{...{
														onMouseDown:
															header.getResizeHandler(),
														onTouchStart:
															header.getResizeHandler(),
														className: `resizer ${
															header.column.getIsResizing()
																? "isResizing"
																: ""
														}`,
														style: {
															transform:
																columnResizeMode ===
																	"onEnd" &&
																header.column.getIsResizing()
																	? `translateX(${
																			table.getState()
																				.columnSizingInfo
																				.deltaOffset
																	  }px)`
																	: "",
														},
													}}
												/>
											</TableCell>
										)
									})}
								</TableRow>
							))}
						</TableHead>
					)}
					<TableBody>
						{!!table?.getRowModel()?.rows.length && (
							<>
								{table?.getRowModel()?.rows?.map((row) => {
									return (
										<>
											<TableRow
												key={row.id}
												onContextMenu={(e) =>
													handleMenu(e, row)
												}
												onClick={(e) =>
													handleClick(e, row)
												}
												hover
												className={
													getRowClass
														? getRowClass(row)
														: ""
												}
											>
												{row
													.getVisibleCells()
													.map((cell: any) => {
														let dueDaysClass = ""
														if (
															cell.column.id ===
																"dueDays" &&
															cell.row.original
																?.dueDays
														) {
															const dueDays =
																cell.row
																	.original
																	?.dueDays

															if (dueDays < 0) {
																dueDaysClass =
																	"dueDays dueDays-overdue"
															} else if (
																dueDays === 0
															) {
																dueDaysClass =
																	"dueDays dueDays-today"
															} else if (
																dueDays < 15 &&
																dueDays > 0
															) {
																dueDaysClass =
																	"dueDays dueDays-1week"
															} else if (
																dueDays < 8 &&
																dueDays > 0
															) {
																dueDaysClass =
																	"dueDays dueDays-2weeks"
															} else if (
																dueDays ===
																"N/A"
															) {
																dueDaysClass =
																	"dueDays dueDays-grayout"
															}
														}

														return (
															<TableCell
																key={`${cell.id}_${cell.column.columnDef.header}`}
																className={`${dueDaysClass} ${
																	getCellClass
																		? getCellClass(
																				cell
																					.column
																					.columnDef
																					.accessorKey,
																				row
																		  )
																		: ""
																}`}
																style={{
																	width: cell.column.getSize(),
																}}
															>
																{flexRender(
																	cell.column
																		.columnDef
																		.cell,
																	cell.getContext()
																)}
															</TableCell>
														)
													})}
											</TableRow>
											{renderSubcomponent &&
												renderSubcomponent(row)}
										</>
									)
								})}
							</>
						)}
					</TableBody>
					{hasFooter && (
						<TableFooter>
							{table?.getFooterGroups()?.map((footerGroup) => {
								if (footerGroup.depth === 1) {
									return (
										<TableRow key={footerGroup.id}>
											{footerGroup.headers.map(
												(header) => {
													return (
														<TableCell
															key={header.id}
															colSpan={
																header.colSpan
															}
															style={{
																width: header.getSize(),
															}}
															className="FR"
														>
															{header.isPlaceholder ? null : (
																<div className="header">
																	<div
																		onClick={header.column.getToggleSortingHandler()}
																		style={{
																			whiteSpace:
																				"nowrap",
																		}}
																		className="FR JSB"
																	>
																		{flexRender(
																			header
																				.column
																				.columnDef
																				.footer,
																			header.getContext()
																		)}
																	</div>
																</div>
															)}
														</TableCell>
													)
												}
											)}
										</TableRow>
									)
								}
							})}
						</TableFooter>
					)}
				</MaUTable>
			</TableContainer>
			{hasPagination && (
				<TablePagination
					rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
					component="div"
					count={table.getFilteredRowModel().flatRows.length}
					rowsPerPage={pageSize}
					page={pageIndex}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			)}
			{open && (
				<DLDialog
					eleTestId="page-setting-dialog"
					isOpen={open}
					setIsOpen={setOpen}
					showCloseBtn={true}
					showOpenBtn={false}
					dialogTitle="Page Setting"
					dialogContents={
						<StyledPageSettingContainer className="page-setting-container">
							<div style={{ maxWidth: 400, margin: "30px auto" }}>
								<SortableList
									items={table.getAllLeafColumns()}
									onChange={handleSort}
									renderItem={(item: any) => (
										<SortableList.Item id={item.id}>
											<div className="FR JSB column-div">
												<DLCheckbox
													eleTestId={`checkbox-${item.id}`}
													color="primary"
													isChecked={item.getIsVisible()}
													clickHandler={item.getToggleVisibilityHandler()}
													labelText={
														item.columnDef.header
															?.props?.title ||
														typeof item.columnDef
															.header === "string"
															? item.columnDef
																	.header
															: React.Children.map(
																	item
																		.columnDef
																		.header
																		.props
																		.children,
																	(child) =>
																		typeof child ===
																		"string"
																			? child
																			: " "
															  ).join("")
													}
												/>
												<SortableList.DragHandle />
											</div>
										</SortableList.Item>
									)}
								/>
							</div>
						</StyledPageSettingContainer>
					}
					cancelBtnText="Cancel"
					draggable
				/>
			)}
		</StyledTable>
	)
}

export default memo(ReactTableV8)

interface props {
	readonly height?: number | string
}

const StyledTable = styled.div<props>`
	.container {
		height: ${(props: any) =>
			props.height ? props.height - 120 - 12 + "px" : "100%"};
	}
	.pageHeader {
		padding: 1rem;
	}
	a {
		color: blue;
	}
	.header {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		min-height: 70px;
		.header-text {
			// font-size: 13px !important;
			font-weight: bold !important;
		}
	}
	select {
		border: 1px solid rgba(0, 0, 0, 0.1);
		background: #fff;
		padding: 5px 7px;
		font-size: inherit;
		border-radius: 3px;
		font-weight: normal;
		outline-width: 0;
	}
	.resizer {
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		width: 1px;
		background: rgba(0, 0, 0, 0.5);
		cursor: col-resize;
		user-select: none;
		touch-action: none;
	}

	table > tbody > tr > td {
		padding: 0 8px 0 8px;
	}

	.resizer.isResizing {
		background: blue;
		opacity: 1;
	}
	.MuiTableCell-root {
		border-right: 1px solid rgba(224, 224, 224, 1);
		font-size: 12px !important;
	}

	@media (hover: hover) {
		.resizer {
			opacity: 0;
		}

		*:hover > .resizer {
			opacity: 1;
		}
	}
`

const StyledPageSettingContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	.column-div {
		padding: 0.4rem;
		transition: backgrond-color 0.4s;
		:hover {
			background-color: ${(props) => props.theme.secondary};
		}
		margin: 0.2rem 0 0.2rem 0;
		cursor: pointer;
		width: 400px;
		border: 1px solid ${(props) => props.theme.shade20};
		border-radius: 3px;
	}
	ul {
		list-style-type: none;
	}
`
