import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"

const GenerateForceArchivedPreProcessPdfs = (self: any) => ({
	generateForceArchivedPreProcessPdfs(projectId: string) {
		// 0.
		const actionName = "generateForceArchivedPreProcessPdfs"
		// 1.
		let proceed = window.confirm(
			"Do you really want to generate force-archived pre-process pdfs?"
		)
		if (!proceed) {
			return
		}
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 111,
			color: MessageColorType.green,
			customMessage: "Pre-Process PDF Request is in progress...",
			open: true,
		})
		// 3.

		self.requestGenerateForceArchivedPreProcessPdfs(projectId).then(
			(response: any) => {
				// console.log(actionName + "response" + response)
				if (
					response.status === 200 &&
					response.data.status.code === 1
				) {
					// if success
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 111,
						color: MessageColorType.blue,
						customMessage:
							response.data.status.message ||
							response.data.status.Message,
						// "Pre-Process PDF is requested",
						open: true,
					})
				} else {
					// set fail response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						customMessage:
							"Force-Archived pre-process has been failed.",
						open: true,
					})
				}
			}
		)
	},
})

export default GenerateForceArchivedPreProcessPdfs
