import React from "react"
import styled from "styled-components"

const AccountsCard = ({ account, handleClick, onDragStart }: any) => {
	return (
		<Card
			onClick={() => handleClick(account)}
			draggable
			onDragStart={onDragStart}
		>
			<CardBorder value={account.balance} />
			<CardContent>
				<CardCode>{account.code}</CardCode>
				<CardLabel>{account.name}</CardLabel>
				<CardValue value={account.balance}>{account.balance}</CardValue>
			</CardContent>
		</Card>
	)
}

export default AccountsCard

const Card = styled.div`
	display: flex;
	align-items: center;
	background-color: white;
	border: 1px solid #ddd;
	border-radius: 4px;
	padding: 8px;
	margin-bottom: 8px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	position: relative;
	cursor: grab;
	&:active {
		cursor: grabbing;
	}
	&:hover {
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	}
`

const CardBorder = styled.div<any>`
	width: 4px;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: ${(props) =>
		props.value.startsWith("(") ? "#ff4d4f" : "#007bff"};
	border-radius: 4px 0 0 4px;
`

const CardContent = styled.div`
	width: 100%;
	margin-left: 12px;
	display: flex;
	flex-direction: column;
	gap: 10px;
`

const CardCode = styled.div`
	font-size: 12px;
	font-weight: bold;
	color: #555;
`

const CardLabel = styled.div`
	font-size: 14px;
	color: #333;
`

const CardValue = styled.div<any>`
	display: flex;
	flex-direction: row-reverse;
	font-size: 14px;
	font-weight: bold;
	color: ${(props) => (props.value.startsWith("(") ? "red" : "green")};
`
