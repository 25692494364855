import React, { useState } from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import { ActionStatus } from "@datalobby/types"
import styled from "styled-components"
import { FormControl, MenuItem, Select, TextField } from "@material-ui/core"

import { DLButton } from "../../../../components/basic-elements"

const MCreateNewFSGroupNumber = ({ actionStatus }: { actionStatus: any }) => {
	const [newFSGroupNumber, setNewFSGroupNumber] = useState({
		fsGroupnumber: "",
		name: "",
		type: "",
		normalSign: "",
		behaviour: "",
		title: "",
		class: "",
		calculation: "",
		flipType: "",
		lsGroupNoFlip: "",
		formatting: "",
	})

	const handleForm = (name: string, value: any) => {
		setNewFSGroupNumber((prev: any) => ({
			...prev,
			[name]: value,
		}))
	}

	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			isLoading={actionStatus === ActionStatus.loading}
		>
			<StyledNewFSGroupNumber>
				<h3>New Group Number</h3>

				<div className="FR" style={{ marginTop: 15, gap: 15 }}>
					<div style={{ width: "50%" }}>
						<div>FS Group Number</div>
						<FormControl
							style={{ marginTop: 5 }}
							fullWidth
							size="small"
						>
							<TextField
								hiddenLabel
								id="fsGroupnumber"
								value={newFSGroupNumber.fsGroupnumber}
								variant="outlined"
								size="small"
								onChange={(e: any) =>
									handleForm("fsGroupnumber", e.target.value)
								}
							/>
						</FormControl>
					</div>
					<div style={{ width: "50%" }}>
						<div>Name</div>
						<FormControl
							style={{ marginTop: 5 }}
							fullWidth
							size="small"
						>
							<TextField
								hiddenLabel
								id="name"
								value={newFSGroupNumber.name}
								variant="outlined"
								size="small"
								onChange={(e: any) =>
									handleForm("name", e.target.value)
								}
							/>
						</FormControl>
					</div>
				</div>
				<div className="FR" style={{ marginTop: 15, gap: 15 }}>
					<div style={{ width: "50%" }}>
						<div>Type</div>
						<FormControl
							style={{ marginTop: 5 }}
							fullWidth
							size="small"
						>
							<Select
								labelId="adjustment-type-label"
								id="adjustment-type"
								value={newFSGroupNumber.type}
								variant="outlined"
								displayEmpty
								placeholder="Select type"
								onChange={(e) =>
									handleForm("type", e.target.value)
								}
								// fullWidth
							>
								<MenuItem disabled value="">
									Select type
								</MenuItem>
								<MenuItem value={"balance-sheet"}>
									Balance Sheet (B)
								</MenuItem>
								<MenuItem value={"inceome-statement"}>
									Income Statement (I)
								</MenuItem>
								<MenuItem value={"statement-of-cash-flow"}>
									Statement of Cash Flow (S)
								</MenuItem>
								<MenuItem value={"performance"}>
									Performance (P)
								</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div style={{ width: "50%" }}>
						<div>Normal Sign</div>
						<FormControl
							style={{ marginTop: 5 }}
							fullWidth
							size="small"
						>
							<Select
								labelId="period-label"
								id="normalSign"
								value={newFSGroupNumber.normalSign}
								variant="outlined"
								displayEmpty
								placeholder="Select Normal Sign"
								onChange={(e) =>
									handleForm("normalSign", e.target.value)
								}
							>
								<MenuItem disabled value="">
									Select Normal Sign
								</MenuItem>
								<MenuItem value={"debit"}>Debit</MenuItem>
								<MenuItem value={"credit"}>Credit</MenuItem>
							</Select>
						</FormControl>
					</div>
				</div>
				<div className="FR" style={{ marginTop: 15, gap: 15 }}>
					<div style={{ width: "50%" }}>
						<div>Behaviour</div>
						<FormControl
							style={{ marginTop: 5 }}
							fullWidth
							size="small"
						>
							<Select
								labelId="period-label"
								id="behaviour"
								value={newFSGroupNumber.behaviour}
								variant="outlined"
								displayEmpty
								placeholder="Select Behaviour"
								onChange={(e) =>
									handleForm("behaviour", e.target.value)
								}
							>
								<MenuItem disabled value="">
									Select Behaviour
								</MenuItem>
								<MenuItem value={"normal"}>Normal</MenuItem>
								<MenuItem value={"calculated"}>
									Calculated
								</MenuItem>
								<MenuItem value={"unassignable"}>
									Unassignable
								</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div style={{ width: "50%" }}>
						<div>Title</div>
						<FormControl
							style={{ marginTop: 5 }}
							fullWidth
							size="small"
						>
							<TextField
								hiddenLabel
								id="title"
								value={newFSGroupNumber.title}
								variant="outlined"
								size="small"
								onChange={(e: any) =>
									handleForm("title", e.target.value)
								}
							/>
						</FormControl>
					</div>
				</div>
				<div className="FR" style={{ marginTop: 15, gap: 15 }}>
					<div style={{ width: "50%" }}>
						<div>Class</div>
						<FormControl
							style={{ marginTop: 5 }}
							fullWidth
							size="small"
						>
							<Select
								labelId="period-label"
								id="class"
								value={newFSGroupNumber.class}
								variant="outlined"
								displayEmpty
								placeholder="Select Class"
								onChange={(e) =>
									handleForm("behaviour", e.target.value)
								}
							>
								<MenuItem disabled value="">
									Select Class
								</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div style={{ width: "50%" }}>
						<div>Calculation</div>
						<FormControl
							style={{ marginTop: 5 }}
							fullWidth
							size="small"
						>
							<TextField
								hiddenLabel
								id="calculation"
								value={newFSGroupNumber.calculation}
								variant="outlined"
								size="small"
								onChange={(e: any) =>
									handleForm("calculation", e.target.value)
								}
							/>
						</FormControl>
					</div>
				</div>
				<div className="FR" style={{ marginTop: 15, gap: 15 }}>
					<div style={{ width: "50%" }}>
						<div>Flip Type</div>
						<FormControl
							style={{ marginTop: 5 }}
							fullWidth
							size="small"
						>
							<Select
								labelId="period-label"
								id="flipType"
								value={newFSGroupNumber.flipType}
								variant="outlined"
								displayEmpty
								placeholder="Select Flip Type"
								onChange={(e) =>
									handleForm("flipType", e.target.value)
								}
							>
								<MenuItem disabled value="">
									Select Flip Type
								</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div style={{ width: "50%" }}>
						<div>L/S/Group No (Flip)</div>
						<FormControl
							style={{ marginTop: 5 }}
							fullWidth
							size="small"
						>
							<Select
								labelId="period-label"
								id="lsGroupNoFlip"
								value={newFSGroupNumber.lsGroupNoFlip}
								variant="outlined"
								displayEmpty
								placeholder="Select L/S/Group No (Flip)"
								onChange={(e) =>
									handleForm("lsGroupNoFlip", e.target.value)
								}
							>
								<MenuItem disabled value="">
									Select L/S/Group No (Flip)
								</MenuItem>
							</Select>
						</FormControl>
					</div>
				</div>
				<div className="FR" style={{ marginTop: 15, gap: 15 }}>
					<div style={{ width: "50%" }}>
						<div>Formatting</div>
						<FormControl
							style={{ marginTop: 5 }}
							fullWidth
							size="small"
						>
							<Select
								labelId="period-label"
								id="formatting"
								value={newFSGroupNumber.formatting}
								variant="outlined"
								displayEmpty
								placeholder="Select Formatting"
								onChange={(e) =>
									handleForm("formatting", e.target.value)
								}
							>
								<MenuItem disabled value="">
									Select Formatting
								</MenuItem>
							</Select>
						</FormControl>
					</div>
				</div>
				<div className="FR AC JR" style={{ marginTop: 15 }}>
					<DLButton
						variant="contained"
						eleTestId="save-btn"
						size="regular"
						color="primary"
						clickHandler={() => {}}
					>
						Save
					</DLButton>
				</div>
			</StyledNewFSGroupNumber>
		</PageContainer>
	)
}

export default MCreateNewFSGroupNumber

const StyledNewFSGroupNumber = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
`
