import { types } from "mobx-state-tree"
import AssignFSAccountsViewModel from "./assign-fs-accounts.view-model"
import AssignFSAccountsModel from "./assign-fs-accounts.model"

export const AssignFSAccountsStore = () =>
	types
		.compose(AssignFSAccountsModel(), AssignFSAccountsViewModel)
		.named("AssignFSAccountsStore")

export const initialStore = {
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const AssignFSAccountsStoreInstance =
	AssignFSAccountsStore().create(initialStore)

export default AssignFSAccountsStoreInstance
