import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import { DLButton, ConsoleLog } from "../../../components/basic-elements"
import { DLTooltip } from "@datalobby/components"
import {
	DLIcon,
	DLIconName,
	RequestIcon,
} from "../../../components/basic-elements/icons/common-icons"
import { mdiInformationOutline, mdiLock } from "@mdi/js"
import { v4 as uuidv4 } from "uuid"
import { Icon } from "@mdi/react"
import {
	ActionStatus,
	DLSector,
} from "../../../common-models/enumerations/common-enums"
import { LocalFileProps } from "../../../service-modules/file-module/data-models/file.data-props"
import { formatFileSize } from "@datalobby/common"
import { getProjectIcon } from "../../../components/basic-elements/icons/common-project-icons"
import { HandleResponseProps } from "../../../common-models/common-view-model-actions"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

export default observer(function EditorHeader() {
	const store = useRootStore()
	const eStore = store.editor

	console.log(" ----- editor header be rendered")

	const { fileTitle, fileAliasId, menuId, sector, srcId, projId } =
		eStore.urlInfo

	const userId = eStore.orgInfo.userId
	const { checkedOutById, checkedOutByName, userRole } = eStore.fileStatus
	const {
		projTitle,
		projAliasId,
		statusName,
		clientAliasId,
		clientName,
		financialYear,
		engPartner,
		isLocked,
		isArchived,
	} = eStore.projInfo

	const permission = eStore.getActionsAsObject(menuId, isLocked, isArchived)
	//
	// const handleCheckout = () => {
	// 	if (sector === DLSector.proj) {
	// 		if (checkedOutById === null) {
	// 			const props = {
	// 				srcId,
	// 				userId,
	// 				sector,
	// 				sectorId: projId,
	// 				menuId,
	// 			}
	// 			eStore.checkoutFile(props)
	// 		} else {
	// 			alert(`This file is opened by ${checkedOutByName}`)
	// 		}
	// 	} else {
	// 		alert("There are no checkout feature on the organization side yet")
	// 	}
	// }

	useEffect(() => {
		if (sector === DLSector.proj && !isLocked) {
			const props = {
				srcId,
				userId,
				sector,
				sectorId: projId,
				menuId,
				postActionForSuccess: () => {},
			}
			eStore.checkoutFile(props)
		}
	}, [])

	const handleClose = () => {
		if (sector === DLSector.proj) {
			const props = {
				srcId,
				userId,
				sector,
				sectorId: projId,
				menuId,
				postActionForSuccess: () => window.close(),
			}
			eStore.checkoutFile(props)
		}
	}

	console.log(menuId, "menuId")

	// useEffect(() => {
	// 	const handleBeforeUnload = (event: any) => {
	// 		// Call your function here
	// 		handleClose()
	// 	}

	// 	window.addEventListener("beforeunload", handleBeforeUnload)

	// 	return () => {
	// 		window.removeEventListener("beforeunload", handleBeforeUnload)
	// 	}
	// }, [])

	// window.onbeforeunload = handleClose

	const handleRequestCheckin = () => {
		// request checkin to another user who are using this file
		const props = {
			projId,
			srcId,
			menuId,
			message: "",
			userId,
		}
		const localHandleResponse = (responseProps: HandleResponseProps) => {
			// console.log("responseProps", responseProps)
			eStore.handleResponse(responseProps)
		}
		eStore.requestCheckinFile(props, localHandleResponse)
	}
	const handleDiscardCheckout = () => {
		if (menuId) {
			const postAction = () => eStore.getFileStatus()
			eStore.prepareDiscardCheckout({ menuId, userId, srcId, postAction })
		} else {
			ConsoleLog("(EditorHeader) Cannot find the menuId")
		}
	}

	const projSector = sector === DLSector.proj
	//
	//
	const [file, setFile] = useState<undefined | LocalFileProps>(undefined)
	const handleSelectFileFromLocal = (event: any) => {
		const file = event.target.files[0]
		const { name, type, size, lastModified } = file
		const fileName = name.slice(0, name.lastIndexOf("."))
		const fileExtension = name.slice(name.lastIndexOf("."))
		const formattedSize = formatFileSize(size, 2)
		setFile({
			tempId: uuidv4(),
			aliasId: "",
			selected: false,
			name: fileName,
			extension: fileExtension,
			size,
			formattedSize,
			type,
			lastModified,
			file,
			prepared: false,
			reviewed: false,
		})
	}

	const [whoCheckedOut, setWhoCheckedOut] = useState<
		"byMe" | "byOthers" | "noOne"
	>("noOne")
	// let byMe = false
	// let byOthers = false
	// let noOneUse = false

	useEffect(() => {
		if (projSector) {
			if (userId === checkedOutById) {
				setWhoCheckedOut("byMe")
				// byMe = true
			} else if (checkedOutById === null) {
				setWhoCheckedOut("noOne")
				// noOneUse = true
			} else if (userId !== checkedOutById && checkedOutById !== null) {
				setWhoCheckedOut("byOthers")
				// byOthers = true
			}
		}
	}, [eStore.fileStatus.checkedOutById])

	const getFileStatusWip = projSector
		? eStore.getActionStatus("getFileStatus") === ActionStatus.loading
		: ActionStatus.success
	const checkoutWip =
		eStore.getActionStatus("checkoutFile") === ActionStatus.loading
	const urlStatus = eStore.getActionStatus("combineIframeUrl")

	console.log(
		"whoCheckedOut:",
		whoCheckedOut,
		"/getFileStatusWip:",
		getFileStatusWip,
		"/checkoutWip:",
		checkoutWip,
		"/userId:",
		userId,
		"/checkedOutById:",
		checkedOutById
	)
	return (
		<StyledEditorHeader
			className="editor-header FR JSB"
			data-testid="editor-header"
		>
			<div className="left-side FR AC">
				{!getFileStatusWip &&
					!checkoutWip &&
					whoCheckedOut === "byMe" && (
						<DLButton
							eleTestId="checkin-file"
							color="primary"
							clickHandler={handleDiscardCheckout}
							startIcon={
								<DLIcon name={DLIconName.close} noMargin />
							}
						>
							Checkin
						</DLButton>
					)}
				{/* {!getFileStatusWip &&
					!checkoutWip &&
					whoCheckedOut === "noOne" && (
						<DLButton
							eleTestId="checkout-file"
							color="primary"
							clickHandler={handleCheckout}
						>
							Checkout
						</DLButton>
					)} */}

				<DLButton
					eleTestId="checkout-file"
					color="secondary"
					clickHandler={handleClose}
				>
					Close
				</DLButton>

				<div className="heading2 file-name-container FR AC">
					{menuId !== DLOrgSubMenus.proj_templates && (
						<div className="project-info FR AC">
							<DLTooltip
								title={
									<div className="project-info-tooltip">
										<div className="FR AC proj-info-row">
											Year: {financialYear}
										</div>
										<div className="FR AC proj-info-row">
											Project: ({projAliasId}) {projTitle}
										</div>
										<div className="FR AC proj-info-row">
											Client: ({clientAliasId}){" "}
											{clientName}
										</div>
										<div className="FR AC proj-info-row">
											E.P.: {engPartner}
										</div>
									</div>
								}
							>
								<Icon path={mdiInformationOutline} size={1.2} />
							</DLTooltip>
						</div>
					)}
					<div className="FR AC JC">
						{getProjectIcon(statusName, 1.2)}
					</div>
					{whoCheckedOut === "byOthers" && (
						<div className="lock-icon FR AC">
							<Icon path={mdiLock} size={0.8} />
						</div>
					)}
					<DLTooltip title="File Alias ID (reference number)" arrow>
						<span className="aliasId" data-testid="aliasId">
							({fileAliasId})
						</span>
					</DLTooltip>
					<span className="file-name" data-testid="fileName">
						{fileTitle}
					</span>
				</div>
				{whoCheckedOut === "byMe" && (
					<div className="checked-out-info">
						<span className="checked-out-by-me">
							Checked out by me
						</span>
					</div>
				)}
				{whoCheckedOut === "byOthers" && (
					<DLTooltip title="If you want to update this file, please request checkin.">
						<div className="checked-out-info">
							<span className="checked-out-user">
								{checkedOutByName}
							</span>{" "}
							is using this file.
						</div>
					</DLTooltip>
				)}
			</div>
			<div className="right-side FR AC">
				{projSector &&
					whoCheckedOut === "byOthers" &&
					permission.update && (
						<DLButton
							eleClassName="editor-toolbar-btn"
							eleTestId="request-checkin-btn"
							startIcon={<RequestIcon noMargin />}
							tooltipText="Request checkin this file "
							clickHandler={handleRequestCheckin}
						>
							Request Checkin
						</DLButton>
					)}
				{/* 
						1. Someone checking out this file
						2. the user has admin role (able to force close)
						3. checking out user is different with the current user
						(if the user is checking out user, use checkin instead of the discard checkout) 
					*/}
				{projSector &&
					whoCheckedOut === "byOthers" &&
					userRole === "ProjectAdmin" && (
						<DLButton
							eleTestId="discard-checkout-btn"
							startIcon={
								<DLIcon name={DLIconName.forceClose} noMargin />
							}
							tooltipText="Checkin this file without saving (force close)"
							clickHandler={handleDiscardCheckout}
						>
							Discard Checkout
						</DLButton>
					)}

				{/* file upload is required for PT also (PT is org side menu) */}
				{/* NOTE: It seems on the dev-express side...  */}
				{/* {checkedOutById === userId && (
						<DLButton
							eleClassName="editor-toolbar-btn upload-btn"
							eleTestId="upload-local-file-btn"
							tooltipText="Upload local file"
							startIcon={<Icon path={mdiUpload} size={0.8} />}
						>
							<label>
								File Upload
								<input
									type="file"
									name="file"
									onChange={handleSelectFileFromLocal}
									data-testid="file-input"
									accept={".xls,.xlsx"}
									accept={allowedFormats}
								/>
							</label>
						</DLButton>
					)} */}
			</div>
		</StyledEditorHeader>
	)
})

const StyledEditorHeader = styled.div`
	&.editor-header {
		width: 100vw;
		height: 3rem;
		padding: 0 1rem;
		.left-side {
			.file-name-container {
				margin: 0 1rem;
				font-weight: 700;
				.project-info {
					svg {
						opacity: 0.6;
					}
				}
				.lock-icon {
					margin-right: 0.4rem;
					opacity: 0.6;
				}
				.aliasId {
					font-weight: ${(props) => props.theme.shared.fontLight};
					margin-right: 0.6rem;
				}
				.file-name {
				}
			}
			.checked-out-info {
				.checked-out-by-me {
					opacity: 0.4;
				}
				.checked-out-user {
					color: ${(props) => props.theme.emphasis};
					font-weight: 500;
				}
			}
		}
		.right-side {
			.editor-toolbar-btn {
				margin-right: 0.6rem;
				&.upload-btn {
					input[type="file"] {
						display: none;
					}
				}
			}
		}
	}
`
