import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

export const RefreshForProjects = (self: any) => ({
	customRefresh(menuId: DLOrgSubMenus, needRefresh: boolean) {
		if (menuId === DLOrgSubMenus.normal_projs) {
			self.needRefreshForNormal = needRefresh
			self.needRefreshForAssignedNormal = !needRefresh
		}
		if (menuId === DLOrgSubMenus.replicas) {
			self.needRefreshForReplica = needRefresh
			self.needRefreshForAssignedReplica = !needRefresh
		}
		if (menuId === DLOrgSubMenus.archived) {
			self.needRefreshForArchived = needRefresh
			self.needRefreshForAssignedArchived = !needRefresh
		}
		if (menuId === DLOrgSubMenus.unarchived) {
			self.needRefreshForUnarchived = needRefresh
			self.needRefreshForAssignedUnarchived = !needRefresh
		}
		if (menuId === DLOrgSubMenus.force_archived) {
			self.needRefreshForForceArchived = needRefresh
		}
		if (menuId === DLOrgSubMenus.assignedNormal) {
			self.needRefreshForAssignedNormal = needRefresh
			self.needRefreshForNormal = !needRefresh
		}
		if (menuId === DLOrgSubMenus.assignedReplica) {
			self.needRefreshForAssignedReplica = needRefresh
			self.needRefreshForReplica = !needRefresh
		}
		if (menuId === DLOrgSubMenus.assignedArchived) {
			self.needRefreshForAssignedArchived = needRefresh
			self.needRefreshForArchived = !needRefresh
		}
		if (menuId === DLOrgSubMenus.assignedUnarchived) {
			self.needRefreshForAssignedUnarchived = needRefresh
			self.needRefreshForUnarchived = !needRefresh
		}
		if (menuId === DLOrgSubMenus.assignedForceArchivedProjects) {
			self.needRefreshForAssignedForceArchived = needRefresh
		}
		if (menuId === DLOrgSubMenus.assignedArchivedReadonlyProjects) {
			self.needRefreshForROAM = needRefresh
		}
		if (menuId === DLOrgSubMenus.remind_report) {
			self.needRefreshForRemindReport = needRefresh
			self.needRefreshForAdminReport = !needRefresh
		}
		if (menuId === DLOrgSubMenus.remind_archiving) {
			self.needRefreshForRemindArchive = needRefresh
			self.needRefreshForAdminArchive = !needRefresh
			self.needRefreshForArchivingReminder = !needRefresh
		}
		if (menuId === DLOrgSubMenus.admin_remind_report) {
			self.needRefreshForAdminReport = needRefresh
			self.needRefreshForRemindReport = !needRefresh
		}
		if (menuId === DLOrgSubMenus.admin_remind_archiving) {
			self.needRefreshForAdminArchive = needRefresh
			self.needRefreshForRemindArchive = !needRefresh
			self.needRefreshForArchivingReminder = !needRefresh
		}
		if (menuId === DLOrgSubMenus.arc_rem_norm_projs) {
			self.needRefreshForArchivingReminder = needRefresh
			self.needRefreshForAdminArchive = !needRefresh
			self.needRefreshForRemindArchive = !needRefresh
		}
		if (menuId === DLOrgSubMenus.user_my_summary) {
			self.needRefreshForUserMySummary = needRefresh
		}
	},
})
