import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"
import styled from "styled-components"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"
import {
	getPageSettings,
	savePageSetting,
} from "../../../../library/get-page-settings"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import MySummaryTable from "../utils/MySummaryTable"
import UserInfoHeaderCard from "../utils/UserInfoHeaderCard"

export default observer(function UserMySummaryController() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.remind_archiving
	const projStatus = ProjectStatus.normal // What will be the proj status
	const actionName = "getRemindProjectList_" + projStatus
	//
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)
	const i18nSheet = OrgI18n.projects
	const needRefresh = orgStore.projects.needRefreshForArchivingReminder
	const dntFormat = store.global.getDntFormat
	const user = orgStore.checkin.orgInfo

	const loadingStatus = orgStore.projects.getActionStatus(actionName)
	const i18n = store.i18n.combineI18n(i18nSheet)
	const clickPointExist = orgStore.projects.clickPoint.mouseX !== null
	const clickPoint = {
		mouseX: orgStore.projects.clickPoint.mouseX,
		mouseY: orgStore.projects.clickPoint.mouseY,
	}

	const projId: string | null = orgStore.projects.selectedProj
	const projNumId: number | null = projId
		? (idToNumber(projId, IdType.project) as number)
		: null

	const menuOptions: any = [
		{
			label: "Open",
			value: "open",
			isLink: true,
			linkTo: projNumId ? `/project/checkin/${projNumId}` : "#",
		},
		{
			label: "Project Information",
			value: "ctx-proj_info",
			available: permission.read,
			clickEvent: () =>
				orgStore.projects.setProjectDetailsDialogOpen(true),
		},
	]

	useEffect(() => {
		if (menuAccess && needRefresh) {
			const pageSetting = getPageSettings(menuId)
			if (pageSetting?.columnFilters) {
				const value = pageSetting?.columnFilters.filter(
					(item: any) => item.id !== "financialYear"
				)
				savePageSetting("columnFilters", value, `pageSetting_${menuId}`)
			}
			orgStore.projects.getRemindProjectList({
				projStatus,
				year: "All Years", //"2023",
				PTMAssignedOnly: true,
				menuId,
				dntFormat,
			})
		}
	}, [menuAccess, needRefresh])

	return (
		<>
			{menuAccess ? (
				<StyledMySummaryProjects>
					<div>
						<UserInfoHeaderCard
							user={{
								email: user.email,
								aliasId: user.userAliasId,
								name: user.userName,
							}}
						/>
						<MySummaryTable
							orgStore={orgStore}
							i18n={i18n}
							menuId={menuId}
							permission={permission}
							clickPointExist={clickPointExist}
							clickPoint={clickPoint}
							menuOptions={menuOptions}
							loadingStatus={loadingStatus}
						/>
					</div>
				</StyledMySummaryProjects>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

const StyledMySummaryProjects = styled.div`
	// overflow: auto; // NOTE: @BADA, uncomment this line when you want the scroll the whole page
`
