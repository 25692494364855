import React, { useState } from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import { ActionStatus } from "@datalobby/types"
import styled from "styled-components"
import { FormControl, MenuItem, Select, TextField } from "@material-ui/core"
import SimpleMDE from "react-simplemde-editor"
import "easymde/dist/easymde.min.css"
import { DLButton, DLIconButton } from "../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiClose, mdiPlus, mdiPlusCircle } from "@mdi/js"
import { Autocomplete } from "@material-ui/lab"

const MNewAdjustment = ({ actionStatus }: { actionStatus: any }) => {
	const [newAdjustment, setNewAdjustment] = useState({
		number: "",
		type: "",
		period: "",
		description: "",
		accounts: [],
	})

	const handleForm = (name: string, value: any) => {
		setNewAdjustment((prev: any) => ({
			...prev,
			[name]: value,
		}))
	}

	const handleAddAccountLine = () => {
		const copy: any = [...newAdjustment.accounts]
		const fileId = copy.reduce((acc: any, val: any) => {
			acc = acc === undefined || val.id > acc ? val.id : acc
			return acc
		}, 0)

		copy.push({
			id: fileId + 1,
			account: "",
			value: "",
		})

		setNewAdjustment((prev: any) => ({
			...prev,
			accounts: copy,
		}))
	}

	const handleRemoveAccount = (id: number) => {
		const copy: any = [...newAdjustment.accounts]
		setNewAdjustment((prev: any) => ({
			...prev,
			accounts: copy.filter((item: any) => item.id !== id),
		}))
	}

	console.log(newAdjustment, "newAdjustment")

	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			isLoading={actionStatus === ActionStatus.loading}
		>
			<StyledNewAdjustment>
				<h3>New Adjustment</h3>

				<div style={{ marginTop: 15 }}>
					<div>Adjustment Number</div>
					<FormControl
						style={{ marginTop: 5, width: "25%" }}
						fullWidth
						size="small"
					>
						<TextField
							hiddenLabel
							id="number"
							value={newAdjustment.number}
							variant="outlined"
							size="small"
							type="number"
							onChange={(e: any) =>
								handleForm("number", e.target.value)
							}
						/>
					</FormControl>
				</div>
				<div style={{ marginTop: 15 }}>
					<div>Type</div>
					<FormControl
						style={{ marginTop: 5, width: "50%" }}
						fullWidth
						size="small"
					>
						<Select
							labelId="adjustment-type-label"
							id="adjustment-type"
							value={newAdjustment.type}
							variant="outlined"
							displayEmpty
							placeholder="Select type"
							onChange={(e) => handleForm("type", e.target.value)}
							// fullWidth
						>
							<MenuItem disabled value="">
								Select type
							</MenuItem>
							<MenuItem value={"normal"}>Normal</MenuItem>
							<MenuItem value={"not-normal"}>Not Normal</MenuItem>
						</Select>
					</FormControl>
				</div>
				<div style={{ marginTop: 15 }}>
					<div>Period</div>
					<FormControl
						style={{ marginTop: 5, width: "50%" }}
						fullWidth
						size="small"
					>
						<Select
							labelId="period-label"
							id="period"
							value={newAdjustment.period}
							variant="outlined"
							displayEmpty
							placeholder="Select period"
							onChange={(e) =>
								handleForm("period", e.target.value)
							}
							// fullWidth
						>
							<MenuItem disabled value="">
								Select period
							</MenuItem>
							<MenuItem value={"current-year"}>
								Current Year
							</MenuItem>
							<MenuItem value={"prior-year"}>Prior Year</MenuItem>
						</Select>
					</FormControl>
				</div>
				<div style={{ marginTop: 15 }}>
					<div>Description</div>
					<SimpleMDE
						className="md-editor"
						value={newAdjustment.description}
						onChange={(value) => {
							handleForm("description", value)
						}}
						data-testid="adjustment-descripton"
					/>
				</div>
				<div className="FR AC" style={{ marginTop: 15 }}>
					<div>Annotations</div>
					<DLIconButton
						eleTestId="annotations"
						clickHandler={() => {}}
					>
						<Icon path={mdiPlusCircle} color={"blue"} size={1} />
					</DLIconButton>
				</div>
				<div style={{ marginTop: 15 }}>
					<div>Details</div>
					<div style={{ marginTop: 5 }}>
						{newAdjustment.accounts.map((item: any) => {
							return (
								<div className="FR" style={{ gap: 10 }}>
									<Autocomplete
										options={[]}
										style={{
											width: "60%",
											marginBottom: 20,
										}}
										size="small"
										onChange={() => {}}
										// value={
										// 	options.find(
										// 		(option) =>
										// 			option.value === value
										// 	) || null
										// }
										renderInput={(params) => (
											<TextField
												variant="outlined"
												{...params}
												label={`Select account`}
											/>
										)}
									/>
									<TextField
										hiddenLabel
										id="account-value"
										value={item.value}
										variant="outlined"
										size="small"
										onChange={() => {}}
										style={{ width: "30%" }}
									/>
									<DLIconButton
										eleTestId="add-annotation"
										clickHandler={() => {}}
									>
										<Icon
											path={mdiPlusCircle}
											color={"blue"}
											size={1}
										/>
									</DLIconButton>
									<DLIconButton
										eleTestId="remove"
										clickHandler={() =>
											handleRemoveAccount(item.id)
										}
									>
										<Icon path={mdiClose} size={1} />
									</DLIconButton>
								</div>
							)
						})}
					</div>
				</div>
				<div className="FR AC JC" style={{ marginTop: 15 }}>
					<DLButton
						variant="text"
						eleTestId="account-line-btn"
						size="regular"
						color="primary"
						clickHandler={handleAddAccountLine}
						startIcon={
							<Icon path={mdiPlus} color={"blue"} size={1} />
						}
					>
						Account line
					</DLButton>
					<DLButton
						variant="text"
						eleTestId="group-line-btn"
						size="regular"
						color="primary"
						clickHandler={() => {}}
						startIcon={
							<Icon path={mdiPlus} color={"blue"} size={1} />
						}
					>
						Group line
					</DLButton>
				</div>
				<div className="FR AC JR" style={{ marginTop: 15 }}>
					{/* <DLButton
						variant="text"
						eleTestId="save-notification-btn"
						size="regular"
						color="primary"
						clickHandler={() => {}}
						startIcon={
							<Icon path={mdiPlus} color={"blue"} size={1} />
						}
					>
						Account line
					</DLButton> */}
					<DLButton
						variant="contained"
						eleTestId="save-btn"
						size="regular"
						color="primary"
						clickHandler={() => {}}
					>
						Save
					</DLButton>
				</div>
			</StyledNewAdjustment>
		</PageContainer>
	)
}

export default MNewAdjustment

const StyledNewAdjustment = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
	.quill {
		width: 100%;
		height: 200px;
	}
	.CodeMirror-scroll {
		min-height: 200px !important;
	}
`
